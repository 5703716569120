import React from 'react'

import { eSummaryScreen } from '@/common/define';
import SummaryScreen from '@/components/SummaryScreen/SummaryScreen';

const MaterialAggregation: React.FC = () => {
  return <SummaryScreen type={eSummaryScreen.TONGHOPVATTU} />;
};

export default MaterialAggregation;
