import { createSlice } from '@reduxjs/toolkit';

import { TeamsResponse } from '@/services/CheckInService';

interface TimekeepingState {
  teams: TeamsResponse[];
  checkIn?: any;
  selectedCheckInDetail?: any;
  selectedCheckInItem?: any;
  checkInPhoto?: any;
  employeeSelected?: any;
  allTimeKeepingFormonth?: any;
  timeKeepingForDay?: any;
  checkInModelData?: any;
}

const initialState: TimekeepingState = {
  teams: []
};

const timekeepingSlice = createSlice({
  name: 'timekeeping',
  initialState,
  reducers: {
    getTeamsOfOperatorRequest: (state, action) => {},
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    getTimeKeepingOfTeamRequest: (state, action) => {},
    setCheckInData: (state, action) => {
      state.checkIn = action.payload;
    },
    setSelectedCheckInDetail: (state, action) => {
      state.selectedCheckInDetail = action.payload;
    },
    setSelectedCheckInItem: (state, action) => {
      state.selectedCheckInItem = action.payload;
    },
    approvedHoursWorkingRequest: (state, action) => {},
    getCheckInPhoto: (state, action) => {
      state.checkInPhoto = undefined;
    },
    setCheckInPhoto: (state, action) => {
      state.checkInPhoto = action.payload;
    },
    setSlectedUser: (state, action) => {
      state.employeeSelected = action.payload
    },
    getAllTimeOfOneEmployee: (state, action) => { },
    setAllTimeKeepingForMonth: (state, action) => {
      state.allTimeKeepingFormonth = action.payload;
    },
    approvedTimeKeepingForMonth: (state, action) => {},
    setTimeKeepingForDay: (state, action) => {
      state.timeKeepingForDay = action.payload
    },
    getAllTimeKeepingsForDay: (state, action) => {},
    setCheckInDataModel: (state, action) => {
      state.checkInModelData = action.payload;
    },
  },
});

export const timekeepingActions = timekeepingSlice.actions;
export const timekeepingReducer = timekeepingSlice.reducer;
