import { useEffect, useState } from 'react';  

import { Dayjs } from 'dayjs';  
import dayjs from 'dayjs';  

import PaythesubcontractorList from './components/PaythesubcontractorList';
import { Paythesubcontractor } from '@/common/define';
import TabHeaderDiary from '@/components/Layout/TabHeaderDiary/TabHeaderDiary';  
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getProjectQueryParams, getSelectedProject, projectActions } from '@/store/project';
interface PaythesubcontractorProps {
  type: Paythesubcontractor;
}
 
export const PaytheSubcontractors = (props: PaythesubcontractorProps) => {  
  const { type } = props;
  const [startDate, setStartDate] = useState<Dayjs | null>(null);  
  const [endDate, setEndDate] = useState<Dayjs | null>(null);  
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const title = type === Paythesubcontractor.ThanhToan12 ? 'Thanh toán thầu phụ 12' : 'Thanh toán thầu phụ 27';
  const handleDownload = () => {  
  };  
  //[20510] [nam_do] view dữu liệu cho màn hình thanh toán ngày 12 và ngày 27
  const handleSelectDate = (dates: [Dayjs | null, Dayjs | null] | null) => {  
    if (dates) {  
      setStartDate(dates[0]);  
      setEndDate(dates[1]);  
    } else {  
      setStartDate(null);  
      setEndDate(null);  
    }  
  };  
  useEffect(() => {
    if (selectedProject) {
      const currentDate = dayjs();
      const apiStartDate = startDate ? startDate.format('YYYY-MM-DD') : currentDate.startOf('month').format('YYYY-MM-DD');
      const apiEndDate = endDate ? endDate.format('YYYY-MM-DD') : currentDate.endOf('month').format('YYYY-MM-DD');

      dispatch(projectActions.getpaymentByProject({
        projectId: selectedProject.id,
        paymentTerm: type === Paythesubcontractor.ThanhToan12 ? 0 : 1,
        startDate: apiStartDate,
        endDate: apiEndDate,
      }));
    }
  }, [selectedProject, dispatch, startDate, endDate, type]);
  return (  
    <div>  
      <TabHeaderDiary  text={title} onDownload={handleDownload} onSelectDate={handleSelectDate} />  
      <PaythesubcontractorList type={type} />
    </div>  
  );  
};
