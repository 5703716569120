import { memo, useEffect, useRef, useState } from "react";

import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";

import OnlyOfficeViewer from "./OnlyofficeViewer";
import { useAppSelector } from "@/store/hooks";
import { getDataFileView, issueActions } from "@/store/issue";
import { getSelectedProject } from "@/store/project";

export const ViewFileNotOffice = ({  previewDataNotoffice, setPreviewDataNotOffice }: any) => {
  const selectedProject = useAppSelector(getSelectedProject());
  const dataFileView = useAppSelector(getDataFileView());
  const [viewFrame, setViewFrame] = useState({ width: '70vw', height: '70vh' });
  // [#20755][hao_lt][08/11/2024]_View file
  useEffect(() => {
    if (dataFileView?.url) {
      const img = new Image();
      img.src = dataFileView.url;
      img.onload = () => {
        setViewFrame({ width: `${img.width + 100}px`, height: `${img.height + 100}px` });
      };
      img.onerror = () => {
        console.error("Failed to load image dimensions");
      };
    }
  }, [selectedProject, dataFileView?.url]);

  // Đóng modal
  const handleCloseModal = () => {
    setPreviewDataNotOffice(false);
  };
  return (
    <Modal
      title="Document Preview"
      open={previewDataNotoffice}
      onCancel={handleCloseModal}
      footer={null} // Không có nút dưới modal
      styles={{
        body: {
          height: dataFileView?.url ? viewFrame.height : '200px',
          maxHeight: '70vh',
          maxWidth: '70vw',
        },
      }}
    >
     {dataFileView && dataFileView?.url ? (
        <img
            src={dataFileView.url}
            alt="File Viewer"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
              margin: '0 auto'
            }}
          />
      ) : null}
    </Modal>
  );
};
