import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface GroupDTO
    {
        id?: number,
        employees?: any[],
        companyId: number,
        parentId: number | null,
        name: string,
        managerId?: number,
        code?: string,
        type?: number,
        status?: number
      }

class GroupController {
  public Get = {
    getGroup: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Group/company/${companyId}`, options);
    }
  };
  public Post = {
    addNewGroup: (inputValues: GroupDTO, options?: RequestOptions) => {
      console.log(inputValues, 'inputValues');
      return HttpClient.post(`${apiUrl}/Group`, inputValues, options);
    },
};
  public Put = {
    editGroup: (inputValues: GroupDTO, id: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/Group/${id}`, inputValues, options);
    },
  };
  public Delete = {
    deleteGroup: (groupId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/Group/${groupId}`, options);
    },
  };
}

export const GroupService = new GroupController();