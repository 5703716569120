import { createSlice } from "@reduxjs/toolkit";

import { GroupDTO } from "@/services/GroupService";
interface GroupState {
    group: GroupDTO[];
    createGroupData?: GroupDTO;
}
const initialState: GroupState = {
    createGroupData: undefined,  
    group: [],
};
const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroups: (state, action) => {
            state.group = action.payload;
        },
        getGroupsRequest: (state, action) => {console.log(action.payload)},
        createGroupRequest: (state, action) => {console.log(action.payload)},
        setCreateGroups: (state, action) => {state.createGroupData = action.payload
            console.log("createGroupData",action.payload)
        }, 
        updateGroupRequest: (state, action) => {console.log(action.payload)},
        removeGroupsRequest: (state, action) => {},
        editGroupRequest: (state, action) => {console.log(action.payload)},
        deleteAttachmentLinks: (state, action) => {},
        uploadAttachmentLinks: (state, action) => {console.log(action.payload)},
        getImageUrlAttachmentLinks: (state, action) => {},
        updateAttachementImageUrl: (state, action) => {},
    }
});
export const groupActions = groupSlice.actions;
export const GroupReducer = groupSlice.reducer;
