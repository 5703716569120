import { useEffect, useRef, useState } from 'react';

import { DocumentsTable } from '../../Components/Document';
import { defaultPagingParams } from '@/common/define';
import { DocumentsToolbar } from '@/pages/Components/Document/DocumentsToolbar';
import { ProjectDocumentsHeader } from '@/pages/Components/Document/ProjectDocumentHeader';
import { getActiveMenu } from '@/store/app';
import { documentActions, getFolderRootId, getPathDocument, getDocumentQueryParams, } from '@/store/documents';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject, getFileRoots, projectActions } from '@/store/project';

export const UnionExpenseTable = () => {
  const dispatch = useAppDispatch();
  const folderRootId = useAppSelector(getFolderRootId());
  const documentPath: any = useAppSelector(getPathDocument());
  const selectedProject = useAppSelector(getSelectedProject());
  const listDataFileRoots = useAppSelector(getFileRoots());
  const activeMenu = useAppSelector(getActiveMenu());
  const params = useAppSelector(getDocumentQueryParams());
  const isCallRef = useRef(true);
  // [hao_lt] get list folder rootid
  useEffect(()=> {
    if (listDataFileRoots && listDataFileRoots.length === 0) {
      dispatch(projectActions.getFolderRootId({ projectId: -1, isGetId: true }));
   }
  },[])
 
  useEffect(() => {
      if (listDataFileRoots && listDataFileRoots?.results?.length > 0) {
        const rootId = listDataFileRoots.results.find((i:any) => i.name === 'bangchiquicongdoan');
        if (rootId) {
          dispatch(documentActions.setFolderRootId(rootId?.id))
        } else {
          isCallRef.current = false;
          dispatch(documentActions.setFolderRootId(null))
          dispatch(documentActions.setDocuments([]))
      }
      dispatch(documentActions.setDocumentPath([]));
  }
    // eslint-disable-next-line
  }, [listDataFileRoots]);

  useEffect(() => {
    const lastPath = documentPath[documentPath?.length - 1];
    if (lastPath) {
      dispatch(documentActions.getLabelRequest({ documentId: lastPath?.id, params: defaultPagingParams }));
    } else {
      if (folderRootId && isCallRef.current) {
        dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: defaultPagingParams }));
      }
    }
    // eslint-disable-next-line
  }, [folderRootId, documentPath, selectedProject]);

  const handleSearchChange = (search : string) => {
    const newParams = { ...params, page: 1, search}
    if (!documentPath?.length && folderRootId) {
      dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: newParams }))
    } else {
      const lastPath = documentPath[(documentPath?.length || 1) - 1];
      if (lastPath) {
        dispatch(documentActions.getLabelRequest({ documentId: lastPath.id, params: newParams }));
      }
    }
  }

  return (
    <>
      <ProjectDocumentsHeader
        title={activeMenu?.label}
      />
      <DocumentsToolbar 
        initialSearch={params.search}
        onSearchChange={handleSearchChange}
      />
      <DocumentsTable />
    </>
  );
};
