import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './UnionDues.module.css';
import { FeeTableEmployee, GettingEmployeeList, IEmployeeFee } from '@/common/define';
import { Loading } from '@/components';
import { eColumnsTpye, iColumnsConfig, TableCustom } from '@/components/TableCustom';
import { EmployeeResponse } from '@/services/EmployeeService';
import { getCurrentCompany } from '@/store/app';
import { employeeActions, getEmployeeQueryParams, getEmployees, getFeeTableEmployees } from '@/store/employee';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import Utils from '@/utils';

export const UnionDues: React.FC = () => {
  const [data, setData] = useState<IEmployeeFee[]>([]);
  const { t } = useTranslation('material');
  const company = useAppSelector(getCurrentCompany());
  const params = useAppSelector(getEmployeeQueryParams());
  const employees = useAppSelector(getEmployees());
  const feeTable = useAppSelector(getFeeTableEmployees());
  const dispatch = useAppDispatch();
  const feeLoading = useAppSelector(getLoading(FeeTableEmployee));
  const employeeLoading = useAppSelector(getLoading(GettingEmployeeList));
  let DataModifine: { [key: string]: IEmployeeFee } = {};

  useEffect(() => {
    if (company && company.id) {
      dispatch(employeeActions.getFeeTableEmployeeRequest({ companyId: company.id }));
      dispatch(employeeActions.getEmployeesRequest({ companyId: company.id, params: { ...params, page: 1, search: undefined } }))
    }
  }, [company]);

  useEffect(() => {
    if (employees && employees.results) {
      const mapData = employees.results.map((e, index) => {
        const item: IEmployeeFee = {
          companyId: e.companyId,
          employeeId: e.id,
          employeeCode: e.employeeCode,
          employeeName: getNameEmployee(e),
          amount: 0,
          index: index + 1
        }
        return item;
      });
      const mapDataWithFee: IEmployeeFee[] = mapData?.map((d) => {
        const item = feeTable?.find((f) => f.employeeId === d.employeeId);
        if (item) {
          return {
            ...d,
            amount: item.amount,
            id: item.id,
          }
        }
        return d;
      });
      mapDataWithFee && setData(mapDataWithFee);
    }
  }, [employees, feeTable]);
  //  [#20680][dung_lt][12/11/2024] lấy tên nhân viên
  const getNameEmployee = (e: EmployeeResponse) => {
    const lastName = e?.lastName ?? '';
    const middleName = e?.middleName ?? '';
    const firstName = e?.firstName ?? '';
    return `${lastName + ' ' + middleName + ' ' + firstName}`;
}
  //  [#20680][dung_lt][12/11/2024] config cho cột của table
  const columnsConfig: { [key: string]: iColumnsConfig } = {
    index: {
      title: t('Numerical order'),
      width: 100,
      type: eColumnsTpye.text,
    },
    employeeCode: {
      title:  t('Employee Code'),
      width: 200,
      type: eColumnsTpye.text,
    },
    employeeName: {
      title: t('Employee Name'),
      width: 400,
      type: eColumnsTpye.text,
    },
    amount: {
      title: t('amount of money'),
      width: 150,
      type: eColumnsTpye.inputNumber,
      formaterNumber: true,
    },
  };
  //  [#20680][dung_lt][12/11/2024] kiểm tra có sự thay đổi giá trị không
  const checkModified = (value: IEmployeeFee) => {
    const item = data.find((d) => d.index === value.index);
    if (item) {
      return item.amount !== value.amount;
    }
    return false;
  }

  //  [#20680][dung_lt][12/11/2024] gửi bảng chi phí lên server
  const handleApply = () => {
    const newFee: IEmployeeFee[] = [];
    const updateFee: IEmployeeFee[] = [];
    const arr = Object.values(DataModifine);
    arr.forEach((d) => {
      const item: IEmployeeFee = {
        companyId: d.companyId,
        employeeId: d.employeeId,
        employeeCode: d.employeeCode,
        employeeName: d.employeeName,
        amount: d.amount
      }
      if (d.id) {
        updateFee.push({
          ...item,
          id: d.id
        });
      } else {
        newFee.push(item);
      }
    });
    if (newFee && newFee.length > 0) {
      dispatch(employeeActions.createFeeTableEmployeeRequest({ feeTable: newFee }))
    }
    if (updateFee && updateFee.length > 0) {
      dispatch(employeeActions.updateFeeTableEmployeeRequest({ feeTable: updateFee }))
    }
    DataModifine = {};
   
  };
  return (
    <div className="MachineList">
      <div className={styles.tabheader}>
        <div className={styles.headerContent}>
          <h4>{t('union dues')}</h4>
        </div>
        <div className="tab-header-diary">
          <Button type="primary" onClick={handleApply} className="apply-button">
            Lưu thay đổi
          </Button>
        </div>
      </div>
      {(feeLoading || employeeLoading) && <Loading />}
      {!(feeLoading || employeeLoading) && <div style={{ padding: 10 }}>
      <TableCustom
        dataSource={data}
        columnsConfig={columnsConfig}
        onChange={(value, type) => {
          if (checkModified(value)) {
            DataModifine = Utils.setDataModified(DataModifine, value.index, value, type);
          } else {
            if (value.index in DataModifine) {
              delete DataModifine[value.index];
            }
          }
        }}
      />
        </div>}
    </div>
  );
};

export default UnionDues;
