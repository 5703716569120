import React, { useState } from 'react';

import {
  InboxOutlined,
  UploadOutlined,
  FileExclamationOutlined,
} from '@ant-design/icons';
import { Modal, Button, Upload, UploadFile, message, Select } from 'antd'; // Import Select
import { useTranslation } from 'react-i18next';

import styles from './CreateUploadFilePaymentModal.module.less';
import { documentActions, getFolderRootId, getPathDocument } from '@/store/documents';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

const { Option } = Select;

export default function CreateUploadFilePaymentModal({ isUploadModal, setIsUploadModal, date }: any) {
  const { t } = useTranslation('document');
  const selectedProject = useAppSelector(getSelectedProject());
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const path = useAppSelector(getPathDocument());
  const folderRootId = useAppSelector(getFolderRootId());
  
  // [#20508][dung_lt][25/10/2024] Initialize the current month
  const [selectedMonth, setSelectedMonth] = useState<string>(() => {
    const currentMonth = new Date().getMonth() + 1; // Get current month (1-12)
    return currentMonth.toString(); // Convert to string for Select value
  });

  const dispatch = useAppDispatch();

  const months = [
    { value: '1', label: t('January') },
    { value: '2', label: t('February') },
    { value: '3', label: t('March') },
    { value: '4', label: t('April') },
    { value: '5', label: t('May') },
    { value: '6', label: t('June') },
    { value: '7', label: t('July') },
    { value: '8', label: t('August') },
    { value: '9', label: t('September') },
    { value: '10', label: t('October') },
    { value: '11', label: t('November') },
    { value: '12', label: t('December') },
  ];

  const props = {
    name: 'file',
    multiple: false, // Only allow one file
    beforeUpload: (file: UploadFile) => {
      setFileList([file]); // Always replace the fileList with the newly selected file
      return false; // Prevent auto upload
    },
    onRemove: () => {
      setFileList([]); // Clear the file list when the file is removed
    },
    fileList, // Set the current file list
  };

  const handleCancel = () => {
    setIsUploadModal(false);
  };

  // [#20508][dung_lt][25/10/2024] Tải file thanh toán lên
  const handleOk = async () => {
    if (!selectedMonth) {
      message.error('Please select a month.');
      return;
    }
    
    if (fileList.length === 0) {
      message.error('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', fileList[0] as any); // Append the single file to the form data
    dispatch(documentActions.uploadFilePayment({
      projectId: selectedProject?.id,
      paymentTerm: date === 12 ? 0 : 1,
      paymentTermDate: `2024-${selectedMonth}-${date}`, // Use selected month
      file: formData,
      labelid: !path.length && folderRootId ? folderRootId : path[path.length - 1]?.id,
    }));
    setFileList([]); // Clear the file list when the file is uploaded
    setIsUploadModal(false);
  };

  // [#20508][dung_lt][25/10/2024] Thay đổi lựa chọn tháng
  const handleMonthChange = (value: string) => {
    setSelectedMonth(value); // Update selectedMonth state
  };
//[nam_do][30/10/2024] Căn chỉnh lại giao diện
  return (
    <Modal
      visible={isUploadModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className={styles['custom-modal']}
      width={600}
      // bodyStyle={{ padding: '20px 24px' }}
      title={
        <div className={styles['modal-header']}>
          <span>{t(`Payment Documents ${date}th`)}</span>
        </div>
      }
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 ,marginRight: 80}}>
        <span style={{ marginRight: 10, fontSize: '16px', fontWeight: 700 }}>{t('Month')}:</span>
        <Select
          style={{ width: '50%' }}
          onChange={handleMonthChange}
          value={selectedMonth}
        >
          {months.map((month) => (
            <Option key={month.value} value={month.value}>
              {t(month.label)}
            </Option>
          ))}
        </Select>
      </div>

      <Upload.Dragger
        {...props}
        style={{
          background: 'rgba(250, 250, 250, 1)',
          width: '100%',
          maxWidth: 370,
          margin: 'auto',
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className={styles['ant-upload-text']}>{t('Select or drag and drop documents')}</p>
      </Upload.Dragger>

      <div style={{ textAlign: 'right', marginTop: 20,marginRight: 40  }}>
        <Button
          onClick={handleCancel}
          style={{
            marginRight: 10,
            borderRadius: 50,
            border: '1px solid rgba(9, 109, 217, 1)',
            color: 'rgba(9, 109, 217, 1)',
          }}
        >
          <FileExclamationOutlined />
          {t('Cancel')}
        </Button>
        <Button type="primary" onClick={handleOk} style={{ borderRadius: 50 }}>
          {t('Upload')}
          <UploadOutlined />
        </Button>
      </div>
    </Modal>
  );
}
