/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Empty, Form, FormInstance, Input, Modal, Row, Select, Space, Table, TableProps, TimePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks';
import { getModalVisible, hideModal } from '@/store/modal';
import { UpdateTimekeepingModalName } from '@/common/define';
import { getAllTimeForDayOfEmployees, getTeams, timekeepingActions, getChekinTimeForDay } from '@/store/timekeeping';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useWindowSize } from '@/hooks';
import './style.css';
import ColumnTableForDays from './ColumnTableForDays';
import { EditableCell, EditableRow } from './TimeKeepingBymonth';
import { ShiftResponse } from '@/services/CheckInService';
import Utils from '@/utils';
dayjs.extend(utc);
dayjs.extend(timezone);

interface DataType {
  key: React.Key;
}

interface QueryParams {
  team_id?: number;
  working_day: dayjs.Dayjs;
}

export const ModelUpdateTime = ({ filterParams, queryParamsTemp }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const updateModel = useAppSelector(getModalVisible(UpdateTimekeepingModalName));
  const teams = useAppSelector(getTeams());
  const windowSize = useWindowSize();
  const [dataSource, setDataSource] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const checkIn = useAppSelector(getChekinTimeForDay());
  const { t } = useTranslation('timeKeeping');
  const [queryParams, setQueryParams] = useState<QueryParams>({ working_day: dayjs().startOf('D') });
  const handleCancel = () => {
    dispatch(timekeepingActions.setSlectedUser(undefined));
    dispatch(hideModal({ key: UpdateTimekeepingModalName }));
  };
  const onChangeTeam = (value: number) => {
    const team = teams.find(x => x.id === value);
    if (team) {
      setQueryParams(prev => ({ ...prev, team_id: team.id }));
    }
  };

  const getFormattedDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (queryParams.team_id || queryParams.working_day) {
      const { team_id, working_day } = queryParams;
      dispatch(timekeepingActions.getAllTimeKeepingsForDay({ team_id: team_id ?? queryParamsTemp.team_id , workingDay: working_day.format('YYYY-MM-DD') }));
    } else {
      console.log(getFormattedDate());
      const { team_id, working_day } = queryParamsTemp;
      dispatch(timekeepingActions.getAllTimeKeepingsForDay({ team_id, workingDay: getFormattedDate()}));
    }
    // eslint-disable-next-line
  }, [queryParams, updateModel]);

  const secondsToHHMM = (minutes: number) => {
    if (isNaN(minutes) || minutes < 1) {
      return '';
    }
    const hours = Math.floor(minutes / 60); // Tính số giờ
    const remainingMinutes = minutes % 60; // Tính số phút còn lại
    // Đảm bảo định dạng 2 chữ số (ví dụ: 04 thay vì 4)
    const hh = String(hours).padStart(2, '0');
    const mm = String(remainingMinutes).padStart(2, '0');
    return `${hh}:${mm}`;
  }
  
  const onChangeWorkingDate = (value: any) =>{
    setQueryParams(prev => ({ ...prev, working_day: value }));
  }

  useEffect(() => {
    if (checkIn && checkIn.length > 0) {
      const mapData = checkIn?.map((item: any) => {
        if (item?.approvedExtra !== null) {
          const { ApprovedShiftTime } = JSON.parse(item.approvedExtra);
          return {
            ...item,
            employee: item.employee_Name,
            casang: secondsToHHMM(ApprovedShiftTime && ApprovedShiftTime[8]) || '',
            cachieu: secondsToHHMM(ApprovedShiftTime && ApprovedShiftTime[9]) || '',
            tangca1: secondsToHHMM(ApprovedShiftTime && ApprovedShiftTime[10]) || '',
            tangca2: secondsToHHMM(ApprovedShiftTime && ApprovedShiftTime[18]) || '',
            tangca3: secondsToHHMM(ApprovedShiftTime && ApprovedShiftTime[19]) || '',
            totalApprovedMainShift: secondsToHHMM((ApprovedShiftTime[8] || 0) + (ApprovedShiftTime[9] || 0)) || '',
            totalApprovedOTShift: secondsToHHMM(
              [ApprovedShiftTime?.[10] || 0, ApprovedShiftTime?.[18] || 0, ApprovedShiftTime?.[19] || 0].reduce(
                (sum, value) => sum + +value,
                0,
              ),
            ),
          };
        } 
        if (item?.approved_Shift_Hours === null) { 
          return {
            ...item,
            employee: item.employee_Name,
            casang: "",
            cachieu: "",
            tangca1: "",
            tangca2: "",
            tangca3: "",
            totalApprovedMainShift: secondsToHHMM((item?.approved_Shift_Hours?.[8] || 0) + (item?.approved_Shift_Hours?.[9] || 0)) || "",
            totalApprovedOTShift: secondsToHHMM(
              [
                item?.approved_Shift_Hours?.[10] || 0,
                item?.approved_Shift_Hours?.[18] || 0,
                item?.approved_Shift_Hours?.[19] || 0
              ].reduce((sum, value) => sum + +value, 0)
            )
          };
        } else {
          return {
            ...item,
            employee: item?.employee_Name,
            casang: secondsToHHMM(item?.approved_Shift_Hours && item?.approved_Shift_Hours[8]) || "",
            cachieu: secondsToHHMM(item?.approved_Shift_Hours && item?.approved_Shift_Hours[9]) || "",
            tangca1: secondsToHHMM(item?.approved_Shift_Hours && item?.approved_Shift_Hours[10]) || "",
            tangca2: secondsToHHMM(item?.approved_Shift_Hours && item?.approved_Shift_Hours[18]) || "",
            tangca3: secondsToHHMM(item?.approved_Shift_Hours && item?.approved_Shift_Hours[19]) || "",
            totalApprovedMainShift: secondsToHHMM((item?.approved_Shift_Hours?.[8] || 0) + (item?.approved_Shift_Hours?.[9] || 0)) || "",
            totalApprovedOTShift: secondsToHHMM(
              [
                item?.approved_Shift_Hours?.[10] || 0,
                item?.approved_Shift_Hours?.[18] || 0,
                item?.approved_Shift_Hours?.[19] || 0
              ].reduce((sum, value) => sum + +value, 0)
            )
          };
        }
      });
      setDataSource(mapData);
    } else {
      setDataSource([]);
    }
  }, [updateModel, queryParams, checkIn]);

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row?.employeeCode === item?.employeeCode);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  // [#20647] [hao_lt] tạo column Màn hình chốt giờ cho nhiều nhân sự trong một ngày
  const columns = ColumnTableForDays().map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  
  const timeToMinutes = (timeStr: string): number => {
    if (timeStr.length === 0) {
        return 0;
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return (hours * 60) + minutes; // Tính tổng số phút
  };

  const convertDate = (dateString: string) =>  {
    // Kiểm tra định dạng đầu vào
    if (!/^\d{8}$/.test(dateString)) {
        throw new Error("Định dạng ngày không hợp lệ. Sử dụng YYYYMMDD.");
    }
    // Tách năm, tháng, ngày
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    // Định dạng lại ngày
    return `${year}-${month}-${day}`;
}

  const handleOk = () => form.submit();

  const handleSaveValue = () => {
    if (dataSource && dataSource?.length > 0) {
      let approvedData: any =  null;
      approvedData = dataSource?.map((item: any )=> {
        const approvedShiftTime: any = {};
        if (item.casang.length > 0) approvedShiftTime[8] = timeToMinutes(item.casang);
        if (item.cachieu.length > 0) approvedShiftTime[9] = timeToMinutes(item.cachieu);
        if (item.tangca1.length > 0) approvedShiftTime[10] = timeToMinutes(item.tangca1);
        if (item.tangca2.length > 0) approvedShiftTime[18] = timeToMinutes(item.tangca2);
        if (item.tangca3.length > 0) approvedShiftTime[19] = timeToMinutes(item.tangca3);
        return {
          working_Day: convertDate(item?.date_Key?.toString()),
          face_Identity_Id: item.face_Identity_Id,
          day_Hours: item?.day_Hours,
          approved_Day_Hours: item?.approved_Day_Hours,
          approved_Note: item?.approved_Note || "",
          team_Id: queryParams.team_id,
          approvedExtra: JSON.stringify({
            ApprovedShiftTime: approvedShiftTime,
            TotalApprovedMainShift: timeToMinutes(item.casang) + timeToMinutes(item.cachieu),
            TotalApprovedOTShift: timeToMinutes(item.tangca1) + timeToMinutes(item.tangca2) + timeToMinutes(item.tangca3),
          })
        }
      })
      const listApprovedFinal = approvedData.filter((item: any)=> {
        const itemJson = JSON.parse(item?.approvedExtra);
        if (Object.keys(itemJson?.ApprovedShiftTime)?.length === 0) {
          return;
        }
        return item;
      } )
      dispatch(timekeepingActions.approvedTimeKeepingForMonth({approvedData: listApprovedFinal, accessToken, month: undefined}))
      dispatch(hideModal({ key: UpdateTimekeepingModalName }));
    }
  }


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Modal
        open={updateModel}
        centered
        okText={'Lưu'}
        width={'1000px'}
        destroyOnClose
        className="modal-timekeeping"
        onCancel={handleCancel}
        onOk={handleOk}
        style={{ width: '300px', ...{ important: 'true' } }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <Row style={{ margin: 0, display: 'flex', justifyContent: 'end' }} align="stretch">
            <Space>
            <Button key="remove" type="primary"  onClick={() => handleSaveValue()}>
              {t('Lưu')}
            </Button>
            </Space>
          </Row>
        )}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontSize: '15px', fontWeight: 'bold' }}>{t('Chốt giờ cho nhân sự theo ngày')}</div>
          <div>
            <Select
              style={{ width: 200, marginRight: 25, marginBottom: '5px' }}
              onChange={onChangeTeam}
              options={teams.map((t: any) => ({ label: t.name, value: t.id }))}
              value={queryParams.team_id || queryParamsTemp.team_id}
            />
            <DatePicker
              allowClear={false}
              onChange={onChangeWorkingDate}
              value={queryParams.working_day}
              format={'DD/MM/YYYY'}
              style={{ marginRight: '25px'}}
            />
          </div>
        </div>
        <Table
          className='wrapperTable' 
          rowHoverable={false}
          rowKey={record => record.employeeCode}
          components={components}
          columns={columns as any}
          rowClassName={() => 'editable-row'}
          dataSource={dataSource || []}
          bordered
          size="small"
          scroll={{ x: 1000, y: windowSize[1] - 300 }}
          locale={{
            emptyText: <Empty description={t('No data timekeeping')}></Empty>,
          }}
          pagination={false}
        />
      </Modal>
    </>
  );
};
