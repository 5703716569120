/* eslint-disable import/order */
import { maKhoTongVT } from '@/environment';
import { ChiTietDeNghiMuaHangDTO } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions, getProducts } from '@/store/accountingInvoice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project/projectSelector';
import { RootState } from '@/store/types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProposalData } from '../ProposalHistory/ProposalHistory';
import styles from './ImportGoods.module.css';

interface NhapKhoData {
  id?: number;
  recId?: number;
  ma_vt?: string;
  ma_kho?: string;
  so_luong?: number;
  gia?: number;
  tien?: number;
  gia_nt?: number;
  tien_nt?: number;
  dien_giai?: string;
  tk_no?: string;
  tk_co?: string;
  so_hopdong?: string;
  ma_Vv?: string;
  ma_Km?: string;
  tinh_gia_von_truc_tiep?: boolean;
  createDate?: string; // or Date, depending on how you handle date types
  guid?: string;
  guidRelation?: string;
}
 export interface ImportGood {
  madvcs: string;
  ma_ct: string;
  ngay_ct: string; // ISO 8601 date strin g
  so_ct: string;
  ma_kh: string;
  nguoi_tt: string;
  dien_giai: string;
  ma_nt: string;
  chiTietHangHoa: NhapKhoData[];
  hoaDonVAT: any[]; // Adjust based on actual data structure for VAT invoices
  list_of_extensions: any[]; // Adjust based on actual data structure for extensions
  chiTietDeNghiMuaHang: any[]; // Adjust based on actual data structure for purchase requests
  guidRelation: string;
  guid?: string;
}
const { Title } = Typography;

const ImportGoods: React.FC<{ proposal: ProposalData, handleClose: () => void; }> = ({ proposal, handleClose  }) => {
  const { t } = useTranslation('material');
  const [dienGiai, setDienGiai] = useState<string>();
  const data = proposal.chiTietDeNghiMuaHang;
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  const selectedProject = useAppSelector(getSelectedProject());
  const currentWarehouseCode = selectedProject
  ? (projectwareHouses && projectwareHouses.length > 0
      ? projectwareHouses.find(wh => !wh.warehouseCode.includes('CCDC'))?.warehouseCode
      : undefined) // Không gán giá trị nếu projectwareHouses rỗng
  : maKhoTongVT;
  console.log(currentWarehouseCode);
  const productList = useAppSelector(getProducts());
  const employee = useAppSelector((state: RootState) => state.app.selectedEmployeeDetails);
  const currentUserName = employee ? `${employee.lastName} ${employee.middleName} ${employee.firstName}` : '';
  const [nhapKho, setNhapKho] = useState<ImportGood>({
    madvcs: 'THUCHIEN',
    ma_ct: 'PNKTP',
    ngay_ct: dayjs().format('YYYY-MM-DD'),
    so_ct: 'Test01',
    ma_kh: proposal.ma_kh,
    nguoi_tt: currentUserName,
    dien_giai: '',
    ma_nt: 'VND',
    chiTietHangHoa: [],
    hoaDonVAT: [],
    list_of_extensions: [],
    chiTietDeNghiMuaHang: [],
    guidRelation: proposal.guid,
  });
  const [chiTietHangHoa, setChiTietHangHoa] = useState<ChiTietDeNghiMuaHangDTO[]>([]);
  const [soluongnhanValues, setSoluongnhanValues] = useState<{ [key: string]: string }>({});
  const [ktxacnhanStatus, setKtxacnhanStatus] = useState<{ [key: string]: boolean }>({});
  const [chtxacnhanStatus, setChtxacnhanStatus] = useState<{ [key: string]: boolean }>({});
  const  dispatch = useAppDispatch();
  const handleDiengiaiChange = (value: string) => {
    setDienGiai(value);
    setNhapKho((prevNhapKho) => ({
      ...prevNhapKho,
      dien_giai: value,
    }));
  };
  const resetForm = () => {
    setDienGiai(''); // Reset the explanation field
    setSoluongnhanValues({}); // Reset quantity received values
    setKtxacnhanStatus({}); // Reset technician confirmation status
    setChtxacnhanStatus({}); // Reset commander confirmation status
  };
  const onSaveWarehouseReceipt = () => {
    const updatedChiTietHangHoa = chiTietHangHoa.map((item) => {
      const quantity = parseFloat(soluongnhanValues[item.ma_vt] || '0');
      const price = item.gia ?? 0;
      const total = quantity * price;

      return {
        ma_vt: item.ma_vt,
        gia: price,
        so_luong: quantity,
        tien: total,
        gia_nt: price,
        tien_nt: total,
        dien_giai: item.dien_giai || '',
        ma_kho: currentWarehouseCode,
        createDate: new Date().toISOString(),
      };
    });

    setNhapKho((prevNhapKho) => {
      const newNhapKho = {
        ...prevNhapKho,
        guidRelation: proposal.guid,
        chiTietHangHoa: updatedChiTietHangHoa.map(item => ({
          ...item,
          id: 0,
          recId: 0,
          ma_kho: currentWarehouseCode,
          guidRelation: proposal.guid,
        })) as NhapKhoData[],
      };
      console.log('Updated nhapKho:', newNhapKho);
      dispatch(accountingInvoiceActions.CreatePhieuNhapKho({data: newNhapKho}));
      resetForm();
      handleClose();
      return newNhapKho;
    });
  };
  useEffect(() => {
    const updatedData = data.map((item: any) => {
      const product = productList.find((p) => p.ma_vt === item.ma_vt);
      return {
        ...item,
        name: product?.ten_vt || '',
        unit: product?.dvt || '',
        key: item.ma_vt,
      };
    });
    setChiTietHangHoa(updatedData);
  }, [data, productList]);

  const handleSoluongnhanChange = (key: string, value: string) => {
    setSoluongnhanValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const toggleKtxacnhan = (key: string) => {
    setKtxacnhanStatus((prevStatus) => ({
      ...prevStatus,
      [key]: !prevStatus[key],
    }));
  };

  const toggleChtxacnhan = (key: string) => {
    setChtxacnhanStatus((prevStatus) => ({
      ...prevStatus,
      [key]: !prevStatus[key],
    }));
  };

  const columns: ColumnsType<ChiTietDeNghiMuaHangDTO> = [
    {
      title: <span>{t('Material code')}</span>,
      dataIndex: 'ma_vt',
      key: 'mavattu',
      width: 150,
      align: 'center',
    },
    {
      title: <span>{t('Material name')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 250,
      align: 'center',
    },
    {
      title: <span>{t('Unit')}</span>,
      dataIndex: 'unit',
      key: 'unit',
      width: 93,
      align: 'center',
    },
    {
      title: <span>{t('Approved quantity')}</span>,
      dataIndex: 'so_luong_nhap1',
      key: 'so_luong_nhap1',
      width: 100,
      align: 'center',
    },
    {
      title: <span>{t('Total quantity received')}</span>,
      dataIndex: 'tongsoluong',
      key: 'tongsoluong',
      width: 100,
      align: 'center',
    },
    {
      title: <span>{t('Quantity received this time')}</span>,
      dataIndex: 'soluongnhan',
      key: 'soluongnhan',
      width: 100,
      align: 'center',
      render: (_: any, record: any) => (
        <Input
          value={soluongnhanValues[record.key] || ''}
          onChange={(e) => handleSoluongnhanChange(record.key, e.target.value)}
        />
      ),
    },
    {
      title: <span>{t('Quantity remaining')}</span>,
      dataIndex: 'soluongconlai',
      key: 'soluongconlai',
      width: 100,
      align: 'center',
    },
    {
      title: <span>{t('Quality Assessment (Warehouse Keeper)')}</span>,
      dataIndex: 'danhgia',
      key: 'danhgia',
      width: 265,
      align: 'center',
    },
    {
      title: <span>{t('Commander confirmed')}</span>,
      dataIndex: 'chtxacnhan',
      key: 'chtxacnhan',
      render: (_: any, record: any) => (
        <Button
          icon={
            chtxacnhanStatus[record.key] ? (
              <CheckOutlined style={{ color: 'green' }} />
            ) : (
              <CloseOutlined style={{ color: 'red' }} />
            )
          }
          onClick={() => toggleChtxacnhan(record.key)}
        />
      ),
      width: 130,
      fixed: 'right',
      align: 'center',
    },
    {
      title: <span>{t('Confirmation technique')}</span>,
      dataIndex: 'ktxacnhan',
      key: 'ktxacnhan',
      width: 130,
      render: (_: any, record: any) => (
        <Button
          icon={
            ktxacnhanStatus[record.key] ? (
              <CheckOutlined style={{ color: 'green' }} />
            ) : (
              <CloseOutlined style={{ color: 'red' }} />
            )
          }
          onClick={() => toggleKtxacnhan(record.key)}
        />
      ),
      fixed: 'right',
      align: 'center',
    },
  ];

  return (
    <div>
      <div id="div1">
        <Row>
          <Col span={24}>
            <Title level={3}>{t('Import warehouse goods')}</Title>
          </Col>
        </Row>
        <Form initialValues={{ mavattu: 'DX2509_1', requestDate: dayjs() }}>
          <div className={styles.formContainer}>
            <div>
              <span className={styles.labeltext}>{t('Interpret')}</span>
              <Input
              style={{ width: '100%' }}
              value={dienGiai}
              onChange={(e) => handleDiengiaiChange(e.target.value)}
            />
            </div>
            <Form.Item label={t('Input day')} name="requestDate" className={styles.formItem}>
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </div>
        </Form>

        <Table dataSource={chiTietHangHoa} columns={columns} pagination={false} scroll={{ x: 'max-content', y: '45vh' }} />
        <div id="div2" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
          <Button
            onClick={onSaveWarehouseReceipt}
            style={{ background: 'rgba(24, 144, 255, 1)', color: 'white' }}
          >
            {t('Save warehouse receipt')}
          </Button>
        </div>
      </div>

    </div>
  );
};export default ImportGoods;
