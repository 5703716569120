import { useEffect } from "react";



const OnlyOfficeViewer = ({ fileUrl, fileType, title }: any) => {
  // [#20755][hao_lt][08/11/2024]_View file
  useEffect(() => {
    if (window.DocsAPI) {
        const config = {
          document: {
            fileType: fileType, // Sử dụng fileType được cung cấp
            title: title ? title : "Uploaded Document",
            url: fileUrl,
          },
          editorConfig: {
            mode: "view", // Thay đổi thành "edit" nếu cần chỉnh sửa
          },
        };
        // Khởi tạo DocEditor
        const docEditor = new window.DocsAPI.DocEditor("onlyoffice-viewer", config);
        // Dọn dẹp khi component unmount
        return () => {
          docEditor.destroyEditor && docEditor.destroyEditor();
        };
      } else {
        console.error("DocsAPI is not available on the window object.");
      }
  }, [fileUrl, fileType]);

  return <div id="onlyoffice-viewer" style={{ height: "500px" }}></div>;
};

export default OnlyOfficeViewer;
