/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import { FormatDateAPI } from '@/common/define';
import { maKhoTongMM, maKhoTongVT } from '@/environment';
import { ChiTietHangHoaDieuChuyenDTO, PhieuDieuChuyenDTO } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions, getProducts, getWareHouses } from '@/store/accountingInvoice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import { RootState } from '@/store/types';
import { DeleteOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styles from './NewTranMaterials.module.less';

const { Option } = Select;
const { Title } = Typography;

interface NewTranMaterialsProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewTranMaterials: React.FC<NewTranMaterialsProps> = ({setIsModalVisible}: any) => {
  const { t } = useTranslation('material');
  const dispatch = useAppDispatch();
  const DanhSachVatTu = useAppSelector(getProducts());
  const wareHouses = useAppSelector(getWareHouses());
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  const employee = useAppSelector((state: RootState) => state.app.selectedEmployeeDetails);
  const selectedProject = useAppSelector(getSelectedProject());
  const currentWarehouseCode = (selectedProject && projectwareHouses && projectwareHouses.length > 0)
  ? projectwareHouses.find(wh => !wh.warehouseCode.includes('CCDC'))?.warehouseCode || maKhoTongMM
  : maKhoTongVT;
  const currentUserName = employee ? `${employee.lastName} ${employee.middleName} ${employee.firstName}` : '';
  const Tonkho = useAppSelector((state: RootState) => state.accountingInvoice.Tonkho);
    // Thêm state mới để lưu trữ giá trị của người thực hiện
    const [nguoiThucHien, setNguoiThucHien] = useState(currentUserName);
  const [dataSource, setDataSource] = useState<any[]>([
    {
      key: '1', // Đảm bảo rằng hàng đầu tiên có key
      code: '',
      name: '',
      dvt: '',
      unit: '',
      ma_kho: '',
      dien_giai: ''
    }
  ]);
  const [dataModifying, setDataModifying] = useState<any>({});
  const [ngayCt, setNgayCt] = useState(dayjs());
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalVisible, setIsModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    ma_kh: currentWarehouseCode,
    nguoi_tt: currentUserName,
    dien_giai: '',
    chiTietHangHoa: [
      {
        ma_vt: '',
        so_luong: '',
        dien_giai: '',
        ma_kho1: ''
      }
    ],
    hoaDonVAT: [],
    list_of_extensions: [],
    chiTietDeNghiMuaHang: []
  });

  const validateForm = () => {
    const isValid = formData.nguoi_tt && formData.dien_giai && dataSource.every(item => item.code);
    setIsSubmitDisabled(!isValid);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, dataSource]);

  useEffect(() => {
    dispatch(accountingInvoiceActions.GetWareHouse({ params: {} })); // Gọi API để lấy dữ liệu kho
    dispatch(
      accountingInvoiceActions.GetTonKho({
        data: {
          madvcs: 'THUCHIEN',
          danhSachMaHang: [],
          ngay_kiem_tra: dayjs().format(FormatDateAPI),
          danhSachMakho: [currentWarehouseCode],
        },
        params: {},
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEditableCell = (text: any, record: any, field: string) => {
    if (field === 'code') {
      return (
        <AutoComplete
          options={Tonkho.map(m => ({ value: m.ma_vt }))}
          defaultValue={text}
          onSelect={(value) => {
            const selectedProduct = DanhSachVatTu.find(v => v.ma_vt === value);
            if (selectedProduct) {
              record.name = selectedProduct.ten_vt; // Cập nhật tên vật tư cho hàng cụ thể
              record.dvt = selectedProduct.dvt; // Cập nhật đơn vị tính cho hàng cụ thể
            } else {
              record.name = ''; // Reset nếu không tìm thấy
              record.dvt = '';
            }
            record[field] = value;
            setDataSource([...dataSource]); // Cập nhật lại dataSource để re-render
            validateForm(); // Kiểm tra dữ liệu sau khi thay đổi
          }}
          onChange={(value) => {
            if (!value) {
              record.name = ''; // Xóa tên vật tư khi mã vật tư bị xóa
              record.dvt = ''; // Xóa đơn vị tính khi mã vật tư bị xóa
            }
            record[field] = value;
            setDataSource([...dataSource]); // Cập nhật lại dataSource để re-render
            validateForm(); // Kiểm tra dữ liệu sau khi thay đổi
          }}
          filterOption={(inputValue, option) =>
            option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
          style={{ width: '100%' }} 
        />
      );
    }
    if (field === 'unit') {
      return (
        <>
        <Input
          type="number"
          min={0}
          value={record[field] !== undefined && record[field] !== null ? record[field] : ''}  // Hiển thị số hoặc rỗng
          onChange={(e) => {
            const value = e.target.value;
            const maxQuantity = Tonkho.find(item => item.ma_vt === record.code)?.luong_ton || 0;
    
            // Xử lý khi input bị xóa hết giá trị
            if (value === '') {
              record[field] = undefined;  // Đặt giá trị của record về undefined khi xóa
              setDataSource([...dataSource]);  // Cập nhật lại dataSource
              validateForm();  // Kiểm tra dữ liệu sau khi thay đổi
              return;
            }
    
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
              if (parsedValue > maxQuantity) {
                record[field] = maxQuantity;  // Đặt lại giá trị về lượng tồn tối đa
                setErrorMessages(prev => ({ ...prev, [record.key]: 'Số lượng vượt quá số lượng tồn kho' })); // Thêm thông báo lỗi
              } else {
                record[field] = parsedValue;  // Cập nhật giá trị hợp lệ
                setErrorMessages(prev => ({ ...prev, [record.key]: '' })); // Xóa thông báo lỗi
              }

              setDataSource([...dataSource]);  // Cập nhật lại dataSource để re-render
              validateForm();  // Kiểm tra dữ liệu sau khi thay đổi
            }
          }}
        />
        {errorMessages[record.key] && (
          <div style={{ color: 'red' }}>{errorMessages[record.key]}</div>
        )}
      </>
      );
    }
    return (
      <Input
        defaultValue={text}
        onChange={(e) => {
          record[field] = e.target.value;
          setDataSource([...dataSource]); // Cập nhật lại dataSource để re-render
          validateForm(); // Kiểm tra dữ liệu sau khi thay đổi
        }}
      />
    );
  };

  const columns: ColumnType<(typeof dataSource)[0]>[] = [
    {
      title: <span>{t('Material code')}</span>,
      dataIndex: 'code',
      key: 'code',
      width: 200, // Tăng độ rộng của ô mã vật tư
      render: (text, record) => renderEditableCell(text, record, 'code'),
      align: 'center',
    },
    {
      title: <span>{t('Material name')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 200,
      align: 'center',
      render: (text, record) => <span>{record.name}</span>, 
    },
    {
      title: <span>{t('dvt')}</span>,
      dataIndex: 'dvt',
      key: 'dvt',
      width: 93,
      align: 'center',
      render: (text, record) => <span>{record.dvt}</span>,
    },
    {
      title: <span>{t('unit')}</span>,
      dataIndex: 'unit',
      key: 'unit',
      width: 157,
      render: (text, record) => renderEditableCell(text, record, 'unit'),
      align: 'center',
    },
    {
      title: <span>{t('Project received transfer')}</span>,
      dataIndex: 'ma_kho',
      key: 'ma_kho',
      width: 250,
      render: (text, record) => (
        <AutoComplete
        options={wareHouses.map(warehouse => ({ value: warehouse.ma_kho, label: warehouse.ten_kho }))}
        value={record.ten_kho}  // Hiển thị tên kho trong input
        onSelect={(value, option) => {
          record.ma_kho = value;  // Lưu giá trị mã kho khi chọn
          record.ten_kho = option.label;  // Cập nhật tên kho để hiển thị trong input
          setDataSource([...dataSource]);
          validateForm();
        }}
        onChange={(value) => {
          const selectedWarehouse = wareHouses.find(warehouse => warehouse.ten_kho === value);
          if (selectedWarehouse) {
            record.ma_kho = selectedWarehouse.ma_kho;  // Cập nhật mã kho khi tên kho thay đổi
          } else {
            record.ma_kho = '';  // Nếu không tìm thấy, đặt mã kho rỗng
          }
          record.ten_kho = value;  // Hiển thị tên kho trong input
          setDataSource([...dataSource]);
          validateForm();
        }}
        filterOption={(inputValue, option) =>
          option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        style={{ width: '100%' }}
      />
      ),
      align: 'center',
    },
    {
      title: <span>{t('interpretation')}</span>,
      dataIndex: 'dien_giai',
      key: 'dien_giai',
      width: 157,
      render: (text, record) => renderEditableCell(text, record, 'dien_giai'),
      align: 'center',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (_: any, record: any) => {
        const showDelete = record.key && record.key !== 'summary'; // Loại bỏ điều kiện startsWith
        return (
          showDelete ? (
            <DeleteOutlined
              onClick={() => {
                removeItem(record.key);
              }}
              style={{ fontSize: '18px', color: '#FF0000', border: 'none' }}
            />
          ) : null
        );
      },
    },
  ];

  const removeItem = (key: string) => {
    setDataSource((prevDataSource: any[]) => {
      const newDataModifying = { ...dataModifying };
      delete newDataModifying[key];
      setDataModifying(newDataModifying); // Cập nhật state dataModifying
      return prevDataSource.filter((i) => i.key !== key);
    });
  };

  const addNewRow = () => {
    const newRow: any = {
      key: (dataSource.length + 1).toString(), // Đảm bảo key là duy nhất
      code: '',
      name: '',
      dvt: '',
      unit: '',
      ma_kho: '',
      dien_giai: ''
    };
    setDataSource([...dataSource, newRow]);
  };

  const handleSubmit = () => {
    // [24/10/2024][phuong_td] bỏ số lượng vật tư trong phiếu điều chuyển kho để tránh việc trừ gấp đôi số lượng vật tư
    const chiTietHangHoa_DC: ChiTietHangHoaDieuChuyenDTO[] = [];
    // [24/10/2024][phuong_td] thêm lại số lượng vật tư cho phiếu điều chuyển
    // const chiTietHangHoaNhapXuat: ChiTietHangHoaDieuChuyenDTO[] = [];
    dataSource.forEach(item => {
      const temp = {
        ma_vt: item.code,
        ma_kho: currentWarehouseCode,
        so_luong: item.unit,
        gia: 0,
        tien: 0,
        gia_nt: 0,
        tien_nt: 0,
        ma_kho1: item.ma_kho,
        dien_giai: item.dien_giai,
        createDate: dayjs().toISOString(),
      }
      chiTietHangHoa_DC.push({...temp});
      // chiTietHangHoaNhapXuat.push({...temp, so_luong: item.unit});
    });

    const commonData = {
      madvcs: 'THUCHIEN',
      ngay_ct: ngayCt.format('YYYY-MM-DD'),
      ma_kh: '',
      so_ct: 'Test',
      ma_nt: 'VND',
      chiTietHangHoa: [],
      hoaDonVAT: [],
      list_of_extensions: [],
      chiTietDeNghiMuaHang: [],
    };
     // Tạo phiếu điều chuyển
     const dieuChuyenData: PhieuDieuChuyenDTO = {
      ...commonData, chiTietHangHoa: chiTietHangHoa_DC,
      ma_ct: 'PXDC',
      nguoi_tt: nguoiThucHien,
      dien_giai: formData.dien_giai,
    };
    dispatch(accountingInvoiceActions.CreatePhieuDieuChuyen({ data: dieuChuyenData }));
    setIsModalVisible(false); // hide modal
    // [24/10/2024][phuong_td] bỏ qua việc tạo phiếu nhập và xuất kho khi tạo phiếu điều chuyển
    // Tạo phiếu xuất kho
    // const xuatKhoData: PhieuDieuChuyenDTO = {
    //   ...commonData,
    //   ma_ct: 'PXKTP',
    //   nguoi_tt: nguoiThucHien,
    //   dien_giai: `Xuất kho điều chuyển vật tư từ kho${currentWarehouseCode}`,
    //   chiTietHangHoa: chiTietHangHoaNhapXuat.map(item => ({
    //     ...item,
    //     ma_kho1: '',
    //     gia: 0,
    //     tien: 0,
    //     gia_nt: 0,
    //     tien_nt: 0,
    //   })),
    // };
    // dispatch(accountingInvoiceActions.CreatePhieuXuatKho({ data: xuatKhoData }));

    // Tạo phiếu nhập kho
    // const nhapKhoData: PhieuDieuChuyenDTO = {
    //   ...commonData,
    //   ma_ct: 'PNKTP',
    //   nguoi_tt: nguoiThucHien,
    //   dien_giai: `Điều chuyển vật tư từ mã kho ${currentWarehouseCode} về ${chiTietHangHoaNhapXuat[0].ma_kho1}`,
    //   chiTietHangHoa: chiTietHangHoaNhapXuat.map(item => ({
    //     ...item,
    //     ma_kho: item.ma_kho1,
    //     ma_kho1: '',
    //     gia: 0,
    //     tien: 0,
    //     gia_nt: 0,
    //     tien_nt: 0,
    //   })),
    // };
    // dispatch(accountingInvoiceActions.CreatePhieuNhapKhodc({ data: nhapKhoData }));
   
    // Reset form và data
    setFormData({
      ma_kh: '',
      nguoi_tt: currentUserName,
      dien_giai: '',
      chiTietHangHoa: [{ ma_vt: '', so_luong: '', dien_giai: '', ma_kho1: '' }],
      hoaDonVAT: [],
      list_of_extensions: [],
      chiTietDeNghiMuaHang: []
    });
    setDataSource([{
      key: '1',
      code: '',
      name: '',
      dvt: '',
      unit: '',
      ma_kho: '',
      dien_giai: ''
    }]);
    setNgayCt(dayjs());
  };

  return (
    <div>
      <div id="div1">
        <Row>
          <Col span={24}>
            <Title level={3}>{t('Transfer material')}</Title>
          </Col>
        </Row>
        <Form>
          <Row gutter={16}>
            <Col span={8} className={styles.formItemCol}>
              <Form.Item label={t('TranMaterial code')} name="code" className={styles.formItem}>
                <Input className={styles.inputCode} defaultValue={currentWarehouseCode} readOnly />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.formItemCol}>
              <Form.Item label={t('Date of creation of ticket')} className={styles.formItem}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className={styles.inputDate01}
                  defaultValue={ngayCt} 
                  onChange={(date) => {
                    setNgayCt(date || dayjs());
                    validateForm();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.formItemCol}>
              <Form.Item label={t('People who perform')} className={styles.formItem}>
                <Input
                  className={styles.inputCod}
                  defaultValue={currentUserName}
                  value={nguoiThucHien}
                  onChange={(e) => {
                    setNguoiThucHien(e.target.value);
                    validateForm();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={
              <span>
                {t('interpretation')} <span style={{ color: 'red' }}>*</span>
              </span>
            }
            className={styles.formItem}
            rules={[{ required: true, message: t('interpretation is required') }]} // Thêm rules để bắt buộc nhập
          >
            <Input
              className={styles.inputCod}
              value={formData.dien_giai}
              onChange={(e) => {
                setFormData({ ...formData, dien_giai: e.target.value });
                validateForm(); 
              }}
            />
          </Form.Item>
        </Form>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content', y: 700 }}
        />
      </div>
      <div style={{ display: 'flex' , paddingTop: '10px',paddingLeft: '18px'}}>
                  <Button onClick={addNewRow} style={{ width: '100px' }}>+</Button>
                </div>
      <div style={{ paddingTop: '20px', justifyContent: 'flex-end', display: 'flex' }}>
        <Button
          className={styles.buttonGuidexuat}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          style={{ backgroundColor: isSubmitDisabled ? undefined : 'green', color: isSubmitDisabled ? undefined : 'white' }} // Thay đổi màu sắc khi nút không bị disable
        >
          {t('Xác nhận')}
        </Button>
      </div>
    </div>
  );
};

export default NewTranMaterials;