import { useEffect, useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { AutoComplete, Tooltip } from 'antd';

import { AutoCompleteOptions } from '@/common/define';
const AutoCompleteCustom = ({
  id,
  value,
  optionsList,
  onChange,
  onSelect,
  onBlur,
  className,
  placeholder,
  dropdownStyle,
  style,
  status,
  warning,
  keyElement,
}: {
  id: string;
  value: string;
  optionsList: AutoCompleteOptions[];
  onChange: (id: string, data: string) => void;
  onSelect: (id: string, data: string, label: string) => void;
  className: string;
  placeholder: string;
  dropdownStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  status?: 'error' | 'warning';
  onBlur: (id: string, data: string) => void;
  warning: string;
  keyElement?: string;
}) => {
  const [TextValue, setTextValue] = useState(value);
  const [options, setOptions] = useState<AutoCompleteOptions[]>([]);
  const getPanelValue = (
    searchText: string,
  ): {
    label: string;
    value: string;
    item: {
      name: string;
      code: string;
    };
  }[] => {
    // [15/10/2024][#20438][phuong_td] If searchText is invalid, then return the Options list
    if (!searchText) return optionsList;
    return optionsList.filter(item => {
      let st1 = item?.label ?? '';
      let st2 = searchText ?? '';
      if (!st2) return false;
      return st1.toUpperCase().includes(st2.toUpperCase());
    });
  };

  useEffect(() => {
    setTextValue(value);
  }, [value, keyElement]);
  useEffect(() => {}, [TextValue]);
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <AutoComplete
        className={className}
        value={TextValue}
        options={options}
        style={{ ...style, marginRight: 2 }}
        status={status}
        onBlur={() => {
          onBlur(id, TextValue);
        }}
        // [15/10/2024][#20438][phuong_td] When Focus, display the option list
        onFocus={() => {
          setOptions(getPanelValue(TextValue));
        }}
        onSelect={(text, options) => {
          onSelect(id, text, options.label);
          setTextValue(options.label);
        }}
        onSearch={(text: any) => setOptions(getPanelValue(text))}
        onChange={text => {
          setTextValue(text);
          onChange(id, text);
        }}
        placeholder={placeholder}
        dropdownStyle={dropdownStyle}
      ></AutoComplete>
      {status === 'error' && (
        <Tooltip title={warning}>
          <WarningOutlined style={{ fontSize: '15px', color: '#FF0000', border: 'node' }} />
        </Tooltip>
      )}
    </div>
  );
};
export default AutoCompleteCustom;
