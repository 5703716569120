import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface CreateLabelData {
  labelCode?: string;
  name: string;
  color?: string;
  type?: 'folder';
  children?: CreateLabelData[];
  parentId?: string;
  projectId?: number;
}

class LabelController {
  public Get = {
    getLabelByDocumentId: (documentId: string, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Label/files/${documentId}`, options);
    },
  };
  public Post = {
    createRootFolder: (inputValues: CreateLabelData, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Label/createFolderRootProject`, inputValues, options);
    },
    createLabel: (projectId: number, inputValues: CreateLabelData, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Label/${projectId}`, inputValues, options);
    },
  };
  public Delete = {
    deleteLabel: (labelId: string, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/Label/${labelId}`, options);
    },
    deleteLabels: (labelIds: string[], options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/Label/deleteLabels`, options, labelIds);
    },
  };
}

export const LabelService = new LabelController();
