import { MenuProps } from 'antd';
import { TFunction } from 'i18next';
import { JwtPayload } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import { ChiTietDeNghiMuaHangDTO, ChiTietHangHoaDTO, ExtensionDTO, HoaDonVATDTO } from '@/services/AccountingInvoiceService';
import { IssuesResponse } from '@/services/IssueService';
import { CreateProjectWarehousePayload } from '@/services/ProjectService';

export type CheckboxValueType = string | number | boolean;

export interface LoginInput {
  username: string;
  password: string;
  captchaId?: string;
  captcha?: string;
  remember: boolean;
}

export interface LoginPayload {
  grant_type: string;
  scope: string;
  username: string;
  password: string;
  client_id: string;
  client_secret?: string;
}

export interface JwtDecoded extends JwtPayload {
  profile: string;
  role: string[];
  orgRoles: string[];
  CompanyId: number;
  OrgId: string;
}

export const NavbarHeight = 70;
export const LeftPanelWidth = 240;

export const formatDateDisplay = 'DD/MM/YYYY';
export const formatDateGantt = '%d-%m-%Y';
export const formatTimeOnly = 'HH:mm:ss';

export interface Project {
  id: number;
  external_id: number;
  title: string;
  address: string;
  status?: ProjectStatus;
  photoUrl?: string;
}

export enum ProjectStatus {
  BIDDING = 'bidding',
  COMPLETED = 'completed',
  EXECUTING = 'executing',
}

export const persitConfigKey = 'persitConfig';

export type MenuItem = Required<MenuProps>['items'][number];

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào đây để sử dụng
 */
enum IconSvgEnum {
  bidding,
  contract,
  prepareConstruction,
}

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào IconSvgEnum trong common/define.ts để sử dụng
 */
export type IconSvgType = keyof typeof IconSvgEnum;

export interface ProjectMemberType {
  key: string;
  name: string;
  role: string[];
}

export interface CheckboxType {
  key: string;
  label: string;
  value: string;
}
export interface ProjectInformationValue {
  projectName: string;
  projectStartDate: Date | null | undefined;
  projectEndDate: Date | null | undefined;
  address: string;
  description: string;
  projectAvatar: UploadFile | null;
  investorName: string;
  investorPhone: string;
  investorEmail: string;
  status: number;
  warehouses: CreateProjectWarehousePayload[]
}

export interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export type ViewState = 'List' | 'Gantt' | 'Kanban';

export enum FormType {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface ITeamInformation {
  teamName: string;
  description: string;
  note: string;
}

export interface PagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  // results: any[];
}

export const defaultPagingParams = {
  paging: true,
  page: 1,
  pageSize: 20,
};

// [09/11/2024][#20629][phuong_td] interface params
export interface iOptions {
  filter?: string,
  expand?: string,
  select?: string,
  search?: string,
  paging?: boolean,
  orderBy?: string,
  ascending?: boolean,
  // SortBy.Key?: string,
  // SortBy.Value?: string,
  page?: number,
  pageSize?: number,
  offset?: number,
  take?: number,
  typeSearch?: number,
  startDate?: string,
  endDate?: string,
  baseDate?: string,
}

export const largePagingParams = {
  paging: true,
  page: 1,
  pageSize: 2000,
};

export enum sMilestone {
  Bid = "DuThau",
  ContractBiddingKPIs = "HopDongKPI",
  PrepareForConstruction = "ChuanBiTC",
  SetupInitialProgress = "ThiCong",
  WaitingForApproval = "Dang_Cho_Duyet",
  Approved = "Da_Duyet",
  Processing = "Dang_Thuc_Hien",
  Complete = "Hoan_Thanh",
  Pause = "Tam_Dung",
  SalaryKpi = "LuongKPI",
  SalaryPaymentOne ="ThanhToanLan1",
  SalaryAdvanceTwo = "UngLuongLan2",
  SalaryPaymentTwo = "ThanhToanLan2",
  WeeklyWork = "Cong_Viec_Hang_Tuan"
}

export enum eTypeUpdate {
  Checklist = 0,
  WeeklyAssignment = 1,
  AssignWork = 2,
  SetupInitialProgress= 3,
}

export enum eAttribute {
  IsoNo = 'IsoNo',
  SpoolNo = 'SpoolNo',
  LineNo = 'LineNo',
  EquipmentNo = 'EquipmentNo',
  EnlNo = 'EnlNo',
  StructureNo = 'StructureNo',
  CivilNo = 'CivilNo',
  TestType = 'TestType',
  WeldingCompleteDate = 'WeldingCompleteDate',
  PunchAClearDate = 'PunchAClearDate',
  ReadyForTestDate = 'ReadyForTestDate',
  SelectionSetName = 'SelectionSetName',
  DailyCapacity = 'DailyCapacity',
  WelderMP = 'WelderMP',
  Dinh_Muc_Luong = 'Dinh_Muc_Luong',
  So_Cong = 'So_Cong',
  So_Cong_Con_Lai = "So_Cong_Con_Lai",
  Khoi_Luong_Con_Lai = "Khoi_Luong_Con_Lai",
  Khoi_Luong = "Khoi_Luong",
  So_Cong_Hoan_Thanh = 'So_Cong_Hoan_Thanh',
  KL_Giao = "KL_Giao"
}

export enum MilestoneLabel {
  Bid = "Dự thầu",
  ContractBiddingKPIs = "Hợp đồng. KPI đấu thầu",
  PrepareForConstruction = "Chuẩn bị thi công",
  SetupInitialProgress = "Thi Công",
}

// [07/11/2024][#20719][phuong_td] AttributesUpdateDTO
export interface AttributesUpdateDTO {
  attributeId: number;
  issuesId: number;
  dateTimeValue: string; // '2024-11-06T13:11:05.535Z';
  value: string;
  valueType: number;
  code: string;
}

// [07/11/2024][#20719][phuong_td] EmployeeReportDTO
export interface EmployeeReportDTO {
  employeeId: number;
  employeeCode: string;
  issueId: number | null;
  teamId?: number;
  projectId?: number;
  id?: number;
  employeePhone: string;
  employeeEmail: string;
  employeeName: string;
  trackerId: number;
  note: string;
  startTime: string;
  endTime: string;
  employReportAttributes: EmployeeReportAttributesDTO[];
  CreateTime: string;
  // [08/11/2024][phuong_td][#20692] EmployeeType cho report
  employeeType: eEmployeeType; // (0 là nhân sự thường, 1 là nhân sự bổ sung)
}

export enum eEmployeeType {
  ThongThuong = 0,
  BoXung = 1,
}

// [07/11/2024][#20719][phuong_td] EmployeeReportAttributesDTO
export interface EmployeeReportAttributesDTO {
  employeeReportId: number;
  attributeId: number;
  value: string;
  valueType: number;
  note: string;
  attributeCode: string;
}

export interface TagVersion {
  id: number,
  name: string,
  code: string,
  description: string,
  status: number,
  order: number,
  type: number,
  companyId: number,
  projectId: number,
  creatTime: string
}

export interface Tracker {
  id?: number;
  name: string,
  code: string,
  type: number
  projectId: number
  companyId: number
  targetIds: number[],
  materialIds: number[],
  otherResourcesIds: number[],
  laborsIds: number[],
  machineryIds: number[],
  attributeIds: number[]
}

export interface TargetTrackerDTO {
  targetId: 0,
  tracker: 0,
  category: 0,
  id?: number
}

export interface TargetDTO {
  id: number,
  description: string,
  name: string,
  actualDisplayName: string,
  code: string,
  status: number,
  tracker: number,
  categoryId: number,
  projectId: number,
  unitVolume: string,
  unitCategory: string,
}

export interface RelationshipDTO {
  issueFirstId: number,
  issueSecondId: number,
  type: number,
  dayRelationship: number
  issueSecond?: IssuesResponse
  issueFirst?: IssuesResponse
  relationshipCode: string,
  relationshipId: number,
  durationRelationship: number,
}

export interface RelationshipUpdateDTO {
  type: number,
  dayRelationship: number
  relationshipCode: string | null,
  relationshipId: number,
  durationRelationship: number,
}

export interface UploadFile {
  issueId: number,
}

export interface IssueMaterialsQuota {
  issuesId: number,
  materialId: number,
  requiredQuantity: string,
  actualQuantity: string,
  unitOfMeasure: number,
  status: number,
  name: string
}

export interface MaterialsDimDTO {
  id: number;
  name: string;
  type: number;
  description: string;
  unitOfMeasure: string;
  status: number;
  code: string;
}

export interface MachineryDimDTO {
  name: string;
  hourlyRate: number;
  description: string;
  status: number;
  id: number;
  type: number;
  code: string;
}

export interface AttributeDimDTO {
  name: string,
  code: string,
  valueType: number,
  status: number,
  notes: string,
  defaultValue: string,
  companyId: number
  id?: number;
  AttributeId: number;
  issuesId?: number;
  value?: string;
  dateTimeValue?: string;
  type?: number;
}

export interface LaborDimDTO {
  name: string,
  hourlyRate: number,
  description: string,
  skillSet: number,
  status: number
}

export enum targetType {
  Issuse = "Issuse",
  Category = "Category",
}

export enum eOrderResourceName {
  NhanCong= "Nhân Công"
}

export interface IssueMaterialsQuota {
  issuesId: number,
  materialId: number,
  requiredQuantity: string,
  actualQuantity: string,
  unitOfMeasure: number,
  status: number,
  name: string
}

export enum StatusLabel {
  All = 'Tất cả',
  Pending = 'Đang chờ duyệt',
  Approved = 'Đã duyệt',
  Processing = 'Đang thực hiện',
  Done = 'Hoàn thành'
}

export enum StatusColor {
  Pending = 'gray',
  Approved = 'orange',
  Processing = 'pink',
  Done = 'green'
}

export enum eNatureOfTheJob {
  DailyRepetitiveWork = 0,
  DetailedArisingWork = 1,
  UnexpectedWork = 2,
}

export enum eCategoryString {
  Foundation = "Phan_Mong",
  BodyPart = "Phan_Than",
  FinishingSection = "Phan_Hoan_Thien",
  BuildTheWall = "Xay_Tuong",
  InternalWallPlastering = "Trat_Tuong_Trong",
  PlasteringExteriorWalls = "Trat_Tuong_Ngoai",
  WallTiling = "Op_Lat_Tuong_Nen",
  Cleaning_AcceptanceAndHandover = "VS_Nghiem_Thu_Ban_Giao",
  Summary = "Summary"
}

export enum eTrackerCode {
  PipingTask = "PipingTask",
  EquipmentTask = "EquipmentTask",
  EAndITask = "E&ITask",
  StructureTask = "StructureTask",
  CivilTask = "CivilTask",
  PipingTestPackage = "PipingTestPackage",
  PipingAreaWorkload = "PipingAreaWorkload",
  CongViecHangTuan = "CongViecHangTuan",
  GiaoViecTheoNgay = 'GiaoViecTheoNgay',
}

export enum eCategoryNumber {
  Foundation = 0,
  BodyPart = 1,
  FinishingSection = 2,
  BuildTheWall = 3,
  InternalWallPlastering = 4,
  PlasteringExteriorWalls = 5,
  WallTiling = 6,
  Cleaning_AcceptanceAndHandover = 7,
  Summary = 8
}

export enum FileStatusConstant {
  repairing = 'repairing',
  uploading = 'uploading',
  success = 'success',
  error = 'error',
}

export interface FileStatus {
  percent: number;
  status: FileStatusConstant;
  file?: FormData;
  fileId: string;
  documentId?: string;
  name?: string;
  error?: string;
}

export const NatureOfWorks = () => {
  const { t } = useTranslation('status');

  return [
    {
      label: t('DetailedArisingWork'),
      value: eNatureOfTheJob.DetailedArisingWork,
    },
    {
      label: t('DailyRepetitiveWork'),
      value: eNatureOfTheJob.DailyRepetitiveWork,
    },
    {
      label: t('UnexpectedWork'),
      value: eNatureOfTheJob.UnexpectedWork,
    }
  ]
}

export const WorkingProgress = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '10%',
    value: 10,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '40%',
    value: 40,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '60%',
    value: 60,
  },
  {
    label: '70%',
    value: 70,
  },
  {
    label: '80%',
    value: 80,
  },
  {
    label: '90%',
    value: 90,
  },
  {
    label: '100%',
    value: 100,
  },
]

export interface CategoryDTO {
  id: number,
  name: string,
  code: eCategoryString,
  parentCode?: eCategoryString,
  CompanyId: number, 
}

export const Category = (categorys: CategoryDTO[] | undefined, t:any) => {
  const category: {
    value: number | undefined;
    id: number,
    label: string;
    code: eCategoryString
  }[] = [];
  if (categorys) {
    categorys?.forEach((c)=>{
      category.push({
        value: c.id,
        label: t(c.code),
        id: c.id,
        code: c.code
      })
    })
  }
  return category;
}


export enum eDateGanttOption {
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS='Months',
  YEARS='Years'

}

export class TabItems {
  t:  TFunction<string, undefined>;
  items: IItemDataGanttOption[];
  constructor(t: TFunction<string, undefined>) {
    this.t = t;
    this.items = [
      { label: this.t('Date'), key: eDateGanttOption.DAYS },
      { label: this.t('Week'), key: eDateGanttOption.WEEKS },
      { label: this.t('Month'), key: eDateGanttOption.MONTHS },
      { label: this.t('Year'), key: eDateGanttOption.YEARS },
    ];
  }
  getItems() {
    return this.items;
  }
}

export class ItemColorNotes {
  t:  TFunction<string, undefined>;
  styles: any;
  items: IItemColorNote[];
  constructor(t:  TFunction<string, undefined>, styles: any) {
    this.t = t;
    this.styles = styles;
    this.items = [
      { label: this.t('Plan'), className: this.styles.Plan },
      { label: this.t('Ahead of Schedule'), className: this.styles.AheadOfSchedule },
      { label: this.t('Behind Schedule'), className: this.styles.BehindSchedule },
      { label: this.t('In Progress'), className: this.styles.InProgress },
      { label: this.t('Completed'), className: this.styles.Completed },
    ];
  }

  getItems() {
    return this.items;
  }

}
export interface IDataLinks {
 id: number
 source: string | number
 target: string | number
 type: string
}

export interface IDataPredecessor {
  key: string
  id: string
  taskName: string
  type: EPredecessorType
  lag: number
 }

export interface IDataGantt {
  id: number | string
  text: string
  start_date: string | null
  end_date: string | null
  start_date_ac: string | null
  end_date_ac: string | null
  duration: number | null
  durationDisplay: number
  parent: number | string | null
  progress: number
  progressDisplay: string
  row_height: number
  open?: boolean
  isCategory?: boolean
  isEmptyChildren?: boolean
  isEmptyStartDate?: boolean
  isEmptyEndDate?: boolean
}
export interface IPropsGantt {
  infoParentComponent: IInforParenComponent
}

export interface IInforParenComponent {
  isApplyCategory?: boolean
  tagVersionId: number
  typeUpdate: eTypeUpdate | sMilestone
  pageSize: number
  ascending?: boolean
}

export interface IItemDataGanttOption {
  label: string
  key: eDateGanttOption
}

export interface IItemColorNote {
  label: string
  className: string
}

export enum EPredecessorType {
  FinishToStart = 'FinishToStart', // 1
  StartToStart = 'StartToStart', // 2
  StartToFinish = 'StartToFinish', // 3
  FinishToFinish = 'FinishToFinish', // 4
}

export interface IMultiIssueUpdateDate {
   id: number
   planeStart: string
   planeEnd: string
}

export enum Resources {
  NhanCong = "NhanCong",
  KhoiLuongGiao = "KhoiLuongGiao",
  DonViTinh = "DonViTinh",
  Loai = "Loai",
  DonGia = "DonGia",
}


export enum eTypeVatTuMayMoc {
  VatTuChinh = "VatTuChinh",
  VatTuPhu = "VatTuPhu",
  MayMoc = "MayMoc",
}
export enum Paythesubcontractor {
  ThanhToan12 = "ThanhToan12",
  ThanhToan27 = "ThanhToan27",
}

export enum eTypeVatTu {
  VatTuChinh = 1,
  VatTuPhu = 0,
}

export interface VatTuMayMocDTO {
  key: string | number;
  id: number;
  ma: string,
  name: string;
  unitOfMeasure: string;
  kldinhmuc?: string;
  tongdacap?: string;
  dexuat?: string;
  tonkho?: string;
  checkbox?: boolean;
  soluonghienco?: string;
  vitri?: string;
}

export interface ProposalType {
  key: string;
  ma: string | JSX.Element;
  ten: string | JSX.Element;
  donvi: string | JSX.Element;
  klkehoach: string | JSX.Element;
  tonkho: string | JSX.Element;
  klconlai: string | JSX.Element;
  dexuat: string | JSX.Element;
  ngaynhap?: string | JSX.Element;
  ghichu: string | JSX.Element;
  maKho: string | JSX.Element;
  tenKho: string | JSX.Element;
  Mavv: string | JSX.Element;
  DienGiai: string | JSX.Element;
  isRowFuction?: boolean;
  capDuyet?: number;
  gia1?: string | JSX.Element;
  ncc1?: string | JSX.Element;
  gia2?: string | JSX.Element;
  ncc2?: string | JSX.Element;
  gia3?: string | JSX.Element;
  ncc3?: string | JSX.Element;
}

export interface PhieuDeNghiMuaHangDTO {
  id?:  number,
  del?: boolean,
  madvcs: string, // mã đv cs
  recId?:  number,
  RecID?:  number,
  ma_ct?: string, // mã chứng từ
  ngay_ct?: string, // 2024-09-20T14:04:08.540Z ngày chứng từ
  so_ct?: string, // số chứng từ
  ngay_hoa_don?: string,
  loai_tt?:  number,
  han_tt?: string, // 2024-09-20T14:04:08.540Z
  ma_kh?: string,
  ma_bo_phan?: string,
  nguoi_tt?: string,
  nv_bh?: string,
  dia_chi?: string,
  dien_giai: string,
  ma_nt?: string,
  ty_gia?:  number,
  info?: string,
  is_local?: boolean,
  release?: boolean,
  moduleName?: string,
  createDate: string, // 2024-09-20T14:04:08.540Z
  CreateDate?: string, // 2024-09-20T14:04:08.540Z
  capDuyet?:  number,
  capDuyetHienTai?: number,
  customerName?: string,
  customerAddress?: string,
  customerPhone?: string,
  recIdparent?:  number,
  nguoiDuyet1?: string,
  nguoiDuyet2?: string,
  nguoiDuyet3?: string,
  recIdrelation?:  number,
  guid?: string, //3fa85f64-5717-4562-b3fc-2c963f66afa6,
  nguoiDuyet4?: string,
  nguoiDuyet5?: string,
  guidRelation?: string, //3fa85f64-5717-4562-b3fc-2c963f66afa6,
  chiTietHangHoa: ChiTietHangHoaDTO[],
  hoaDonVAT?: HoaDonVATDTO[],
  list_of_extensions?: ExtensionDTO[],
  chiTietDeNghiMuaHang?: ChiTietDeNghiMuaHangDTO[]
}

export interface IBaoXuatNhapTonData {
  ma_vt?: string;               // Mã vật tư
  ma_kho?: string;              // Mã kho
  ton_dau?: string;             // Tồn đầu kỳ (đơn vị vật tư)
  du_dau?: string;              // Dư đầu kỳ (giá trị tiền)
  du_dau_nt?: string;           // Dư đầu kỳ (giá trị ngoại tệ)
  luong_nhap?: string;          // Lượng nhập
  tien_nhap?: string;           // Tiền nhập
  tien_nhap_nt?: string;        // Tiền nhập (ngoại tệ)
  luong_xuat?: string;          // Lượng xuất
  tien_xuat?: string;           // Tiền xuất
  tien_xuat_nt?: string;        // Tiền xuất (ngoại tệ)
  ton_cuoi?: string;            // Tồn cuối kỳ
  du_cuoi?: string;             // Dư cuối kỳ (giá trị tiền)
  du_cuoi_nt?: string;          // Dư cuối kỳ (giá trị ngoại tệ)
  ten_vt?: string;              // Tên vật tư
  ShortDescription?: string;    // Mô tả ngắn
  dvt?: string;                 // Đơn vị tính
  tk_vt?: string;               // Tài khoản vật tư
  cach_tinh_gia?: string;       // Cách tính giá
  ParentID?: string;            // ID cha (nếu có)
  ma_nhom?: string;             // Mã nhóm
  ten_nhom?: string;            // Tên nhóm
}
export interface AutoCompleteOptions {
  label: string;
  value: string;
  item: {
    name: string;
    code: string;
  }
}

export enum eReviewTypeUpdate {
  SET_COMMENTS = "SET_COMMENTS",
  SET_LIKES = "SET_LIKES",
  ADD_COMMENT = "ADD_COMMENT",
}

export interface IReviewMessages {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  results: IReviewItem[];
}

export interface IReviewItem {
  id: number;
  categoryId: number;
  senderId: string;
  senderName: string;
  subject: string;
  companyId: number;
  categoryCode: string;
  content: string;
  createdDate: string;
  toIdList: "[]",
  status: number;
  comments: IReviewComment[];
  selected: boolean; // added
  countLike: number; // added
}

export interface IReviewComment {
  id: number;
  senderId: string;
  senderName: string;
  content: string;
  createdDate: string;
  messageId: number;
  parentId: number;
}

// [09/11/2024][#20629][phuong_td] interface ProjectEmployeeDTO
export interface ProjectEmployeeDTO {
  employeeId: number;
  employeeCode: number;
  name: string;
  code: string;
  role: number;
  roleName: string;
  status: number;
  note: string;
  phone: string;
  email: string;
  projectId: number;
  roles: number[];
  startTime: string;// 2024-11-09T13:46:56.720Z;
  endTime: string;// 2024-11-09T13:46:56.720Z;
  projectName?:string;
}


export enum eStatusRequest {
  success = 'success',
  error = 'error'
}

export enum madvcs {
  THUCHIEN='THUCHIEN',
  KEHOACH="KEHOACH"
}

export enum eMaterialDocument {
  ALL='All',
  RELEASE="Release",
  UNRELEASE = 'Unrelease'
}

export enum eSummaryScreen {
  TONGKHO = 'TONGKHO',
  TONGHOPVATTU = 'TONGHOPVATTU'
}

export enum eTypeReview {
  ProjectManagementSuppliers = "QLDA_NCC",
  SupervisionConsultantsSupplier = "TVGS_NCC",
  InvestorsProjectManagement = "CDT_BQLDA",
  InvestorsSupervisionConsultants = "CDT_TVGS",
  Other = "OTHER"
}

export interface IEmployeeFee {
  companyId: number;
  employeeId: number;
  employeeCode: string;
  employeeName: string;
  amount: number;
  id?: number;
  index?: number;
}

export interface IFeeTableResult {
  results: IEmployeeFee[];
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
}

export enum eTypeDinhMucLuong {
  Team = 0,
  Category = 1
}

export interface IDinhMucThuong {
  date: string; // định dạng "yyyy-MM-ddTHH:mm:ss"
  teamId: number;
  id: number;
  subject: string;
  categoryId: number;
  laborCountIssue: string; // định mức
  laborCountTeam: string; // công giao
  laborCountComplete: string; // thực tế
  arise?: number; // phát sinh
  type?: eTypeDinhMucLuong;
  children?: any[]
}

export const FormatDateAPI = 'YYYY-MM-DD';
export const FormatDateToConvertGantt = 'DD-MM-YYYY';
export const FormatDate = 'DD/MM/YYYY';
export const DateEmptyString = "0001-01-01T00:00:00";

export const CreateUpdateEmployeeModalName = 'CreateUpdateEmployeeModal';
export const GettingEmployeeList = 'GettingEmployeeList';
export const FeeTableEmployee = 'FeeTableEmployee';
export const getEmployeeDetails = 'getEmployeeDetails';
export const SavingEmployee = 'SavingEmployee';
export const RemovingEmployee = 'RemovingEmployee';

export const GettingProjectStatusList = 'GettingProjectStatusList';
export const SavingProject = 'SavingProject';
export const GettingProjectMembers = 'GettingProjectMembers';

export const CreateUpdateTeamModalName = 'CreateUpdateTeamModal';
export const IsUpdateTeamModal = 'IsUpdateTeamModal';
export const GettingTeams = 'GettingTeams';
export const GettingTeamDetails = 'GettingTeamDetails';
export const SavingTeam = 'SavingTeam';
export const RemovingTeam = 'RemovingTeam';

export const AddMemberToTeamModalName = 'AddMemberToTeamModal';
export const SavingTeamMembers = 'SavingTeamMembers';
export const RemovingMemberFromTeam = 'RemovingMemberFromTeam';

export const AddMemberToProjectModalName = 'AddMemberToProjectModalName';
export const GettingProjectRolesLoadingKey = 'GettingProjectRolesKey';
export const CreateManyProjectMemberLoadingKey = 'CreateManyProjectMemberLoadingKey';
export const EditProjectMemberModalName = 'EditProjectMemberModalName';
export const SavingProjectMemberLoadingKey = 'SavingProjectMemberLoadingKey';

export const CreateUpdateIssueModalName = 'CreateUpdateIssueModal';
export const ShowViewFileModal = 'ShowViewFileModal';
export const ControlStatusPreparationModalName = 'ControlStatusPreparationModal';
export const ControlAssignWorkModalName = 'ControlAssignWorkModal';
export const CreateUpdateWorkWeeklyModalName = 'CreateUpdateWorkWeeklyModal';
export const CreateUpdateInitWorkModalName = 'CreateUpdateInitWorkModal';
export const GettingIssueList = 'GettingIssueeList';
export const SavingIssue = 'SavingIssue';
export const RemovingIssue = 'RemovingIssue';
export const RemovingIssueTeam = 'RemovingIssueTeam';
export const GettingIssueStatusList = 'GettingIssueStatusList';
export const GettingIssueProgressList = 'GettingIssueProgressList';
export const GettingIssueByVersionList = 'GettingIssueByVersionList';
export const getIssueChecklist = 'getIssueChecklist';
export const getIssueChecklistByIssueIds = 'getIssueChecklistByIssueIds';
export const getIssueChecklistsTeamByCheckitemIds = 'getIssueChecklistsTeamByCheckitemIds';
export const getIssueChecklistsByTeamId = "getIssueChecklistsByTeamId";
export const getCategoryByCompanyIdRequest = 'getCategoryByCompanyIdRequest';
export const getTagByCompanyIdRequest = 'getTagByCompanyIdRequest';
export const UpdateTimekeepingModalName = 'UpdateTimeKeepingModal';
export const getTeamIdsByIssue = 'getTeamIdsByIssue';
export const getTeamIdsByIssueRequest = 'getTeamIdsByIssueRequest';
export const getIssueTeamsByIssueRequest = 'getIssueTeamsByIssueRequest';
export const updateCheckItems = 'updateCheckItems';
export const UpdateStatusIssue = 'UpdateStatusIssue';
export const createIssueTeamRequest = 'createIssueTeamRequest';
export const updateIssueTeams = 'updateIssueTeams';
export const genIssue = 'genIssue';
export const exportGanttToPDF = 'exportGanttToPDF';

export const getDinhMucThuongs = 'getDinhMucThuongs';

export const removeProjectWarehouse = 'removeProjectWarehouse';

export const OtherResourcesDim = {
  updateOtherResourcesDim: 'updateOtherResourcesDim',
  getOtherResourcesDim: 'getOtherResourcesDim',
  getOtherResourcesDimByTracker: 'getOtherResourcesDim',
  addOtherResourcesDimToIssue: 'addOtherResourcesDimToIssue',
  createOtherResourcesDim : 'createOtherResourcesDim',
  removeOtherResourcesDim: 'removeOtherResourcesDim'
};

export const MaterialsDim = {
  updateMaterialsDim: 'updateMaterialsDim',
  getMaterialsDim: 'getMaterialsDim',
  getMaterialsDimByTracker: 'getMaterialsDim',
  addMaterialsDimToIssue: 'addMaterialsDimToIssue',
  createMaterialsDim : 'createMaterialsDim',
  removeMaterialsDim: 'removeMaterialsDim'
};

export const TrackerDim = {
  getTrackerByProject : 'getTrackerByProject',
  getTrackerByCompany : 'getTrackerByCompany',
  createTrackerDim: 'createTrackerDim',
  updateTrackerDim: 'updateTrackerDim',
  removeTrackerDim: 'removeTrackerDim'
};

export const TargetTracker = {
  createTargetTracker: 'createTargetTracker',
  updateTargetTracker: 'updateTargetTracker'
}

export const Quota = {
  createIssueMaterialsQuota: 'createIssueMaterialsQuota',
  createIssue_OtherResourceQuota: 'createIssue_OtherResourceQuota'
}

export const AttributeDim = {
  updateAttributeDim: 'updateAttributeDim',
  getAttributeDim: 'getAttributeDim',
  getAttributeDimByTracker: 'getAttributeDim',
  addAttributeDimToIssue: 'addAttributeDimToIssue',
  createAttributeDim : 'createAttributeDim',
  removeAttributeDim: 'removeAttributeDim'
};

export const LaborDim = {
  updateLaborDim: 'updateLaborDim',
  getLaborDim: 'getLaborDim',
  getLaborDimByTracker: 'getLaborDim',
  addLaborDimToIssue: 'addLaborDimToIssue',
  createLaborDim : 'createLaborDim',
  removeLaborDim: 'removeLaborDim'
};

export const Target = {
  getTargetByCondition: 'getTargetByCondition',
  updateTargetToIssue: 'updateTargetToIssue',
  addTargetToIssue: 'addTargetToIssue',
  createTargetDim: 'createTargetDim'
}

export const IssueRelationship = {
  createRealtionship: 'createRealtionship',
  getParentIssueRelationshipByIssue: 'getParentIssueRelationshipByIssue',
  getChildIssueRelationshipByIssue: 'getChildIssueRelationshipByIssue',
  removeIssueRelationship: 'removeIssueRelationship',
  updateRealtionship: 'updateRealtionship',
  getAllChildIssueRelationShipFromId: 'getAllChildIssueRelationShipFromId'
}

export const IssueCheckItemsTeam = {
  createIssueCheckItemsTeamRequest: 'createIssueCheckItemsTeamRequest',
  getTeamsIdsByCheckItemIdRequest: 'getTeamsIdsByCheckItemId',
  removeCheckitemsTeamRequest: 'removeCheckitemsTeamRequest'
}

export const Issue = {
  GettingIssueByVersionList: 'GettingIssueByVersionList',
  getIssueByParentId: 'getIssueByParentId',
  UpdateReasonForLeaveRequest: 'UpdateReasonForLeaveRequest',
  updateIssueRequest: 'updateIssueRequest',
  createIssueRequest: 'createIssueRequest',
  assignRequest: 'assignRequest',
  attachmentFileUploadRequest: 'attachmentFileUploadRequest',
};

export const MachinerysDim = {
  updateMachinerysDim: 'updateMachinerysDim',
  getMachinerysDim: 'getMachinerysDim',
  getMachinerysDimByTracker: 'getMachinerysDim',
  createMachineryToIssue: 'createMachineryToIssue',
  createMachinerysDim: 'createMachinerysDim',
  removeMachinerysDim: 'removeMachinerysDim',
  getSelectedMachinerys: 'getSelectedMachinerys',
};

export const accountingInvoice = {
  getProducts: 'getProducts',
  getWareHouse: 'getWareHouse',
  GetProductUnit: 'GetProductUnit',
  GetDanhSachThietBi: 'GetDanhSachThietBi',
  GetMoneyTypeList: 'GetMoneyTypeList',
  CreatePhieuNhapXuatKho: 'CreatePhieuNhapXuatKho',
  DeletePhieuNhapXuatKho: 'DeletePhieuNhapXuatKho',
  GetProposalForm: 'GetProposalForm',
  CreateProposalForm: 'CreateProposalForm',
  ConfirmProposalForm: 'ConfirmProposalForm',
  UpdateProposalForm: 'UpdateProposalForm',
  DeleteProposalForm: 'DeleteProposalForm',
  GetDanhSachDuyetChi:'GetDanhSachDuyetChi',
  GetDanhSachDuyetMuaHang: 'GetDanhSachDuyetMuaHang',
  DeleteDanhSachDuyetMuaHang: 'DeletePhieuNhapXuatKho',
  DuyetChi: 'DuyetChi',
  HuyDuyetChi: 'HuyDuyetChi',
  GetTonKho: 'GetTonKho',
  GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa: 'GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa',
  GetDanhSachBoPhan: 'GetDanhSachBoPhan',
  SplitDeNghiMuaHangTheoNhaCungCap: 'SplitDeNghiMuaHangTheoNhaCungCap',
  GetGiaVaNhaCungCap: 'GetGiaVaNhaCungCap',
  getCustomers: 'getCustomers',
  getBaoCaoXuatNhapTon: 'getBaoCaoXuatNhapTon',
  getAdditionalCosts: 'getAdditionalCosts',
  deleteAttachmentLinks: 'deleteAttachmentLinks',
  uploadAttachmentLinks: 'uploadAttachmentLinks',
  getBaoCaoDoanhThuChiPhi: 'getBaoCaoDoanhThuChiPhi',
}


export const documentProject = {
  GettingDocumentList : 'GettingDocumentList',
  DownloadingDocument : 'DownloadingDocument',
  CreateUpdateFolderModalName: 'CreateUpdateFolderModalName',
  SavingLabel: 'SavingLabel',
  RemovingDocument: 'RemovingDocument',
  RemovingDocuments: 'RemovingDocuments',
}
export const labelProject = {
  SavingLabel: 'SavingLabel',
}

export const EmployeeReport = {
  updateEmployeeReport: 'updateEmployeeReport',
  updateEmployeeReportByWeek: 'updateEmployeeReportByWeek',
  CreateEmployeeReports: 'CreateEmployeeReports',
  getEmployeeReport: 'getEmployeeReport',
  getEmployeeReportByWeek: 'getEmployeeReportByWeek',
  getEmployeeReportByIssue: 'getEmployeeReportByIssue',
  getReportsByDateTime: 'getReportsByDateTime',
  getTeamEmployeeReport: 'getTeamEmployeeReport',
  getEmployeeReportByTeam: 'getEmployeeReportByTeam',
};

export const ProjectName = {
  getEmployeeProjects: 'getEmployeeProjects'
}
