import { memo, useEffect, useRef, useState } from "react";

import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";

import OnlyOfficeViewer from "./OnlyofficeViewer";
import { getEnvVars } from "@/environment";
import { useAppSelector } from "@/store/hooks";
import { getDataFileView, issueActions } from "@/store/issue";
import { getSelectedProject } from "@/store/project";

const extensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'xlsm', 'csv', 'ppt', 'pptx', 'pps', 'ppsx', 'mdb', 'accdb', 'pst', 'ost', 'one', 'onetoc2'];

const { apiUrl } = getEnvVars();
export const FileUpload = ({ previewDataOffice, isModalVisible, setIsModalVisible }: any) => {
  const [fileData, setFileData] = useState<any>(null);
  const selectedProject = useAppSelector(getSelectedProject());
  const dataFileView = useAppSelector(getDataFileView());
  const [ext, setExt] = useState<string>('');
  useEffect(() => {
    const extName = previewDataOffice.name.split('.').pop();
    setExt(extName);
    if (extensions.includes(extName)) {
      if (selectedProject) {
        setFileData({
          fileUrl: `${apiUrl}/Document/downloadFile/${previewDataOffice.id}?companyId=${selectedProject.companyId}`,
          fileType: `${extName}`,
        });
      } else {
        setFileData({
          fileUrl: `${apiUrl}/Document/downloadFile/${previewDataOffice.id}?companyId=1`,
          fileType: `${extName}`,
        });
      }
    }
  }, [previewDataOffice, selectedProject, dataFileView]);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  return (
    <Modal
      title="Document Preview"
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null} // Không có nút dưới modal
      width={'70vw'} // Chiều rộng modal
      styles={{
        body: {
          height: '70vh',
        },
      }}
    >
      {fileData && extensions.includes(ext) && (
        <OnlyOfficeViewer 
          fileUrl={fileData.fileUrl} 
          fileType={fileData.fileType} 
         />
      )}
    </Modal>
  );
};
