import { createSlice } from '@reduxjs/toolkit';

import { eReviewTypeUpdate, IReviewItem, IReviewMessages } from '@/common/define';
import { ReviewDTO } from "@/services/ReviewService";



interface ReviewState {
  reviewMessage: IReviewMessages;
  reviews: ReviewDTO[];
  createReviewData?: ReviewDTO;
  selectedReview: ReviewDTO | undefined;
}

const initialState: ReviewState = {
  reviewMessage: {
    page: 0,
    pageCount: 0,
    pageSize: 0,
    queryCount: 0,
    firstRowIndex: 0,
    lastRowIndex: 0,
    results: [], // IReviewComment
  },
  createReviewData: undefined,  
  reviews: [],
  selectedReview: undefined,
};

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    getReviewMessage: (state, action) => {},
    setReviewMessage: (state, action) => {
      state.reviewMessage = action.payload
    },
    getCommentsByMessage: (state, action) => {},
    getCountLikes: (state, action) => {},
    updateReviewMessage: (state, action) => {
      const {messageId, type} = action.payload;
      // messageId: messageId
      // type: COMMENT/LIKE
      // comments: IReviewComment[]
      // countLike: number
      const reviewMessage = {...state.reviewMessage}
      reviewMessage.results.forEach(x => {
        if (messageId === x.id) {
          if (type === eReviewTypeUpdate.SET_COMMENTS) {
            const {comments} = action.payload;
            x.comments = comments;
          } else if (type === eReviewTypeUpdate.SET_LIKES) {
            const {countLike} = action.payload;
            x.countLike = countLike;
          } else if (type === eReviewTypeUpdate.ADD_COMMENT) {
            const {comment} = action.payload;
            x.comments.push(comment);
          }
        }        
      });
      state.reviewMessage = reviewMessage;
    },
    postReviewMessage: (state, action) => {},
    getReviewsRequest: (state, action) => {},
        
    updateReviewRequest: (state, action) => {console.log(action.payload)},
    setReviews: (state, action) => {
        state.reviews = action.payload;
    },
    createReviewRequest: (state, action) => {console.log(action.payload)},
    setCreateReviews: (state, action) => {
      state.createReviewData = action.payload
    }, 
    removeReviewsRequest: (state, action) => {},
    editReviewRequest: (state, action) => {console.log(action.payload)},
    deleteReviewRequest: (state, action) => {},
    deleteAttachmentLinks: (state, action) => {},
    uploadAttachmentLinks: (state, action) => {console.log(action.payload)},
    getImageUrlAttachmentLinks: (state, action) => {},
    updateAttachementImageUrl: (state, action) => {},
    postLike: (state, action) => {},
    getReviewsById: (state, action) => {console.log(action.payload)},
    setSelectedReview: (state, action) => {
      state.selectedReview = action.payload;
    },
    deleteFilesRequest: (state, action) => {},
  },
});

export const reviewActions = reviewSlice.actions;
export const reviewReducer = reviewSlice.reducer;
