import React, { useState, useEffect } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Button, DatePicker, Upload, Space, InputNumber, message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getEnvVars } from '@/environment';
import { accountingInvoiceActions } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

const { TextArea } = Input;
const { apiUrl } = getEnvVars();

interface CustomUploadFile extends UploadFile {
  // thêm vào interface UploadFile để bao gồm cả trường drawingId
  drawingId: string;
}

interface ExpenseFormUpdateProps {
  // Định nghĩa kiểu dữ liệu cho props của component ExpenseFormUpdate
  record?: any;
}

const ExpenseFormUpdate: React.FC<ExpenseFormUpdateProps> = ({ record }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('material');
  const selectedProject = useAppSelector(getSelectedProject());
  const [fileList, setFileList] = useState<CustomUploadFile[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [objectUrls, setObjectUrls] = useState<string[]>([]);
  const [form] = Form.useForm();
  const company = useAppSelector(getCurrentCompany());

  // useEffect để load dữ liệu ban đầu nếu có record
  useEffect(() => {
    if (record) {
      console.log('record', record);
      // Đặt các giá trị mặc định cho form từ record
      form.setFieldsValue({
        expenseCode: record.costName,
        expenseName: record.costCode,
        payer: record.payer,
        date: record.createDate ? dayjs(record.createDate) : null,
        unit: record.unit,
        quantity: record.quantity,
        amount: record.amount,
        totalAmount: record.totalAmount,
        note: record.notes,
      });

      // khởi tạo danh sách file từ record attachmentLinks
      const initialFileList = record.attachmentLinks.map((attachment: any, index: number) => ({
        uid: `${index}`,
        name: attachment.fileName,
        url: `${apiUrl}/Document/downloadFile/${attachment.drawingId}?companyId=${company.id}`, //lấy trực tiếp link apiUrl
        drawingId: attachment.drawingId,
      }));

      setFileList(initialFileList as CustomUploadFile[]);
    }
  }, [record, form, company.id]);

  const onFinish = async (values: any) => {
    // xử lý khi form được submit
    const dataCreate = {
      projectId: selectedProject?.id,
      costName: values.expenseName,
      costCode: values.expenseCode,
      unit: values.unit,
      createdById: 0,
      createdBy: values.payer,
      createDate: values.date ? values.date.toISOString() : new Date().toISOString(),
      amount: values.amount,
      quantity: values.quantity,
      totalAmount: values.totalAmount,
      payer: values.payer,
      payerId: 0,
      notes: values.note,
    };

    try {
      //gọi hàm dispatch tới action update để cập nhật dữ liệu bao gồm id và dataCreate cần cập nhật
      await dispatch(accountingInvoiceActions.UpdateAdditionalCost({ id: record.id, dataCreate }));
      message.success('Cập nhật chi phí thành công!');

      // Check nếu fileList có file mới thì thông báo tải file thành công
      if (fileList.length > 0) {
        const newFiles = fileList.filter(file => !file.url);

        if (newFiles.length > 0) {
          const formData = new FormData();
          newFiles.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });

          await dispatch(
            // nếu có file thì dispatch tới action createFileCPPS để tải tiếp các file lên

            accountingInvoiceActions.createFileCPPS({
              itemId: record.id,
              dataImage: formData,
            }),
          );
          message.success('Tải ảnh lên thành công!');
        }
      }
    } catch (error) {
      message.error('Có lỗi xảy ra, vui lòng thử lại.');
      console.error('Lỗi:', error);
    }
  };

  const handleChange = (info: { fileList: UploadFile[] }) => {
    // Xử lý khi thay đổi danh sách file

    setFileList(info.fileList as CustomUploadFile[]);
  };

  const handleImageClick = (file: UploadFile) => {
    //xử lí phần xem trước ảnh
    if (file.url) {
      setSelectedImage(file.url);
    } else if (file.originFileObj) {
      const objectUrl = URL.createObjectURL(file.originFileObj);
      setObjectUrls(prevUrls => [...prevUrls, objectUrl]);
      setSelectedImage(objectUrl);
    }
  };

  const handleImageClose = () => {
    //đóng lại phần xem ảnh
    setSelectedImage(null);
  };

  const handleRemoveFile = (file: UploadFile) => {
    //xử lí khi xóa file ảnh
    const customFile = file as CustomUploadFile;
    const drawingIds = [customFile.drawingId];
    dispatch(accountingInvoiceActions.deleteFileCPPSRequest({ itemId: record.id, drawingIds })); //dispatch action deleteFileCPPSRequest
    setFileList(prevList => prevList.filter(item => item.uid !== file.uid));
  };

  return (
    <div style={{ maxWidth: 500, borderRadius: 8 }}>
      <h3>{t('Update incidental costs')}</h3>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label={t('Expense Code')} name="expenseCode">
          <Input placeholder={t('Enter expense code')} />
        </Form.Item>

        <Form.Item label={t('Expense Name')} name="expenseName">
          <Input placeholder={t('Enter expense name')} />
        </Form.Item>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Payer')} name="payer">
            <Input style={{ width: '200px' }} placeholder={t('Enter payer')} />
          </Form.Item>
          <Form.Item label={t('Date')} name="date">
            <DatePicker style={{ width: '220px' }} placeholder={t('Select date')} />
          </Form.Item>
        </Space>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Unit')} name="unit">
            <Input style={{ width: '200px' }} placeholder={t('Enter unit')} />
          </Form.Item>
          <Form.Item label={t('Quantity')} name="quantity">
            <InputNumber style={{ width: '220px' }} placeholder={t('Enter quantity')} />
          </Form.Item>
        </Space>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Amount')} name="amount">
            <InputNumber style={{ width: '200px' }} placeholder={t('Enter amount')} />
          </Form.Item>
          <Form.Item label={t('Total1')} name="totalAmount">
            <InputNumber style={{ width: '220px' }} placeholder={t('Enter total')} />
          </Form.Item>
        </Space>

        <Form.Item label={t('Note')} name="note">
          <TextArea rows={2} placeholder={t('Enter note')} />
        </Form.Item>

        <Form.Item label={t('Upload Image')} name="upload">
          <Upload.Dragger
            name="files"
            listType="picture"
            accept=".jpg,.jpeg,.png"
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleChange}
            onPreview={handleImageClick} //xem lại ảnh mới tải lên
            onRemove={handleRemoveFile} //xóa ảnh
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Chọn từ thư mục hoặc kéo thả tệp định dạng jpeg, png</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button type="default" htmlType="button">
              {t('Cancel')}
            </Button> */}
            <Button type="primary" htmlType="submit">
              {t('update')}
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {selectedImage && ( //phần xem lại ảnh mới tải lên và ảnh đã có sẵn
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
          role="dialog"
          aria-modal="true"
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxWidth: '80vw',
              maxHeight: '80vh',
              objectFit: 'contain',
              display: 'block',
              margin: '0 auto',
            }}
          />
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <button type="button" onClick={handleImageClose} style={{ padding: '8px 16px', cursor: 'pointer' }}>
              Đóng
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpenseFormUpdate;
