/* eslint-disable import/order */
import { useEffect, useState } from 'react';

import { Modal } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { FormatDateAPI } from '@/common/define';
import TabHeaderDiary from '@/components/Layout/TabHeaderDiary/TabHeaderDiary';
import { maKhoTongVT } from '@/environment';
import { accountingInvoiceActions, getDieuchuyenvattu, getProducts } from '@/store/accountingInvoice';
import { useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/modal';
import { getSelectedProject, projectActions } from '@/store/project';
import { RootState } from '@/store/types';
import NewTranMaterials from './components/NewTranMaterials';
import TranMaterials from './components/TranMaterials';

export const TransferMaterial = () => {
  const dispatch = useDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const DanhSachVatTu = useAppSelector(getProducts()); 
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  const data = useAppSelector(getDieuchuyenvattu());
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(accountingInvoiceActions.GetProducts({ params: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);
  useEffect(() => {
    if (selectedProject && projectwareHouses) {
      const warehouseCode = projectwareHouses.find(wh => !wh.warehouseCode.includes('CCDC'))?.warehouseCode;
      if (!warehouseCode) {
        return;
      }
      dispatch(
        accountingInvoiceActions.GetDieuChuyenVatTu({
          madvcs: 'THUCHIEN',
          tu_ngay: dayjs().startOf('month').format(FormatDateAPI),
          den_ngay: dayjs().endOf('month').format(FormatDateAPI),
          ma_kho: warehouseCode,
        })
      );
    } else {
      dispatch(
        accountingInvoiceActions.GetDieuChuyenVatTu({
          madvcs: 'THUCHIEN',
          tu_ngay: dayjs().startOf('month').format(FormatDateAPI),
          den_ngay: dayjs().endOf('month').format(FormatDateAPI),
          ma_kho: maKhoTongVT,
        })
      );
    }
  }, [selectedProject, projectwareHouses]);
  const handleDownload = () => {};
  const handleSelectDate = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      const [startDate, endDate] = dates;
      if (startDate && endDate) {
        dispatch(accountingInvoiceActions.setDateRange({ startDate: startDate.format('YYYY/MM/DD'), endDate: endDate.format('YYYY/MM/DD') }));
        if (selectedProject) {
          if (projectwareHouses) {
            const warehouseCode = projectwareHouses.find(wh => !wh.warehouseCode.includes('CCDC'))?.warehouseCode;
            if (!warehouseCode) {
              return;
            }
            dispatch(
              accountingInvoiceActions.GetDieuChuyenVatTu({
                madvcs: 'THUCHIEN',
                tu_ngay: startDate.format(FormatDateAPI),
                den_ngay: endDate.format(FormatDateAPI),
                ma_kho: warehouseCode,
              })
            );
          } else {
            return;
          }
        } else {
          dispatch(
            accountingInvoiceActions.GetDieuChuyenVatTu({
              madvcs: 'THUCHIEN',
              tu_ngay: startDate.format(FormatDateAPI),
              den_ngay: endDate.format(FormatDateAPI),
              ma_kho: maKhoTongVT,
            })
          );
        }
      }
    } else {
      console.log('Khoảng thời gian chưa được chọn đầy đủ.');
    }
  };

  const getprojectwareHouses = () => {
    if (selectedProject) {
      dispatch(
        projectActions.getWarehousesRequest({
          projectId: selectedProject.id,
        })
      );
    }
  };

  // Lấy danh sách warehouse khi component mount hoặc khi selectedProject thay đổi
  useEffect(() => {
    getprojectwareHouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const handleAddTransfer = () => {
    setIsModalVisible(true);
    dispatch(showModal({ key: 'hideCreatePhieuDieuChuyenSuccess' }));
  };

  return (
    <div>
      <TabHeaderDiary text={'Điều chuyển vật tư'} showAddTransferButton={true} onDownload={handleDownload} onSelectDate={handleSelectDate} onAddTransfer={handleAddTransfer} />
      <TranMaterials />
      <Modal open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}width={1250}>
        <NewTranMaterials setIsModalVisible={setIsModalVisible} />
    </Modal>
    </div>
  );
};
