import { filter, withLatestFrom, switchMap, concat, mergeMap, catchError } from "rxjs";

import { groupActions } from "./groupSlice";
import { startLoading, stopLoading } from "../loading";
import { shiftActions } from "../shift";
import { RootEpic } from "../types";
import { defaultPagingParams } from "@/common/define";
import { IAttachmentLinks } from "@/services/AccountingInvoiceService";
import { GroupService } from "@/services/GroupService";
import Utils from "@/utils";

const getGroupsRequest$: RootEpic = (action$, state$) => {
    return action$.pipe(
      filter(groupActions.getGroupsRequest.match),
      withLatestFrom(state$),
      switchMap(([action, state]) => {
        // eslint-disable-next-line
        const { companyId } = action.payload;
        console.log(companyId);
        return concat(
          [startLoading({ key: 'getGroupsRequest' })],
          GroupService.Get.getGroup(action.payload).pipe(
            mergeMap(groups => {
              return [groupActions.setGroups(groups.results)];
            }),
            catchError(errors => {
              Utils.errorHandling(errors);
              return [groupActions.setGroups(undefined)];
            }),
          ),
          [stopLoading({ key: 'getGroupsRequest' })],
        );
      }),
    );
  };

  const editGroupsRequest$: RootEpic = (action$, state$) => {
    return action$.pipe(
      filter(groupActions.editGroupRequest.match),
      withLatestFrom(state$),
      switchMap(([action, state]) => {
        // eslint-disable-next-line
        const { id, queryParams, inputValues } = action.payload;
        return concat(
          [startLoading({ key: 'editGroupsRequest' })],
          GroupService.Put.editGroup(id, inputValues ).pipe(
            mergeMap(groups => {
              return [];
            }),
            catchError(errors => {
              Utils.errorHandling(errors);
              return [];
            }),          ),
          [stopLoading({ key: 'editGroupsRequest' })],
        );
      }),
    );
  };
  const newGroupsRequest$: RootEpic = (action$, state$) => {
    return action$.pipe(
      filter(groupActions.createGroupRequest.match),
      withLatestFrom(state$),
      switchMap(([action, state]) => {
        const { inputValues } = action.payload;
        console.log('test run new request', inputValues);
        return concat(
          [startLoading({ key: 'newGroupsRequest' })],
          GroupService.Post.addNewGroup(inputValues).pipe(
            mergeMap(groups => {
              console.log("group", groups);
              return [
                groupActions.setCreateGroups(groups),
                groupActions.getGroupsRequest(inputValues.companyId)
              ];
            }),
            catchError(errors => {
              Utils.errorHandling(errors);
              return [];
            }),          ),
          [stopLoading({ key: 'newGroupsRequest' })],
        );
      }),
    );
  };


  
  export const groupEpics = [getGroupsRequest$, editGroupsRequest$, newGroupsRequest$];