import React, { useEffect, useState } from 'react';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProjectItem } from './components/ProjectItem';
import styles from './ProjectPage.module.less';
import { largePagingParams } from '@/common/define';
import { ProjectResponse } from '@/common/project';
import FilterContainer from '@/components/Layout/Filter/FilterContainer';
import { getCurrentCompany } from '@/store/app';
import { ConstantStatic, documentActions } from '@/store/documents';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { startLoading } from '@/store/loading';
import { getProjectList, projectActions, getFileRoots } from '@/store/project';

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['projects']);
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCurrentCompany());
  const projectList = useAppSelector(getProjectList());
  const [isHover, setIsHover] = useState(false);
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const listDataFileRoots = useAppSelector(getFileRoots());
  useEffect(() => {
    dispatch(
      projectActions.getStatusListRequest({
        ...largePagingParams,
        type: 0,
      }),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(projectActions.getFolderRootId({ projectId: -1, isGetId: true }));
    if (listDataFileRoots && listDataFileRoots.results &&  listDataFileRoots.results.length === 0 && listDataFileRoots.length === 0) {
       dispatch(projectActions.getFolderRootId({ projectId: -1, isGetId: false }));
    }
  },[]);
  

  const onSearchChange = (evt: any) => {
    const { value } = evt.target;
    setSearchStr(value);
    if (value) {
      const filteredEmployee = projects.filter(prj => {
        const employeeLastName = prj.name.toLowerCase();
        return employeeLastName.includes(value.toLowerCase());
      });
      setProjects(filteredEmployee);
    } else {
      setProjects(projectList);
    }
  };

  const handleSelectProject = (item: ProjectResponse) => {
    dispatch(projectActions.setSelectedProject(item));
    dispatch(projectActions.getFolderRootId({projectId: item.id, isGetId: true}))
    //[#20477] [hao_lt] Tài liệu dự án - Fix bug upload duplicate.
    dispatch(documentActions.setListFilesUpload([]));
    dispatch(documentActions.setFolderRootId(null))
    ConstantStatic.FileDatas = [];
    navigate(`/projects/bidding`);
  };

  const handleCreateProject = () => {
    dispatch(projectActions.setCreateProjectCurrentStep(0));
    dispatch(projectActions.setCreateProjectInformationValue(null));
    dispatch(projectActions.setProjectMemberList([]));
    dispatch(projectActions.setProjectAvatar(''));
    navigate(`/create-project`);
  };

  useEffect(() => {
    dispatch(projectActions.getProjectsByCompanyIdRequest(company.id));
    dispatch(projectActions.setSelectedProject());
    dispatch(projectActions.setProjectMembers(undefined));
    // eslint-disable-next-line
  }, [company]);

  useEffect(() => {
    if (projectList) {
      setProjects(projectList);
    }
    // eslint-disable-next-line
  }, [projectList]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topper}>
      <div>
          <Typography.Text strong className={styles.title}>
            {`${projects.length} dự án`}
          </Typography.Text>
        </div>
        <div>
        <Input
          placeholder={t('Find project name')}
          allowClear
          onChange={onSearchChange}
          className={styles.inputSearch}
          suffix={searchStr ? null : <SearchOutlined />}
        />
        <Button type="primary" shape="round" icon={<PlusOutlined />} size="large" onClick={handleCreateProject}>
          {t('createProject.button')}
        </Button>
        </div>
      </div>

      <div className={styles.projectContainer} >
        <Row
          gutter={[15, 15]}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={`${isHover && styles.scrollAble}`}
        >
          {projects.map(item => {
            return <ProjectItem key={item.id} item={item} handleSelectProject={handleSelectProject} />;
          })}
        </Row>
      </div>
    </div>
  );
};
