import { createSelector } from "@reduxjs/toolkit";

import { defaultPagingParams } from "@/common/define";
import { RootState } from "@/store/types";

export const getEmployeeState = (state: RootState) => state.employee;
export const getDanhSachUserState = (state: RootState) => state.employee.DanhSachUser;
export function getSelectedEmployee () {
  return createSelector([getEmployeeState], state => state.selectedEmployee);
}

export function getEmployees () {
  return createSelector([getEmployeeState], state => state.employees);
}

export function getEmployeeQueryParams () {
  return createSelector([getEmployeeState], state => state.queryParams || defaultPagingParams);
}
export function getFeeTableEmployees () {
  return createSelector([getEmployeeState], state => state.FeeTableEmployee);
}