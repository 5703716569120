import { useEffect, useState } from 'react';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';


import NewReview from './NewReview';
import ReviewListItem from './ReviewListItem';
import styles from '../Review.module.css';
import { IReviewItem } from '@/common/define';
import { getCurrentCompany } from '@/store/app';
import { getEmployeeQueryParams } from '@/store/employee';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { reviewActions } from '@/store/review';

const ReviewList = (props: { messages: IReviewItem[]; onSelectMessage: any, categoryCode: string }) => {
  const { messages, onSelectMessage, categoryCode } = props;
  const [showModal, setShowModal] = useState<boolean>();
  const company = useAppSelector(getCurrentCompany());
  const dispatch = useAppDispatch();
  const [textSearch, setTextSearch] = useState('');
  const [filteredReviewItem, setFilteredReviewItem] = useState<IReviewItem[]>([]);
  
  useEffect(() => {
    if (messages && messages.length > 0) {
      const filter = messages.filter(x => x.subject?.includes(textSearch));
      setFilteredReviewItem(filter);
    } else {
      setFilteredReviewItem([]);
    }
  }, [messages, textSearch]);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(reviewActions.getReviewMessage({
      companyId: Number(company.id),
      categoryCode: categoryCode,
      startDate: "2000-01-01",
      endDate: "3000-01-01",
    }))
  };

  const handleTextChange = (evt: any) => {
    setTextSearch(evt?.currentTarget?.value);
  };
  
  return (
    <>
      <Flex gap="middle" vertical className={styles.reviewList}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={styles.fontLarge} style={{ marginRight: '8px' }}>
            Thông báo
          </span>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size={'middle'}
            style={{ marginLeft: 'auto' }} // Căn chỉnh nút về phía bên phải
            onClick={() => setShowModal(true)}
          >
            {'Soạn mới'}
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            placeholder={'Tìm kiếm'}
            allowClear
            value={textSearch}
            style={{ flex: 1 }} // Chiếm phần còn lại
            suffix={textSearch ? null : <SearchOutlined />}
            onChange={handleTextChange} // Event onChangeText
          />
          {/* <Button
            type="primary"
            icon={<OrderedListOutlined />}
            size={'middle'}
            style={{ marginLeft: '10px' }} // Kích thước cố định
          ></Button> */}
        </div>
        <div style={{ flexGrow: 1, overflowY: 'auto', alignItems: 'center' }}>
          {filteredReviewItem && filteredReviewItem.map((msg: any) => (
            <div style={{ cursor: 'pointer' }}>
              {/* , border: "1px solid #ff0000" */}
              <ReviewListItem message={msg} handleItemClick={onSelectMessage} categoryCode={categoryCode}></ReviewListItem>
            </div>
          ))}
        </div>
      </Flex>
      <Modal open={showModal} title="Thêm mới chủ đề" onCancel={handleCloseModal} footer={null}>
        <NewReview categoryCode={categoryCode} onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ReviewList;
