export const colors = {
  primary: 'rgba(0, 155, 235, 1)',
  complete: '#14aeea',
  progress: '#c41d7f',
  secondary: '',
  sider: {
    bg: '#FFFFFF',
    menu: {
      itemSelected: '#096DD9',
    },
  },
  header: {
    border: 'rgba(217, 217, 217, 1)',
    bg: '#FFFFFF',
  },
  filter: {
    bg: '#FFFFFF',
  },
  projects: {
    project: {
      statusBg: {
        bidding: '#FAAD14',
        completed: '#52C41A',
        executing: '#8C8C8C',
      },
    },
  },
  table: {
    headerBg: {
      gray: 'f0f0f0',
    }
  }
};
