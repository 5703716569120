import { catchError, concat, EMPTY, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { employeeActions } from './employeeSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateEmployeeModalName,
  FeeTableEmployee,
  GettingEmployeeList,
  IEmployeeFee,
  RemovingEmployee,
  SavingEmployee,
  defaultPagingParams,
} from '@/common/define';
import { EmployeeService } from '@/services/EmployeeService';
import Utils from '@/utils';

const getEmployeesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.getEmployeesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { companyId, params, paging } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingEmployeeList })],
        EmployeeService.Get.getEmployees(companyId, paging).pipe(
          mergeMap(employees => {
            return [employeeActions.setQueryParams(search), employeeActions.setEmployees(employees)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [employeeActions.setEmployees(undefined)];
          }),
        ),
        [stopLoading({ key: GettingEmployeeList })],
      );
    }),
  );
};

const getDanhSachUserRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.getDanhSachUserRequest.match),
    switchMap(action => {
      const { options } = action.payload;
      return concat(
        of(startLoading({ key: 'getClientPortTwo' })),
        EmployeeService.Get.getDanhSachUser(options).pipe(
          map(response => {
            return employeeActions.setDanhSachUser(response);
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return EMPTY;
          }),
        ),
        of(stopLoading({ key: 'getClientPortTwo' })),
      );
    }),
  );
};

const createEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.createEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employee } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams };
      return concat(
        [startLoading({ key: SavingEmployee })],
        EmployeeService.Post.createEmployee(employee).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(employee.companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingEmployee })],
      );
    }),
  );
};

const updateEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.updateEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, employee } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams };
      return concat(
        [startLoading({ key: SavingEmployee })],
        EmployeeService.Put.updateEmployee(employeeId, employee).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(employee.companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingEmployee })],
      );
    }),
  );
};

const removeEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.removeEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, companyId } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingEmployee })],
        EmployeeService.delete.removeEmployee(employeeId).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Removed successfully');
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  employeeActions.setQueryParams(search),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingEmployee })],
      );
    }),
  );
};

//  [#20680][dung_lt][12/11/2024] lấy bảng chi phí
const getFeeTableEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.getFeeTableEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { companyId } = action.payload;
      return concat(
        [startLoading({ key: FeeTableEmployee })],
        EmployeeService.Get.getFeeTableEmployee(companyId).pipe(
          mergeMap(fee => {
            return [employeeActions.setFeeTableEmployee(fee.results)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [employeeActions.setFeeTableEmployee(undefined)];
          }),
        ),
        [stopLoading({ key: FeeTableEmployee })],
      );
    }),
  );
};

//  [#20680][dung_lt][12/11/2024] lấy chi phí công đoàn cho các nhân viên
const createFeeTableEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.createFeeTableEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { feeTable } = action.payload;
      return concat(
        [startLoading({ key: FeeTableEmployee })],
        EmployeeService.Post.createFeeTableEmployee(feeTable).pipe(
          mergeMap(fee => {
            Utils.successNotification();
            return [employeeActions.setFeeTableEmployee(fee)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: FeeTableEmployee })],
      );
    }),
  );
};

//  [#20680][dung_lt][12/11/2024] cập nhật chi phí công đoàn cho nhiều nhân viên, input feeTable là IEmployee[]
const updateFeeTableEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.updateFeeTableEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { feeTable } = action.payload;
      const fee = feeTable[0];
      const newFee = feeTable.filter((f: IEmployeeFee) => f.id !== fee.id);
      return concat(
        [startLoading({ key: FeeTableEmployee })],
        EmployeeService.Put.updateFeeEmployee(fee).pipe(
          mergeMap(fee => {
            if (newFee && newFee.length > 0) {
              return [employeeActions.updateFeeTableEmployeeRequest({ feeTable: newFee })];
            } else {
              return [employeeActions.getFeeTableEmployeeRequest({ companyId: fee.companyId })];
            }
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: FeeTableEmployee })],
      );
    }),
  );
};

const getRank$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.getRanksRequest.match),
    switchMap(action => {
      const companyId = action.payload;
      return concat(
        [startLoading({ key: 'getranks' })],
        EmployeeService.Get.getRank(companyId).pipe(
          switchMap(response => {
            return [employeeActions.getRanksSuccess(response)];
          }),
          catchError(error => {
            console.error('Lấy danh sách rank không thành công', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'getranks' })],
      );
    }),
  );
};

const getRankByID$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.getRankByIdRequest.match),
    switchMap(action => {
      const { companyId, rankCode } = action.payload;
      return concat(
        [startLoading({ key: 'getranks' })],
        EmployeeService.Get.getRankById(companyId, rankCode).pipe(
          switchMap(response => {
            return [employeeActions.getRankByIdSuccess(response)];
          }),
          catchError(error => {
            console.error('Lấy danh sách theo id không thành công', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'getranks' })],
      );
    }),
  );
};

const updateEmployeeID$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.updategetByEmployeeIdRequest.match),
    switchMap(action => {
      const { employeeId, dateTime } = action.payload;
      return concat(
        [startLoading({ key: 'updategetByEmployeeIdRequest' })],
        EmployeeService.Put.updategetByEmployeeId(employeeId, dateTime).pipe(
          switchMap(response => {
            return [employeeActions.updategetByEmployeeIdSuccess(response)];
          }),
          catchError(error => {
            console.error('Lấy danh sách theo cán bộ không thành công', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'updategetByEmployeeIdRequest' })],
      );
    }),
  );
};

const updateEmployee$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.updateEmployeeIdRequest.match),
    switchMap(action => {
      const { companyId, employeeId, dateTime, data } = action.payload;
      return concat(
        [startLoading({ key: 'updateEmployeeIdRequest' })],
        EmployeeService.Put.updateEmployeeId(companyId, employeeId, dateTime, data).pipe(
          switchMap(response => {
            Utils.successNotification('Cập nhật dữ liệu thành công');
            return [employeeActions.updateEmployeeIdSuccess(response)];
          }),
          catchError(error => {
            console.error('Lấy danh sách theo không thành công', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'updateEmployeeIdRequest' })],
      );
    }),
  );
};

export const employeeEpics = [
  updateEmployee$,
  updateEmployeeID$,
  getRankByID$,
  getRank$,
  getDanhSachUserRequest$,
  getEmployeesRequest$,
  getFeeTableEmployeeRequest$,
  createEmployeeRequest$,
  createFeeTableEmployeeRequest$,
  updateEmployeeRequest$,
  updateFeeTableEmployeeRequest$,
  removeEmployeeRequest$,
];
