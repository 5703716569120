import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams, FileStatus } from '@/common/define';
import { DocumentResponse } from '@/services/DocumentService';
import { CreateLabelData } from '@/services/LabelService';

interface DocumentState {
  queryParams: any;
  documentPath: DocumentResponse[];
  selectedRowKeys: React.Key[];
  documents? : any;
  folderRootId?: string;
  listFileUpload?: FileStatus[];
}
export class ConstantStatic
{
  static FileDatas: FileStatus[] = [];
}

const initialState: DocumentState = {
  documentPath: [],
  queryParams: defaultPagingParams,
  selectedRowKeys: [],
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    getDocumentsRequest: (state, action) => {},
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getLabelRequest: (state, action) => {},
    getFolderRootIdRequest: (state, action) => {},
    setFolderRootId: (state, action) => {
      state.folderRootId = action.payload;
    },
    setDocumentPath: (state, action) => {
      state.documentPath = action.payload || [];
    },
    setListFilesUpload: (state, action: PayloadAction<FileStatus[]>) => {
      state.listFileUpload = action.payload;
    },
    createLabelRequest: (_state, _action: PayloadAction<{ label: CreateLabelData; projectId: number }>) => {},
    removeLabelRequest: (state, action: PayloadAction<{ labelId: string; parentId: string | undefined }>) => {},
    removeLabelsRequest: (state, action: PayloadAction<{ labelIds: string[]; parentId: string | undefined }>) => {},
    removeDocumentRequest: (state, action: PayloadAction<{ documentId: string; parentId: string | undefined }>) => {},
    removeDocumentsRequest: (
      state,
      action: PayloadAction<{ documentIds: string[]; parentId: string | undefined }>,
    ) => {},
    uploadFiles: (state, action: PayloadAction<{ body?: FileStatus[]; params?: any } | null>) => {},
    uploadFilePayment: (state, action) => {},
    downloadFile: (state, action) => {},
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<FileStatus>) => {
      let temp = ConstantStatic.FileDatas.map(x => x) || [];
      let currentFile = temp.find(x => x.fileId === action.payload.fileId);
      
      if (currentFile) {
        currentFile.percent = action.payload.percent;
        currentFile.status = action.payload.status;
        currentFile.error = action.payload.error;
      }
      ConstantStatic.FileDatas = temp;
      let tempFiles = temp.map(x => ({
        ...x,
      }));
      state.listFileUpload = tempFiles;
    },
  },
});

export const documentActions = documentSlice.actions;
export const documentReducer = documentSlice.reducer;
