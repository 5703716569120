import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { defaultPagingParams } from '@/common/define';

const getState = (state: RootState) => state.review;

export function getReviews() {
  return createSelector([getState], state => state.reviews);
}
export function getSelectedReview() {
  return createSelector([getState], state => state.selectedReview);
}
