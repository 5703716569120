/* eslint-disable import/order */
import { useEffect, useMemo, useState } from 'react';

import { Badge, Modal, TabsProps } from 'antd';
import dayjs from 'dayjs';

import { eTypeVatTu, eTypeVatTuMayMoc } from '@/common/define';
import TabHeader from '@/components/Layout/TabHeader/TabHeader';
import { maKhoTongMM, maKhoTongVT } from '@/environment';
import {
  accountingInvoiceActions,
  getDanhSachDuyetMuaHang,
  getMayMoc,
  getProducts,
  getWareHouses,
} from '@/store/accountingInvoice';
import { getCurrentCompany, getgetUserIIS } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getSelectedProject } from '@/store/project';
import { RootState } from '@/store/types';
import Utils from '@/utils';
import MachineryMaterialsList from './components/MachineryMaterialsList';
import NewMachineryMaterialList from './components/NewMachineryMaterialList';
import ProposalHistory from './components/ProposalHistory';
import AdditionalCost from './components/AdditionalCost';
import ProposalDone from './components/ProposalDone';

interface MachineryMaterialsProps {
  type: eTypeVatTuMayMoc;
}
export const MachineryMaterials = (props: MachineryMaterialsProps) => {
  const { type } = props;
  const company = useAppSelector(getCurrentCompany());
  const selectedProject = useAppSelector(getSelectedProject());
  const producsts = useAppSelector(getProducts());
  const machineries = useAppSelector(getMayMoc());
  const wareHouses = useAppSelector(getWareHouses());
  const trackers = useAppSelector(getTracker());
  const danhsachduyetmuahang = useAppSelector(getDanhSachDuyetMuaHang());
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  //lấy mã kho
  const currentWarehouseCode = selectedProject
    ? projectwareHouses && projectwareHouses.length > 0
      ? type === eTypeVatTuMayMoc.MayMoc
        ? projectwareHouses.find(wh => wh.warehouseCode.includes('CCDC'))?.warehouseCode
        : projectwareHouses.find(wh => !wh.warehouseCode.includes('CCDC'))?.warehouseCode
      : undefined // Không gán giá trị nếu projectwareHouses rỗng
    : type === eTypeVatTuMayMoc.MayMoc
    ? maKhoTongMM
    : maKhoTongVT;
  const userIIS = useAppSelector(getgetUserIIS());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasPendingProposals, setHasPendingProposals] = useState({
    [eTypeVatTuMayMoc.VatTuChinh]: 0,
    [eTypeVatTuMayMoc.VatTuPhu]: 0,
    [eTypeVatTuMayMoc.MayMoc]: 0,
  });
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    console.log(selectedProject, 'selectedProject');
    // dispatch(accountingInvoiceActions.GetDanhSachDuyetMuaHang({ params: {
    //   madvcs: 'THUCHIEN',
    //   ngay_de_nghi: '2024/09/16'
    // } }));
    dispatch(accountingInvoiceActions.GetProducts({ params: {} }));
    dispatch(accountingInvoiceActions.GetDanhSachThietBi({ params: {} }));
    dispatch(accountingInvoiceActions.GetWareHouse({ params: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // Function to handle dispatching GetTonKho based on selectedProject
  //   const dispatchTonKho = () => {
  //     if (selectedProject && Array.isArray(selectedProject.maKho)) {
  //       // Dispatch with danhSachMakho from selectedProject
  //       dispatch(
  //         accountingInvoiceActions.GetTonKho({
  //           data: {
  //             madvcs: 'THUCHIEN',
  //             danhSachMaHang: [],
  //             ngay_kiem_tra: dayjs().format(FormatDateAPI),
  //             danhSachMakho: [...selectedProject.maKho], // Ensure maKho is an array
  //           },
  //           params: {},
  //         })
  //       );
  //     } else {
  //       // Fallback to default danhSachMakho
  //       if (selectedProject) return;
  //       dispatch(
  //         accountingInvoiceActions.GetTonKho({
  //           data: {
  //             madvcs: 'THUCHIEN',
  //             danhSachMaHang: [],
  //             ngay_kiem_tra: dayjs().format(FormatDateAPI),
  //             danhSachMakho: ['TONGTEST', 'TONGTEST CCDC'], // Default values
  //           },
  //           params: {},
  //         })
  //       );
  //     }
  //   };

  //   // Only dispatch if selectedProject or company changes
  //   dispatchTonKho();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedProject, company]);

  useEffect(() => {
    if (company) {
      // dispatch(issueActions.getTagByCompanyIdRequest({ companyId: company.id }));
      // dispatch(issueActions.getCategoryByCompanyIdRequest({ companyId : company.id }));
      dispatch(issueActions.getTrackerByCompany({ id: company.id }));
    }
  }, [company, dispatch]);
  useEffect(() => {
    // console.log('producsts ', producsts);
    // console.log('machineries ', machineries);
  }, [producsts, machineries]);
  useEffect(() => {
    // console.log('wareHouses ', wareHouses);
  }, [wareHouses]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DanhSachVatTu = useMemo(() => [...producsts], [producsts]);
  const DanhSachMayMoc = useMemo(() => [...machineries], [machineries]);
  //[20434] [nam_do] hiển thị thông báo và số lượng phiếu đề xuất mới chưa được duyệt
  const calculateCapDuyet = (proposal: any): number => {
    return proposal.nguoiDuyet1 === ''
      ? 0
      : proposal.nguoiDuyet2 === ''
      ? 1
      : proposal.nguoiDuyet3 === ''
      ? 2
      : proposal.nguoiDuyet4 === ''
      ? 3
      : proposal.nguoiDuyet5 === ''
      ? 4
      : 5;
  };
  useEffect(() => {
    console.log('currentWarehouseCode ', currentWarehouseCode);
    dispatch(
      accountingInvoiceActions.GetDanhSachDuyetMuaHang({
        params: {
          madvcs: 'THUCHIEN',
          ngay_de_nghi_tu_ngay: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          ngay_de_nghi_den_ngay: dayjs().format('YYYY-MM-DD'),
          ma_kho: currentWarehouseCode,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWarehouseCode]);
  useEffect(() => {
    if (Array.isArray(danhsachduyetmuahang) && danhsachduyetmuahang.length > 0) {
      const pendingProposals = {
        [eTypeVatTuMayMoc.VatTuChinh]: 0,
        [eTypeVatTuMayMoc.VatTuPhu]: 0,
        [eTypeVatTuMayMoc.MayMoc]: 0,
      };

      danhsachduyetmuahang.forEach(proposal => {
        const capDuyetHienTai = calculateCapDuyet(proposal);
        if (capDuyetHienTai < proposal.capDuyet) {
          const maVt = proposal.chiTietDeNghiMuaHang[0]?.ma_vt;
          const product = DanhSachVatTu.find(vt => vt.ma_vt === maVt);
          const maymoc = DanhSachMayMoc.find(mm => mm.ma_Tscd === maVt);

          if (product) {
            if (product.productType === eTypeVatTu.VatTuChinh) {
              pendingProposals[eTypeVatTuMayMoc.VatTuChinh]++;
            } else if (product.productType === eTypeVatTu.VatTuPhu) {
              pendingProposals[eTypeVatTuMayMoc.VatTuPhu]++;
            }
          } else if (maymoc) {
            pendingProposals[eTypeVatTuMayMoc.MayMoc]++;
          }
        }
      });

      setHasPendingProposals(pendingProposals);
    } else {
      setHasPendingProposals({
        [eTypeVatTuMayMoc.VatTuChinh]: 0,
        [eTypeVatTuMayMoc.VatTuPhu]: 0,
        [eTypeVatTuMayMoc.MayMoc]: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [danhsachduyetmuahang, DanhSachVatTu, DanhSachMayMoc]);

  let tabs: TabsProps['items'] = [];

  switch (type) {
    case eTypeVatTuMayMoc.VatTuChinh:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư chính',
          children: <MachineryMaterialsList type={type} searchText={searchText} />,
        },
        {
          key: '2',
          label: (
            <Badge count={hasPendingProposals[eTypeVatTuMayMoc.VatTuChinh]} offset={[20, 0]}>
              Đề xuất cấp vật tư chính
            </Badge>
          ),
          children: <ProposalHistory type={type} />,
        },
        {
          key: '3',
          label: (
            <span style={{paddingLeft: 15, paddingRight: 15}}>Đề xuất sẵn sàng nhập kho</span>
          ),
          children: <ProposalDone type={type} />,
        }
      ];
      break;
    case eTypeVatTuMayMoc.VatTuPhu:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư phụ',
          children: <MachineryMaterialsList type={type} searchText={searchText} />,
        },
        {
          key: '2',
          label: (
            <Badge count={hasPendingProposals[eTypeVatTuMayMoc.VatTuPhu]} offset={[15, 0]}>
              Đề xuất cấp vật tư phụ
            </Badge>
          ),
          children: <ProposalHistory type={type} />,
        },
        {
          key: '3',
          label: (
            <span  style={{paddingLeft: 15, paddingRight: 15}}>Đề xuất sẵn sàng nhập kho</span>
          ),
          children: <ProposalDone type={type} />,
        }
      ];
      if (selectedProject) {
        tabs.push({
          key: '4',
          label: <span style={{ paddingLeft: 15, paddingRight: 15 }}>Chi phí phát sinh</span>,
          children: <AdditionalCost type={type} />,
        });
      }
      break;
      break;
    case eTypeVatTuMayMoc.MayMoc:
      tabs = [
        {
          key: '1',
          label: 'Danh sách máy móc',
          children: <MachineryMaterialsList type={type} searchText={searchText} />,
        },
        {
          key: '2',
          label: (
            <Badge count={hasPendingProposals[eTypeVatTuMayMoc.MayMoc]} offset={[15, 0]}>
              Đề xuất cấp máy móc
            </Badge>
          ),
          children: <ProposalHistory type={type} />,
        },
        {
          key: '3',
          label: (
            <span style={{paddingLeft: 15, paddingRight: 15}}>Đề xuất sẵn sàng nhập kho</span>
          ),
          children: <ProposalDone type={type} />,
        }
      ];
      break;
  }
  //[20433] [ngoc_td] lấy ncc list
  const handleAddProposal = () => {
    dispatch(accountingInvoiceActions.setProposalFormSelected(undefined));
    dispatch(accountingInvoiceActions.getCustomers());
    // [21/10/2024][phuong_td] Thay đổi Session để dialog Tạo phiếu đề xuất gọi lại api
    dispatch(issueActions.setSession(Utils.generateRandomString(3)));
    setIsModalVisible(true);
  };

  const handleDownload = () => {};

  const handleSelectDate = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      const [startDate, endDate] = dates;
      if (startDate && endDate) {
        dispatch(
          accountingInvoiceActions.setDateRange({
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
          }),
        );
      }
    } else {
      console.log('Khoảng thời gian chưa được chọn đầy đủ.');
    }
  };
  const handleModalClose = () => {
    const random = Utils.generateRandomString(3);
    dispatch(accountingInvoiceActions.setClearData(random));
    setIsModalVisible(false);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };
  return (
    <div>
      <TabHeader
        tabs={tabs}
        onAddProposal={handleAddProposal}
        onDownload={handleDownload}
        onSelectDate={handleSelectDate}
        onSearch={handleSearch}
      />
      <Modal open={isModalVisible} onCancel={handleModalClose} footer={null} width={1250}>
        <NewMachineryMaterialList
          type={type}
          handleClose={() => {
            setIsModalVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};
