import React, { useEffect, useState } from 'react';

import { Button, Flex, Splitter, Switch, Typography } from 'antd';

import styles from './Review.module.css';
import ReviewDetail from './ReviewDetail/ReviewDetail';
import ReviewList from './ReviewList/ReviewList';
import { eTypeReview, IReviewComment, IReviewItem } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { reviewActions } from '@/store/review';

export const Review = (props: {categoryCode: eTypeReview}) => {
  const { categoryCode } = props;
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const [selectedMessage, setSelectedMessage] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const company = useAppSelector(getCurrentCompany());
  const [sizes, setSizes] = React.useState<(number | string)[]>(['30%', '70%']);
  const [startDate, setStartDate] = React.useState<string>("2000-01-01");
  const [endDate, setEndDate] = React.useState<string>("9000-01-01");
  const reviewMessage = useAppSelector(state => state.review.reviewMessage);

  useEffect(() => {
    if (company) {
      dispatch(reviewActions.getReviewMessage({
        companyId: company.id, 
        categoryCode,  
        startDate, 
        endDate, 
      }));
    }
  }, [company, categoryCode, startDate, endDate]);
  
  const updateDefaultMessage = (results: IReviewItem[]) => {
    if (results?.length > 0) {
      if (!selectedMessage) { 
        setSelectedMessage(results[0]);
      } 
      else {
        let index = results.findIndex(x => x.id === selectedMessage.id)
        if (index < 0) setSelectedMessage(results[0]);
      }
    } else setSelectedMessage(null);    
  }
  useEffect(() => {
    if (reviewMessage) {
      updateDefaultMessage(reviewMessage.results);
      if (selectedMessage) {
        let mess = [...reviewMessage.results];
        mess = mess.map((it: IReviewItem) => {
          if(selectedMessage.id === it.id) {
            const itUpdate = {...it, selected: true};
            setSelectedMessage(itUpdate);
            return itUpdate;
          }
          return {...it, selected: false};
        })
        setMessages(mess);
      }
    }
    else setMessages([]);
  }, [reviewMessage]);

  const handleItemClick = (message: IReviewItem) => {
    let mess = [...messages];
    message = {...message, selected: true};
    mess = mess.map((it: IReviewItem) => {
      if(message.id === it.id) return {...it, selected: true};
      return {...it, selected: false};
    })
    setMessages(mess);
    dispatch(reviewActions.getReviewsById({id: message.id}));
    setSelectedMessage(message);
  }

  return (
    <Flex vertical className={styles.layout}>
      <Typography.Title style={{ marginLeft: 20, marginTop: 10 }} level={4}>
      {activeMenu?.label}
      </Typography.Title>
      <Splitter
        onResize={setSizes}
        style={{ flexGrow: 1, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}
      >
        <Splitter.Panel size={sizes[0]} resizable={true}>
          <ReviewList messages={messages} onSelectMessage={handleItemClick} categoryCode={categoryCode}/>
        </Splitter.Panel>
        <Splitter.Panel size={sizes[1]}>
          <ReviewDetail message={selectedMessage}/>
        </Splitter.Panel>
      </Splitter>
    </Flex>
  );
};
