import { useEffect, useState } from 'react';

import { UserOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Flex, Modal } from 'antd';
import dayjs from 'dayjs';

import styles from '../../Review.module.css';
import NewReview from '../NewReview';
import { IReviewItem } from '@/common/define';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { reviewActions } from '@/store/review';
import Utils from '@/utils';

const ReviewListItem = (props: {message: IReviewItem, handleItemClick?: any, categoryCode: string}) => {
  const { message, handleItemClick, categoryCode } = props;
  const [showModal, setShowModal] = useState<boolean>();
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false); // New state for delete confirmation modal
  const company = useAppSelector(getCurrentCompany());
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(reviewActions.getReviewMessage({
      companyId: Number(company.id),
      categoryCode: categoryCode,
      startDate: "2000-01-01",
      // endDate: dayjs().format('YYYY-MM-DD'),
      endDate: "3000-01-01",
    }))
  };

  const handleDelete = () => {
    dispatch(reviewActions.deleteReviewRequest({ id: message.id }));
    setIsDeleteConfirmVisible(false); // Hide the delete confirmation modal after deletion
  };

  useEffect(() => {
  }, [])

  return (
    // border: "1px solid #14aeea"
    <Flex className={`${styles.rowMessage} ${message.selected ? styles.rowMessageSelected : ""}`}
      onClick={() => handleItemClick && handleItemClick(message)}
    >
      <div className={`${styles.fontMedium} ${styles.onerow}`}>
        {message.subject}
      </div>
      <div className={`${styles.fontSmall} ${styles.onerow}`}>
        {`${message.content}`}
      </div>
      <div className={`${styles.fontSmall} ${styles.flexRow}`}>
        <ClockCircleOutlined style={{marginRight: 5}}/>
        <div style={{paddingRight: 20}}>{Utils.formatDateTimeStamp(new Date(message.createdDate))}</div>
        <UserOutlined style={{marginRight: 5}}/>
        <div style={{paddingRight: 20}}>{message.senderName}</div>
        <span className={styles.buttonGroup}>
          <EditOutlined 
            className={`${styles.buttons} ${styles.buttonEdit}`}
            onClick={() => setShowModal(true)}/>
          <DeleteOutlined 
            className={`${styles.buttons} ${styles.buttonDelete}`}
            onClick={() => {setIsDeleteConfirmVisible(true)}}
          />
        </span>
      </div>
      <Modal open={showModal} title="Chỉnh sửa chủ đề" onCancel={handleCloseModal} footer={null}>
        <NewReview messages={message} categoryCode={categoryCode} onClose={handleCloseModal} />
      </Modal>
      <Modal
        open={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)} // Close modal if user cancels
        onOk={handleDelete} // Proceed with delete if user confirms
        okText="Xác nhận"
        cancelText="Hủy"
        title="Xác nhận xóa"
      >
        <p>Bạn có chắc chắn muốn xóa đề nghị này không?</p>
      </Modal>
    </Flex>
  )
}
export default ReviewListItem;
