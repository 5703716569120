/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { PersonnelTransferHeader } from './PersonnelTransferHeader';
import { eEmployeeType, FormatDateAPI } from '@/common/define';
import { eColumnsTpye, iColumnsConfig, TableCustom } from '@/components/TableCustom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getQueryReportsByStartEndDate, getReportsByStartEndDate, issueActions } from '@/store/issue';
import { getEmployeeProjects, getProjectList, getSelectedProject, projectActions } from '@/store/project';
import Utils from '@/utils';



interface iPersonnelTransfer {
  Key: number;
  MaNV: string;
  TenNV: string;
  CongTrinhDC: string;
  StartDate: string;
  Date: string;
  EndDate: string;
  CongTrinhNhanDC: string;
}
export const PersonnelTransfer: React.FC = () => {
  const [Data, setData] = useState<iPersonnelTransfer[]>([]);
  const tTable = useTranslation('table').t;
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const reportsByStartEndDate = useAppSelector(getReportsByStartEndDate());
  const projectList = useAppSelector(getProjectList());
  const employeeProjects = useAppSelector(getEmployeeProjects());
  const queryReportsByStartEndDate = useAppSelector(getQueryReportsByStartEndDate());
  // [#20692][phuong_td][31/10/2024] Dữ liệu thay đổi
  const DataModifine: { [key: string]: iPersonnelTransfer } = {};
  // [#20692][phuong_td][31/10/2024] Cấu hình của table
  const columnsConfig: { [key: string]: iColumnsConfig } = {
    Key: {
      hidden: true,
      isKey: true,
      width: 20,
      type: eColumnsTpye.text,
    },
    MaNV: {
      title: tTable('MaNV'),
      width: 30,
      type: eColumnsTpye.text,
    },
    TenNV: {
      title: tTable('TenNV'),
      width: 30,
      type: eColumnsTpye.textStart,
      sorter: true,
    },
    CongTrinhDC: {
      title: tTable('CongTrinhDC'),
      width: 30,
      type: eColumnsTpye.text,
    },
    Date: {
      title: tTable('Date'),
      width: 30,
      type: eColumnsTpye.date,
      sorter: true,
    },
    // StartDate: {
    //   title: tTable('StartDate'),
    //   width: 50,
    //   type: eColumnsTpye.date,
    // },
    // EndDate: {
    //   title: tTable('EndDate'),
    //   width: 50,
    //   type: eColumnsTpye.date,
    // },
    CongTrinhNhanDC: {
      title: tTable('CongTrinhNhanDC'),
      width: 30,
      type: eColumnsTpye.text,
    },
  };

  // [09/11/2024][#20629][phuong_td] phương thức lấy tên dự án theo id
  const GetProjectName = (id?: number) => {
    let projectName = '';
    if (projectList && !Utils.checkNull(id)) {
      const project = projectList.find(p => p.id === id);
      projectName = project?.name ?? '';
    }
    return projectName;
  };

  // [09/11/2024][#20629][phuong_td] đọc dữ liệu report và gọi api lấy project của nhân công
  useEffect(() => {
    if (reportsByStartEndDate) {
      const ids: number[] = [];
      reportsByStartEndDate.forEach(r => {
        if (r.id && r.employeeId && r.employeeType === eEmployeeType.BoXung && !ids.includes(r.employeeId)) {
          ids.push(r.employeeId);
        }
      });
      const startDate = queryReportsByStartEndDate?.params.startDate ?? dayjs().startOf('week').format(FormatDateAPI);
      const endDate = queryReportsByStartEndDate?.params.endDate ?? dayjs().endOf('week').format(FormatDateAPI);
      dispatch(
        projectActions.getEmployeeProjectsRequest({
          ids,
          params: {
            startTime: startDate,
            endTime: endDate,
          },
        }),
      );
    }
  }, [reportsByStartEndDate]);

  useEffect(() => {
    const _data: iPersonnelTransfer[] = [];
    // [09/11/2024][#20629][phuong_td] tạo dữ liệu nhân viên điều chuyển
    if (reportsByStartEndDate) {
      reportsByStartEndDate.forEach(r => {
        if (r.id && r.employeeType === eEmployeeType.BoXung) {
          // [10/11/2024][#20629][phuong_td] lấy thông tin dự án làm việc của nhân công theo ngày
          const employeeProject = employeeProjects?.find(e => {
            const startTimeWork = dayjs(e.startTime);
            const endTimeWork = dayjs(e.endTime);
            const dateReport = dayjs(r.startTime);
            return e.employeeId === r.employeeId && Utils.isSameOrAfter(dateReport, startTimeWork, endTimeWork);
          });
          const temp: iPersonnelTransfer = {
            Key: r.id,
            MaNV: r.employeeCode,
            TenNV: `${r.employeeName}`,
            CongTrinhDC: employeeProject?.projectName ?? t('None'),
            Date: r.startTime,
            StartDate: r.startTime,
            EndDate: r.endTime,
            CongTrinhNhanDC: `${GetProjectName(r.projectId)}`,
          };
          _data.push(temp);
        }
      });
    }
    setData(_data);
  }, [employeeProjects, reportsByStartEndDate]);

  return (
    <>
      <PersonnelTransferHeader />
      <TableCustom dataSource={Data} columnsConfig={columnsConfig} />
    </>
  );
};

export default PersonnelTransfer;
