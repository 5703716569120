import { useEffect } from 'react';

import { issueActions } from '../../store/issue/issueSlice';
import OnlyOfficeViewer from '../Components/ViewFile/OnlyofficeViewer';
import { getEnvVars } from '@/environment';
import { ProjectDocumentsHeader } from '@/pages/Components/Document/ProjectDocumentHeader';
import { accountingInvoiceActions, getBaoCaoDanhThuChiPhi } from '@/store/accountingInvoice';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getFinanceFile } from '@/store/issue';

const { apiUrl } = getEnvVars();
export const AccoutingManagement = ({ AccoutingKey }: any) => {
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const getBaoCaoDanh = useAppSelector(getBaoCaoDanhThuChiPhi());
  const getFileFinance = useAppSelector(getFinanceFile());
  // [#20755] [hao_lt] call api báo cáo thu doanh thu chi phí
  useEffect(() => {
    dispatch(accountingInvoiceActions.getBaoCaoDanhThuChiPhi({ startDay: '1-1-2024', endDay: '1-12-2024' }));
  }, []);

  useEffect(() => {
    if (getBaoCaoDanh) {
      dispatch(issueActions.getFinance({ data: getBaoCaoDanh }));
    }
  }, [getBaoCaoDanh, AccoutingKey]);

  return (
    <div style={{ height: '90vh' }}>
      <ProjectDocumentsHeader title={activeMenu?.label} />
      <OnlyOfficeViewer
        fileUrl={`${apiUrl}/Document/downloadFile/${getFileFinance}?companyId=1`}
        fileType={'xlsx'}
        title={activeMenu?.label}
      />
    </div>
  );
};
