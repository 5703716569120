import { useEffect, useState } from 'react';
import React from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Table, Checkbox, Avatar, Modal, Flex, Splitter, Image, Button, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { ColumnType } from 'antd/es/table';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './AdditionalCost.module.css';
import ExpenseFormUpdate from '../AddAriseNew/ExpenseFormUpdate';
import { eTypeVatTuMayMoc } from '@/common/define';
import { eColumnsTpye, iColumnsConfig, TableCustom } from '@/components/TableCustom';
import { DataType, IAttachmentLinks } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import { RootState } from '@/store/types';
import Utils from '@/utils';

const initialDataSource: DataType[] = [
  {
    id: 0,
    key: '1',
    costCode: 'BT01',
    costName: 'Gọi xe cẩu ngoài',
    unit: 'Lượt',
    quantity: '1',
    totalAmount: '700.000',
    hinhanh: '',
    payer: 'Tú',
    notes: 'Abcsasd',
    checkbox: false,
    createDate: '2024-10-10',
    amount: '100000',
    attachmentLinks: [],
  },
  {
    id: 1,
    key: '2',
    costCode: 'Thai1',
    costName: 'Gọi xe chở thải',
    unit: 'Lượt',
    quantity: '',
    totalAmount: '700.000',
    hinhanh: '',
    payer: 'Tới',
    notes: 'ghi chú',
    checkbox: false,
    createDate: '2024-11-01',
    amount: '100000',
    attachmentLinks: [],
  },
];
type AdditionalCostProps = {
  type: eTypeVatTuMayMoc;
};
const AdditionalCost = (props: AdditionalCostProps) => {
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const company = useAppSelector(getCurrentCompany());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const additionalCosts = useAppSelector((state: RootState) => state.accountingInvoice.AdditionalCosts) || [];
  const dataAttachmentLinks = useAppSelector((state: RootState) => state.accountingInvoice.dataAttachmentLinks) || []; // data imageUrl
  const [dataSource, setDataSource] = useState<DataType[]>(initialDataSource);
  const [selectedRecord, setSelectedRecord] = useState<DataType | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation('material');
  const [modalAttachmentLinks, setModalAttachmentLinks] = useState(false);
  const [modalAddAttachmentLink, setModalAddAttachmentLink] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [sizes, setSizes] = React.useState<(number | string)[]>(['40%', '60%']);
  const [selectAttach, setSelectAttach] = React.useState<IAttachmentLinks>();
  const [selectAdditionalCost, setSelectAdditionalCost] = React.useState<DataType>();
  const [deleteAttachmentLinks, setDeleteAttachmentLinks] = React.useState<{
    id: number;
    deletList: IAttachmentLinks[];
  }>({ id: 0, deletList: [] });
  const [fileList, setFileList] = useState([]);
  const tProjects = useTranslation(['projects']).t;

  let DataModifine: { [key: string]: DataType } = {};
  const columnsConfig: { [key: string]: iColumnsConfig } = {
    // checkbox: {
    //   width: 20,
    //   type: eColumnsTpye.checkbox,
    // },
    costCode: {
      hidden: true,
      title: t('Cost code'),
      width: 20,
      type: eColumnsTpye.text,
    },
    costName: {
      title: t('Cost name'),
      width: 200,
      type: eColumnsTpye.textStart,
    },
    unit: {
      title: t('Unit'),
      width: 60,
      type: eColumnsTpye.text,
    },
    createDate: {
      title: t('Day'),
      width: 100,
      type: eColumnsTpye.date,
    },
    amount: {
      title: t('amount of money'),
      width: 50,
      type: eColumnsTpye.number,
    },
    soluong: {
      title: t('Quantity'),
      width: 50,
      type: eColumnsTpye.number,
    },
    totalAmount: {
      title: t('Into money'),
      width: 100,
      type: eColumnsTpye.number,
    },
    hinhanh: {
      title: t('Image'),
      width: 50,
      type: eColumnsTpye.image,
      previewImage: true,
      onClick: (record: any) => {
        console.log(record);
      },
    },
    payer: {
      title: t('People who spend money'),
      width: 50,
      type: eColumnsTpye.text,
    },
    notes: {
      title: t('Note'),
      width: 50,
      type: eColumnsTpye.text,
    },
    action: {
      width: 50,
      type: eColumnsTpye.action,
      actions: [
        {
          name: 'edit',
          action: (record: any) => {
            console.log('edit ', record);
            setSelectedRecord(record);
            setIsModalVisible(true);
          },
        },
        {
          // [09/11/2024][#20629][phuong_td] đưa việc gọi api xóa dữ liệu từ table custom ra vị trí này
          name: 'remove',
          Notification: tProjects('Bạn có muốn xóa chi phí phát sinh này không?'),
          action: (record: any) => {
            console.log('remove ', record);
            const drawingIds = Array.isArray(record.attachmentLinks)
              ? record.attachmentLinks.map((link: any) => link.drawingId)
              : [];
            dispatch(
              accountingInvoiceActions.deleteFileCPPSRequest({
                itemId: record.id,
                drawingIds: drawingIds,
              }),
            );
            dispatch(accountingInvoiceActions.DeleteAdditionalCostRequest({ id: record.id }));
          },
        },
      ],
    },
  };

  useEffect(() => {
    // Gọi action để lấy dữ liệu chi phí phát sinh từ API
    if (selectedProject?.id && company.id) {
      dispatch(accountingInvoiceActions.getAdditionalCosts({ projectId: selectedProject?.id, companyId: company.id }));
    }
  }, [selectedProject, company]);

  useEffect(() => {
    setDataSource(additionalCosts);
    if (selectAdditionalCost) {
      additionalCosts.forEach(x => {
        if (selectAdditionalCost.id === x.id) {
          setSelectAdditionalCost(x);
        }
      });
    }
  }, [additionalCosts]);

  const updateSelectAttach = (it: IAttachmentLinks) => {
    if (dataAttachmentLinks) {
      dataAttachmentLinks.forEach(x => {
        if (it && it.drawingId === x.drawingId) {
          setSelectAttach({ ...it, imageUrl: x.imageUrl });
        }
      });
    }
  };

  const handleAttachClick = (attach: IAttachmentLinks) => {
    if (selectAdditionalCost) {
      const attachs = { ...selectAdditionalCost };
      attachs?.attachmentLinks?.forEach((it: IAttachmentLinks) => {
        if (attach.drawingId === it.drawingId) {
          it = { ...it, selected: true };
          updateSelectAttach(it);
        } else {
          it = { ...it, selected: false };
        }
      });

      setSelectAdditionalCost(attachs);
    }
  };

  const handleCheckboxChange = (id: number, checked: boolean) => {
    if (selectAdditionalCost && selectAdditionalCost?.attachmentLinks) {
      const additionalCost = { ...selectAdditionalCost };
      additionalCost.attachmentLinks = additionalCost.attachmentLinks.map(x => {
        if (x.id === id) {
          x = { ...x, selected: checked };
        }
        return x;
      });
      setSelectAdditionalCost(additionalCost);
    }
  };

  const handleUploadChange = (info: any) => {
    const { fileList } = info;
    setFileList(fileList);
  };

  useEffect(() => {
    selectAttach && updateSelectAttach(selectAttach);
  }, [dataAttachmentLinks]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  return (
    <div className="AdditionalCost">
      <header className="AdditionalCost-header">
        <TableCustom
          dataSource={dataSource}
          columnsConfig={columnsConfig}
          onChange={(value, type) => {
            // [#20692][phuong_td][31/10/2024] Đặt dữ liệu thay đổi
            DataModifine = Utils.setDataModified(DataModifine, value.costCode, value, type);
          }}
          onImagePopup={(value: DataType) => {
            setModalAttachmentLinks(true);
            setSelectAdditionalCost(value);
            if (value?.attachmentLinks) {
              value.attachmentLinks.forEach((x: any) => {
                //ham xu ly download se tu bo qua neu đã có
                dispatch(
                  accountingInvoiceActions.getImageUrlAttachmentLinks({
                    drawingId: x.drawingId,
                    fileName: x.fileName,
                    companyId: company.id,
                    itemId: x.itemId,
                  }),
                );
              });
              setSelectAttach(value.attachmentLinks[0]);
            }
          }}
        />
        <Modal visible={isModalVisible} onCancel={handleCancel} footer={null} width={500}>
          {selectedRecord && <ExpenseFormUpdate record={selectedRecord} />}
        </Modal>
      </header>
      <Modal
        title={`Hình ảnh đính kèm (${selectAdditionalCost?.attachmentLinks.length})`}
        centered
        open={modalAttachmentLinks}
        onOk={() => setModalAttachmentLinks(false)}
        onCancel={() => setModalAttachmentLinks(false)}
        width={750}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
            <Button
              type="primary"
              key="Add"
              onClick={() => {
                setFileList([]);
                setModalAddAttachmentLink(true);
              }}
            >
              Add Image
            </Button>
            <Button
              style={{ backgroundColor: 'red', color: 'white' }}
              key="Delete"
              onClick={() => {
                if (selectAdditionalCost && selectAdditionalCost.attachmentLinks?.length > 0) {
                  const deletList = selectAdditionalCost.attachmentLinks.filter(x => x.selected);
                  if (deletList && deletList.length > 0) {
                    setModalConfirm(true);
                    setDeleteAttachmentLinks({ id: selectAdditionalCost.id, deletList });
                  } else {
                    setDeleteAttachmentLinks({ id: 0, deletList: [] });
                  }
                }
              }}
            >
              Delete Image
            </Button>
          </div>,
        ]}
      >
        <Splitter onResize={setSizes} className={styles.model}>
          <Splitter.Panel size={sizes[0]} resizable={true}>
            <div style={{ overflowY: 'auto', alignItems: 'center' }}>
              {selectAdditionalCost?.attachmentLinks?.map((attach: IAttachmentLinks) => (
                <div
                  className={`${styles.rowItem}`}
                  style={{
                    background: attach.id === selectAttach?.id ? '#1890ff' : 'white',
                    color: attach.id === selectAttach?.id ? 'white' : 'black',
                  }}
                >
                  {/* , border: "1px solid #ff0000" */}
                  <Flex
                    vertical={false}
                    style={{ gap: 5, cursor: 'pointer', width: '100%' }}
                    onClick={() => handleAttachClick(attach)}
                  >
                    <Checkbox
                      checked={attach.selected}
                      onChange={e => handleCheckboxChange(attach.id, e.target.checked)}
                    ></Checkbox>
                    <div className={`${styles.fontMedium} ${styles.truncateText}`}>
                      {Utils.getFileNmeWithoutExtension(attach.fileName)}
                    </div>
                  </Flex>
                </div>
              ))}
            </div>
          </Splitter.Panel>
          <Splitter.Panel size={sizes[1]}>
            <Image width="100%" height="100%" src={selectAttach?.imageUrl} style={{ objectFit: 'contain' }} />
          </Splitter.Panel>
        </Splitter>
      </Modal>
      {/* Confirm Add Dialog */}
      <Modal
        title="Add AttachmentLink"
        open={modalAddAttachmentLink}
        onOk={() => {
          if (selectAdditionalCost) {
            setModalAddAttachmentLink(false);
            if (!fileList || fileList.length < 1) {
              alert('Chưa có hình ảnh nào để upload.');
              return;
            }
            const formData = new FormData();
            fileList.forEach((file: any) => {
              if (file.originFileObj) {
                formData.append('files', file.originFileObj);
              }
            });

            dispatch(
              accountingInvoiceActions.uploadAttachmentLinks({
                itemId: selectAdditionalCost.id,
                id: selectAdditionalCost.id,
                companyId: company.id,
                dataImage: formData,
              }),
            );
          }
        }}
        onCancel={() => {
          setModalAddAttachmentLink(false);
        }}
      >
        <Upload.Dragger
          name="files"
          listType="picture"
          accept=".jpg,.jpeg,.png,.jfif"
          beforeUpload={() => false}
          fileList={fileList}
          onChange={handleUploadChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Chọn từ thư mục hoặc kéo thả tệp định dạng (.jpg,.jpeg,.png,.jfif)</p>
        </Upload.Dragger>
      </Modal>
      {/* Confirm Delete Dialog */}
      <Modal
        title=""
        open={modalConfirm}
        onOk={() => {
          setModalConfirm(false);
          if (deleteAttachmentLinks && deleteAttachmentLinks.deletList.length > 0) {
            const itemId = deleteAttachmentLinks.deletList[0].itemId;
            const drawingIds = deleteAttachmentLinks.deletList.map(x => x.drawingId);
            dispatch(
              accountingInvoiceActions.deleteAttachmentLinks({ itemId, drawingIds, id: deleteAttachmentLinks.id }),
            );
          }
        }}
        onCancel={() => {
          setModalConfirm(false);
        }}
      >
        <p>{`Xác nhận xóa ${deleteAttachmentLinks.deletList.length} image`}</p>
      </Modal>
    </div>
  );
};

export default AdditionalCost;
