import dayjs from "dayjs";
import { filter, withLatestFrom, switchMap, concat, mergeMap, catchError } from "rxjs";

import { reviewActions } from "./reviewSlice";
import { startLoading, stopLoading } from "../loading";
import { shiftActions } from "../shift";
import { RootEpic } from "../types";
import { defaultPagingParams, eReviewTypeUpdate, IReviewMessages } from "@/common/define";
import { IAttachmentLinks } from "@/services/AccountingInvoiceService";
import { cxmService } from "@/services/CxmService";
import { ReviewService } from "@/services/ReviewService";
import Utils from "@/utils";



const getReviewsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.getReviewsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      console.log('called');
      const { companyId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'getReviewsRequest' })],
        ReviewService.Get.getMessage(companyId ).pipe(
          mergeMap(reviews => {
            return [reviewActions.setReviews(reviews)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [reviewActions.setReviews(undefined)];
          }),
        ),
        [stopLoading({ key: 'getReviewsRequest' })],
      );
    }),
  );
};
const getReviewsById$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.getReviewsById.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      console.log('called');
      const { id } = action.payload;
      return concat(
        [startLoading({ key: 'getReviewsRequest' })],
        ReviewService.Get.getMessagebyId(id).pipe(
          mergeMap(review => {
            return [reviewActions.setSelectedReview(review)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [reviewActions.setReviews(undefined)];
          }),
        ),
        [stopLoading({ key: 'getReviewsRequest' })],
      );
    }),
  );
};
const editReviewsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.updateReviewRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      // eslint-disable-next-line
      const { id, dataImage, review } = action.payload;
      console.log(review, 'review', id, 'id');
      return concat(
        [startLoading({ key: 'editReviewsRequest' })],
        ReviewService.Put.editMessage(review, id).pipe(
          mergeMap(reviews => {
            return [reviewActions.uploadAttachmentLinks({ dataImage, itemId: reviews.id }) ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            console.log("errors", errors); 
            return [];
          }),          ),
        [stopLoading({ key: 'editReviewsRequest' })],
      );
    }),
  );
};
const deleteReviewsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.deleteReviewRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      // eslint-disable-next-line
      const { id} = action.payload;
      console.log(id, 'id');
      return concat(
        [startLoading({ key: 'editReviewsRequest' })],
        ReviewService.Delete.deleteMessage(id).pipe(
          mergeMap(reviews => {
            return [];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            console.log("errors", errors); 
            return [];
          }),          ),
        [stopLoading({ key: 'editReviewsRequest' })],
      );
    }),
  );
};
const newReviewsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.createReviewRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { inputValues, dataImage } = action.payload;
      console.log('test run new request', inputValues);
      return concat(
        [startLoading({ key: 'newReviewsRequest' })],
        ReviewService.Post.addNewMessage(inputValues).pipe(
          mergeMap(reviews => {
            console.log("review", reviews);
            
            return [reviewActions.setCreateReviews(reviews),
              reviewActions.uploadAttachmentLinks({ dataImage, itemId: reviews.id })
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),          ),
        [stopLoading({ key: 'newReviewsRequest' })],
      );
    }),
  );
};

const uploadAttachmentLinks$: RootEpic = action$ => {
  return action$.pipe(
    filter(reviewActions.uploadAttachmentLinks.match),
    switchMap(action => {
      const { itemId, dataImage } = action.payload; //actionpayload truyền vào bao gồm itemid và dữ liệu ảnh

      return concat(
        [startLoading({ key: 'createFileCPPS' })],
        ReviewService.Post.createFileCPPS(itemId, dataImage).pipe(
          switchMap(response => {
            // console.log('Tải file thành công', response);
            return [];
          }),
          catchError(error => {
            console.error('Lỗi tải file', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createFileCPPS' })],
      );
    }),
  );
};

const getReviewMessage$: RootEpic = action$ => {
  return action$.pipe(
    filter(reviewActions.getReviewMessage.match),
    switchMap(action => {
      const { companyId, categoryCode, startDate, endDate } = action.payload;
      return concat(
        [startLoading({ key: 'getReviewMessage' })],
        cxmService.Message.Get.getByCategoryCode(companyId, categoryCode, startDate, endDate).pipe(
          switchMap((results) => {
            let dispatchComment: any = [];
            let tempResult: IReviewMessages = {
              page: 0,
              pageCount: 0,
              pageSize: 0,
              queryCount: 0,
              firstRowIndex: 0,
              lastRowIndex: 0,
              results: []
            };
            if (results) {
              tempResult = {...results};
              if (tempResult.results?.length > 0) {
                tempResult.results = tempResult.results.map(x => {
                  dispatchComment.push(reviewActions.getCommentsByMessage({messageId: x.id, type: 'COMMENT'}));
                  dispatchComment.push(reviewActions.getCountLikes({messageId: x.id, type: 'LIKE'}));
                  return {
                    ...x,
                    selected: false,
                    countLike: 0
                  };
              });
              }
            }
            return [reviewActions.setReviewMessage(tempResult), ...dispatchComment];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [stopLoading({ key: 'getReviewMessage' })];
          }),
        ),
        [stopLoading({ key: 'getReviewMessage' })],
      );
    }),
  );
};

const getCommentsByMessage$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.getCommentsByMessage.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { messageId } = action.payload;
      return concat(
        [startLoading({ key: 'getCommentsByMessage' })],
        cxmService.Message.Get.getCommentsByMessage(messageId).pipe(
          switchMap((results) => {
            return [reviewActions.updateReviewMessage({messageId, comments: results, type: eReviewTypeUpdate.SET_COMMENTS})];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
      [stopLoading({ key: 'getCommentsByMessage' })]
      );
    }),
  );
};

const getCountLikes$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.getCountLikes.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { messageId } = action.payload;
      return concat(
        [startLoading({ key: 'getCountLikes' })],
        cxmService.Message.Get.getCountLikes(messageId).pipe(
          switchMap((results) => { 
            return [reviewActions.updateReviewMessage({messageId, countLike: Utils.getNumber(results), type: eReviewTypeUpdate.SET_LIKES})];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
      [stopLoading({ key: 'getCountLikes' })]
      );
    }),
  );
};

const postLike$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.postLike.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { messageId } = action.payload;
      return concat(
        [startLoading({ key: 'postLike' })],
        cxmService.Message.Post.Like(messageId, action.payload).pipe(
          switchMap((results) => { 
            return [reviewActions.getCountLikes({messageId, type: 'LIKE'})];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
      [stopLoading({ key: 'postLike' })]
      );
    }),
  );
};

const postReviewMessage$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.postReviewMessage.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { messageId } = action.payload;
      // content: inputValue,
      // createdDate: new Date().toLocaleDateString(),
      // messageId: message.id,
      // parentId: null,
      return concat(
        [startLoading({ key: 'postReviewMessage' })],
        cxmService.Message.Post.PostComment(action.payload).pipe(
          switchMap((results) => {         
            // {
            //   "id": 10,
            //   "senderId": "11b56e7a-171a-4253-b1cf-aef656f32f52",
            //   "senderName": "Công",
            //   "content": "test comment",
            //   "createdDate": "2024-11-11T08:31:45.215Z",
            //   "messageId": 1,
            //   "parentId": null
            // }
            return [reviewActions.updateReviewMessage({messageId, comment: results, type: eReviewTypeUpdate.ADD_COMMENT})];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
      [stopLoading({ key: 'postReviewMessage' })]
      );
    }),
  );
};
const deleteFilesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(reviewActions.deleteFilesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      // eslint-disable-next-line
      const { id, drawingIds } = action.payload;
      return concat(
        [startLoading({ key: 'editFilesRequest' })],
        ReviewService.Delete.deleteAttachmentLink(  id, drawingIds).pipe(
          mergeMap(reviews => { 
            Utils.successNotification('Xóa ảnh thành công');
            return [];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            console.log("errors", errors); 
            return [];
          }),          ),
        [stopLoading({ key: 'editFilesRequest' })],
      );
    }),
  );
};
export const reviewEpics = [
  getReviewMessage$,
  getReviewsRequest$,
  getCommentsByMessage$,
  getCountLikes$,
  postLike$,
  postReviewMessage$,
  editReviewsRequest$, 
  newReviewsRequest$, 
  uploadAttachmentLinks$,
  deleteReviewsRequest$,
  getReviewsById$,
  deleteFilesRequest$
];
