/* eslint-disable react-hooks/exhaustive-deps */
import { debug } from 'util';

import React, { useEffect, useState } from 'react';

import { EllipsisOutlined, CaretDownOutlined, CaretUpOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, Table, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '././CompanyGroup.module.less';
import { CompanyGroupHeader } from './CompanyGroupHeader';
import { colors } from '@/common/colors';
import { useWindowSize } from '@/hooks';
import { getCurrentCompany } from '@/store/app';
import { getGroups } from '@/store/group/groupSelector';
import { groupActions } from '@/store/group/groupSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
interface iCompanyGroup {
  id: string;
  name: string;
  type: eType;
  parentID: number;
  children: iCompanyGroup[];
}
export enum eType {
  PhongBan,
  Nhom,
  NhanSu,
}
const convertData = (inputData: any[]) => {
  const result: any[] = [];
  if (inputData) {
    inputData.forEach((item: any) => {
      // Xác định type và parentID cho từng phòng ban và nhóm
      const type = item.type === 0 ? eType.PhongBan : eType.Nhom;
      const parentId = item.parentId ? item.parentId : null;

      result.push({
        id: `${item.id}`,
        name: item.name,
        parentId: `${parentId}`,
        type: type,
      });

      // Xử lý các employees và thêm vào result
      item.employees.forEach((employee: any) => {
        console.log(employee, 'employee');
        result.push({
          id: `nv_${employee.id}`,
          name: `${employee.firstName} ${employee.middleName} ${employee.lastName}`.trim(),
          parentId: `${item.id}`,
          type: eType.NhanSu,
        });
      });
    });
  }
  console.log(result);
  return result;
};

export const CompanyGroup: React.FC = () => {
  const { t } = useTranslation('companyGroup');
  const windowSize = useWindowSize();
  const dataGroups = useAppSelector(getGroups());
  const [Data, setData] = useState<iCompanyGroup[]>([]);
  const company = useAppSelector(getCurrentCompany());

  const dispatch = useAppDispatch();

  // [#20692][phuong_td][31/10/2024] Dữ liệu thay đổi
  let DataModifine: { [key: string]: iCompanyGroup } = {};
  // [#20692][phuong_td][31/10/2024] Cấu hình của table
  const buildTree = (data: any[]) => {
    const map: { [key: string]: any } = {};
    const treeData: any[] = [];

    // Tạo map để tra cứu nhanh các phần tử
    data.forEach(item => {
      map[item.id] = { ...item, children: [] };
    });

    // Sắp xếp các phần tử vào cây dựa trên parentID
    data.forEach(item => {
      if (item.parentId && map[item.parentId]) {
        // Nếu có parentID, thêm vào children của phần tử chas
        map[item.parentId].children.push(map[item.id]);
      } else {
        // Nếu không có parentID, đây là phần tử gốc
        treeData.push(map[item.id]);
      }
    });

    return treeData;
  };

  useEffect(() => {
    console.log(company, 'company');
    dispatch(groupActions.getGroupsRequest(company.id));
  }, []);

  useEffect(() => {
    console.log('data ', Data);
  }, [Data]);
  useEffect(() => {
    console.log('dataGroups ', dataGroups);
    setData(buildTree(convertData(dataGroups)));
  }, [dataGroups]);
  const columns: any[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: `100%`,
      render: (_: any, record: iCompanyGroup) => renderNameColumn(record),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (_: any, record: iCompanyGroup) => {
        const fAdd = {
          label: (
            <Typography.Text
              // disabled={!isComplete}
              onClick={() => {}}
            >
              {t('Add')}
            </Typography.Text>
          ),
          key: `Add-${record.id}`,
        };
        const fTransfer = {
          label: (
            <Typography.Text
              // disabled={!isComplete}
              onClick={() => {}}
            >
              {t('Transfer')}
            </Typography.Text>
          ),
          key: `Transfer-${record.id}`,
        };
        const fDelete = {
          label: (
            <Typography.Text
              // disabled={!isComplete}
              onClick={() => {}}
            >
              {t('Delete')}
            </Typography.Text>
          ),
          key: `Delete-${record.id}`,
        };
        const items: MenuProps['items'] = [];

        switch (record.type) {
          case eType.PhongBan:
          case eType.Nhom:
            items.push(fAdd, fDelete);
            break;
          case eType.NhanSu:
            items.push(fTransfer);
            break;
        }
        return (
          <Space>
            <Dropdown menu={{ items }} trigger={['click']}>
              <EllipsisOutlined style={{ color: colors.primary }} />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const renderNameColumn = (record: iCompanyGroup) => {
    const isBold = record.type === eType.PhongBan || record.type === eType.Nhom;

    return (
      <Space>
        <Tooltip title={`${record.name}`}>
          <Typography.Text strong={isBold}>{`${record.name}`}</Typography.Text>
        </Tooltip>
      </Space>
    );
  };

  const handleRemoveIssue = (issueId: any[], listRecord: any) => {};

  return (
    <>
      <CompanyGroupHeader />
      <Table
        rowKey={record => record.id}
        size={'large'}
        style={{ width: '100%', height: '75vh' }}
        columns={columns}
        dataSource={Data}
        rowHoverable={false}
        scroll={{ x: 1000, y: windowSize[1] * 0.74 }}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            // Show expand/collapse icons for items with type PhongBan (1) or Nhom (2)
            const isExpandableType = record.type === eType.PhongBan || record.type === eType.Nhom;

            if (!isExpandableType) {
              return null; // Hide expand icon for non-expandable types
            }

            // Render the expand icon depending on whether the item is expanded or collapsed
            return expanded ? (
              <Space className={styles.row}>
                <CaretDownOutlined onClick={e => onExpand(record, e)} style={{ fontSize: '18px', color: '#52c41a', paddingRight: 2 }} />
              </Space>
            ) : (
              <Space className={styles.row}>
                <CaretRightOutlined onClick={e => onExpand(record, e)} style={{ fontSize: '18px', color: '#000000', paddingRight: 2 }} />
              </Space>
            );
          },
          expandIconColumnIndex: 0,
        }}
        pagination={false}
      />
    </>
  );
};

export default CompanyGroup;
