import { useEffect, useState } from 'react';


import { ClockCircleOutlined, EyeOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Carousel, Flex, Image} from 'antd';
import PropTypes from 'prop-types';

import styles from '../../Review.module.css';
import { IReviewItem } from '@/common/define';

const ReviewDetailHead = (props: {message: IReviewItem, handleItemClick?: any}) => {
  const { message, handleItemClick } = props;
  const [sizeImage, setSizeImage] = useState<number>(250);
  useEffect(() => {
  }, [])

  return (
    <Flex vertical style={{paddingBottom: 10}} onClick={() => handleItemClick && handleItemClick(message)}>
      <div className={styles.fontLarge}>
        {message.subject}
      </div>
      <div className={`${styles.fontSmall} ${styles.flexRow}`}>
        <ClockCircleOutlined style={{marginRight: 5}}/>
        <div style={{paddingRight: 20}}>{message.createdDate}</div>
        <UserOutlined style={{marginRight: 5}}/>
        <div style={{paddingRight: 20}}>{message.senderName}</div>
        <EyeOutlined style={{marginRight: 5}}/>
        <div style={{paddingRight: 20}}>{20} người xem</div>
      </div>
      <div className={`${styles.fontNormal}`}
          style={{paddingTop: 10}}
      >
        {message.content}
      </div>
      <div style={{display: 'flex', justifyContent: 'center', width:"100%", paddingTop:10, paddingBottom:10}}>
        <Carousel arrows infinite={false} style = {{margin: 0, width: `${4*sizeImage}px`, height: `${sizeImage}px`, color: '#fff', lineHeight: `${sizeImage}px`, textAlign: 'center', background: '#808080', justifyContent: 'center'}}
                  slidesToShow={3} slidesToScroll={1}
        >
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
          <Image src={'https://cdn.langeek.co/photo/49453/original/build?type=jpeg'} width={sizeImage} height={sizeImage}/>
        </Carousel>
      </div>      
    </Flex>
  )
}


export default ReviewDetailHead;
