import { of } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

class CxmService {
  Message = {
    Get: {
      /**
       * 
       * @param companyId 
       * @param categoryCode 
       * @param startDate 
       * @param endDate 
       */
      getByCategoryCode: (companyId: number, categoryCode: string, startDate: string='2000-01-01', endDate: string='9000-01-01', options?: RequestOptions) => {
        return HttpClient.get(`${apiUrl}/Message/getByCategoryCode/${companyId}?categoryCode=${categoryCode}&startDate=${startDate}&endDate=${endDate}`, options);
      },
      /**
       * 
       * @param messageId 
       * @param options 
       * @returns 
       */
      getCommentsByMessage: (messageId: number, options?: RequestOptions) => {
        return HttpClient.get(`${apiUrl}/Comment/getCommentsByMessage/${messageId}`, options);
      },
      /**
       * 
       * @param messageId 
       * @param options 
       * @returns 
       */
      getCountLikes: (messageId: number, options?: RequestOptions) => {
        return HttpClient.get(`${apiUrl}/Message/getCountLikes/${messageId}`, options);
      },
    },

    Post: {
      PostComment: (data: any, options?: RequestOptions) => {
        return HttpClient.post(`${apiUrl}/Comment/postComment`, data, options);
      },
      Like: (messageId: number, data: any, options?: RequestOptions) => {
        return HttpClient.post(`${apiUrl}/Message/like/${messageId}`, data, options);
      },
    }
  };

  public Get = {
    /**
     * Lấy dữ liệu chi phí phát sinh ngoài của dự án
     * AdditionalCost/getByProjectId/3
     * @param projectId {number}
     * @returns
     */
    getAdditionalCosts: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/AdditionalCost/getByProjectId/${projectId}`, options);
    },
    /**
     * Lấy thong tin image
     * Document/downloadFile/2?companyId=1
     * @param id {number} drawingId
     * @param companyId {number}
     * @returns
     */
    downloadFile: (id: string, companyId: string, options?: RequestOptions) => {
      return HttpClient.get(
        `${apiUrl}/Document/downloadFile/${id}?companyId=${companyId}`, {
          ...options,
          headers: {
            ...options?.headers,
          },
          responseType: 'blob',
        }
      );
    },
    
  };

  public Post = {
    /**
     * upload AttachmentFiles
     * /AdditionAttachmentLink/uploadAttachmentFiles
     * @param itemId {number} id của record addition cost
     * @param dataImage: FormData
     * @returns
     */
    uploadAttachmentFiles: (itemId: number, dataImage: FormData, options?: RequestOptions) => {
      //uploadFileCPPS
      return HttpClient.post(
        `${apiUrl}/AdditionAttachmentLink/uploadAttachmentFile?itemId=${itemId}`,
        dataImage,
        options,
      );
    },
  };

  public Put = {};

  public delete = {
        /**
     * Xóa AttachmentFiles
     * /AdditionAttachmentLink/deleteAttachmentFiles
     * @param itemId {number} id của record addition cost
     * @param drawingIds: {string[]}
     * @returns
     */    
        deleteAttachmentFiles: (itemId: number, drawingIds: string[], options?: RequestOptions) => {
          return HttpClient.delete(
            `${apiUrl}/AdditionAttachmentLink/deleteAttachmentFiles?itemId=${itemId}`, options, drawingIds);
        },
  };
}

export const cxmService = new CxmService();
