import { Observable } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { IEmployeeFee, PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl, accountingInvoiceURL } = getEnvVars();

export interface EmployeesPagingResponse extends PagingResponse {
  results: EmployeeResponse[];
}

export interface EmployeeResponse {
  id: number;
  userId: string;
  companyId: number;
  employeeCode: string;
  employIdConnect: string;
  contactDetail: ContactResponse;
  dateOfBirth: string;
  startDate: string;
  endDate: string;
  gender: string;
  firstName: string;
  middleName: string;
  lastName: string;
  homeTown: string;
  identity: string;
  maritalStatus: string;
  nationality: string;
  picture: string;
  status: number;
  mood: string;
}

export interface ContactResponse {
  employeeId: number;
  addressStreet1: string;
  addressStreet2: string;
  city: string;
  country: string;
  homePhone: string;
  mobile: string;
  otherEmail: string;
  workEmail: string;
  workPhone: string;
  zipCode: string;
}

export interface CreateEmployeePayload {
  companyId: number;
  companyGuid: string;
  employeeCode: string;
  dateOfBirth: string;
  startDate: string;
  endDate: string;
  gender: string;
  firstName: string;
  middleName: string;
  lastName: string;
  homeTown: string;
  identity: string;
  maritalStatus: string;
  nationality: string;
  picture: string;
  status: number;
  mood: string;
  contactDetail: {
    addressStreet1: string;
    addressStreet2: string;
    city: string;
    country: string;
    homePhone: string;
    mobile: string;
    otherEmail: string;
    workEmail: string;
    workPhone: string;
    zipCode: string;
  };
}
export interface DanhSachUserResponse {
  del: boolean;
  id: number;
  un: string;
  pw: string | null;
  groupID: number;
  groupIDSign: number;
  isOwner: number;
  email: string;
  guid: string;
  createDate: string;
  capDuyetChi: number;
}
export interface UpdateEmployeePayload extends CreateEmployeePayload {
  id: number;
}

export interface rankData {
  projectId: number;
  companyId: number;
  rankCode: string;
  rankName: string;
  name: string;
  target: number;
  unit: string;
  weightCriteria: number;
  level1: string;
  level2: string;
  level3: string;
  level4: string;
  description: string;
  id: number;
  diem: number;
}

export interface EmployeePoint {
  id: number;
  employeeId: number;
  employeeName: string;
  employeeCode: string;
  kipCriteriaId: number;
  projectId: number;
  companyId: number;
  confirmBy: number;
  point: number;
  createdDate: string;
  notes: string;
}

class EmployeeController {
  public Get = {
    getRank: (companyId: number) => {
      return HttpClient.get(`${apiUrl}/KPICriteria/getRank/${1}`);
    },
    getRankById: (companyId: number, rankCode: string, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/KPICriteria/getbyRank/${companyId}?rankCode=${rankCode}`, options);
    },
    getAllEmployees: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee`, options);
    },
    getEmployees: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee/company/${companyId}`, options);
    },
    getEmployeeDetails: (employeeId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee/${employeeId}`, options);
    },
    getDanhSachUser: (options?: RequestOptions): Observable<DanhSachUserResponse[]> => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetDanhSachUser`, options);
    },
    getFeeTableEmployee: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/FeeTableEmployee/getByCompanyId//${companyId}`, options);
    },
  };

  public Post = {
    createEmployee: (input: CreateEmployeePayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Employee/detail`, input, options);
    },
    createFeeTableEmployee: (feeTable: IEmployeeFee[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/FeeTableEmployee/creates`, feeTable, options);
    },
  };

  public Put = {
    updategetByEmployeeId: (employeeId: number, dateTime: string, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/KPIEmployee/getbyEmployeeId/${employeeId}?dateTime=${dateTime}`, options);
    },
    updateEmployeeId: (companyId: number, employeeId: number, dateTime: string, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/KPIEmployee/updates/${1}/${employeeId}?dateTime=${dateTime}`, options);
    },
    updateEmployee: (employeeId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Employee/${employeeId}/detail`, input, options);
    },
    updateFeeEmployee: (input: IEmployeeFee, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/FeeTableEmployee/${input.id}`, input, options);
    },
  };

  public delete = {
    removeEmployee: (EmployeeId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Employee/${EmployeeId}`, options);
    },
  };
}

export const EmployeeService = new EmployeeController();
