import React, { useEffect, useState } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Button, DatePicker, Upload, Space, InputNumber, message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { accountingInvoiceActions } from '@/store/accountingInvoice';
import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

interface ExpenseFormProps {
  record?: any;
}

const ExpenseForm: React.FC<ExpenseFormProps> = ({ record }) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { t } = useTranslation('material');
  const selectedProject = useAppSelector(getSelectedProject());
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  //khởi tạo form dữ liệu formdata để lưu dữ liệu các tệp ảnh vừa tải lên
  const [formFileData, setFormFileData] = useState<FormData | null>(null);
  const formData = new FormData();
  fileList.forEach((file: any) => {
    if (file.originFileObj) {
      formData.append('files', file.originFileObj);
    }
  });

  // useEffect để xử lý tải ảnh khi có tệp trong danh sách tải lên
  useEffect(() => {
    const uploadFiles = async () => {
      if (fileList.length > 0) {
        try {
          await dispatch(
            accountingInvoiceActions.createFileCPPS({
              itemId: record?.id,
              dataImage: formData,
            }),
          );
          message.success('Thêm mới chi phí và tải ảnh lên thành công!');
        } catch (error) {
          message.error('Có lỗi xảy ra khi tải ảnh lên.');
          console.error('Lỗi khi tải ảnh lên:', error);
        }
      }
    };
    uploadFiles();
  }, []);

  const onFinish = async (values: any) => {
    //lưu onfinish là tạo mới form dữ liệu bao gồm
    const dataCreate = {
      projectId: selectedProject?.id,
      costName: values.expenseName,
      costCode: values.expenseCode,
      unit: values.unit,
      createdById: 0,
      createdBy: values.payer,
      createDate: values.date ? values.date.toISOString() : new Date().toISOString(),
      amount: values.amount,
      quantity: values.quantity,
      totalAmount: values.totalAmount,
      payer: values.payer,
      payerId: 0,
      notes: values.note,
    };

    try {
      await dispatch(
        //sau đó dispatch tới action CreateAdditionalCost bao gồm dataform bên trên và dữ liệu ảnh để bắt đầu thực hiện hành động tạo mới
        accountingInvoiceActions.CreateAdditionalCost({
          dataCreate: [dataCreate],
          files: formData,
        }),
      );
    } catch (error) {
      message.error('Có lỗi xảy ra, vui lòng thử lại.');
      console.error('Lỗi:', error);
    }
  };

  const handleUploadChange = (info: any) => {
    // Xử lý khi có thay đổi trong upload, cập nhật danh sách tệp
    const { status, fileList } = info;
    const formData = new FormData();
    fileList.forEach((file: any) => {
      if (file.originFileObj) {
        formData.append('files', file.originFileObj);
      }
    });
    setFormFileData(formData);

    setFileList(fileList);
  };

  const handlePreview = async (file: UploadFile) => {
    // Xử lý khi người dùng nhấn để xem lại các ảnh vừa tải lên
    if (file.url) {
      setSelectedImage(file.url);
    } else if (file.preview) {
      setSelectedImage(file.preview as string);
    } else {
      const preview = await new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve(reader.result as string);
      });
      setSelectedImage(preview);
      file.preview = preview;
    }
  };

  const handleImageClose = () => {
    //close xem ảnh
    setSelectedImage(null);
  };

  return (
    <div style={{ maxWidth: 500, borderRadius: 8 }}>
      <h3>{t('Add new costs')}</h3>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item label={t('Expense Code')} name="expenseCode">
          <Input placeholder={t('Enter expense code')} />
        </Form.Item>

        <Form.Item label={t('Expense Name')} name="expenseName">
          <Input placeholder={t('Enter expense name')} />
        </Form.Item>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Payer')} name="payer">
            <Input style={{ width: '230px' }} placeholder={t('Enter payer')} />
          </Form.Item>
          <Form.Item label={t('Date')} name="date">
            <DatePicker style={{ width: '220px' }} defaultValue={dayjs()} placeholder={t('Select date')} />
          </Form.Item>
        </Space>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Unit')} name="unit">
            <Input style={{ width: '230px' }} placeholder={t('Enter unit')} />
          </Form.Item>
          <Form.Item label={t('Quantity')} name="quantity">
            <InputNumber style={{ width: '220px' }} placeholder={t('Enter quantity')} />
          </Form.Item>
        </Space>

        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('Amount')} name="amount">
            <InputNumber style={{ width: '230px' }} placeholder={t('Enter amount')} />
          </Form.Item>
          <Form.Item label={t('Total1')} name="totalAmount">
            <InputNumber style={{ width: '220px' }} placeholder={t('Enter total')} />
          </Form.Item>
        </Space>

        <Form.Item label={t('Note')} name="note">
          <TextArea rows={2} placeholder={t('Enter note')} />
        </Form.Item>

        <Form.Item label={t('Upload Image')} name="upload">
          <Upload.Dragger
            name="files"
            listType="picture"
            accept=".jpg,.jpeg,.png"
            beforeUpload={() => false}
            onChange={handleUploadChange}
            onPreview={handlePreview}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Chọn từ thư mục hoặc kéo thả tệp định dạng jpeg, png</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button type="default" htmlType="button">
              {t('Cancel')}
            </Button> */}
            <Button type="primary" htmlType="submit">
              {t('Save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {selectedImage && ( // Phần hiển thị ảnh khi người dùng nhấn xem trước
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '10px', //padding ảnh
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
          role="dialog"
          aria-modal="true"
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxWidth: '80vw',
              maxHeight: '80vh',
              objectFit: 'contain',
              display: 'block',
              margin: '0 auto',
            }}
          />
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <button
              type="button"
              onClick={handleImageClose}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleImageClose();
                }
              }}
              style={{ padding: '8px 16px', cursor: 'pointer' }}
            >
              Đóng
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpenseForm;
