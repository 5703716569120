/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Table, Image, Checkbox, Space, Tooltip, Button, Popconfirm, PaginationProps, InputNumber } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './TableCustom.css';
import { useDispatch } from 'react-redux';

import { colors } from '@/common/colors';
import { formatDateDisplay } from '@/common/define';
import { useWindowSize } from '@/hooks';
import fallback from '@/image/fallback.svg';
import Utils from '@/utils';

export interface TableCustomProps<T> {
  dataSource: T;
  columnsConfig: { [key: string]: iColumnsConfig };
  onChange?: (data: T, type: string) => void;
  onImagePopup?: (data: T) => void;
}

export enum eColumnsTpye {
  text = 'text',
  textStart = 'textStart',
  date = 'date',
  number = 'number',
  image = 'image',
  input = 'input',
  checkbox = 'checkbox',
  action = 'action',
  inputNumber = 'inputNumber',
}

export interface iColumnsConfig {
  hidden?: boolean;
  isKey?: boolean;
  title?: string;
  width?: number | string;
  type: eColumnsTpye;
  fixed?: 'left' | 'right' | boolean;
  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  onChange?: (checked: boolean, record: any) => void;
  sorter?: boolean;
  onClick?: (record: any) => void;
  // [09/11/2024][#20629][phuong_td] thêm câu thông báo cho action
  actions?: {
    name: 'edit' | 'remove';
    Notification?: string;
    action: (record: any) => void;
  }[];
  // [09/11/2024][#20629][phuong_td] tùy chọn cho phép bật tắt chức năng preview cho image
  previewImage?: boolean;
  formaterNumber?: boolean; // [dung_lt][13/11/2024] cho phép format 10000 thành 10,000 và đang chỉ dành cho inputNumber
}
export const TableCustom = ({ dataSource, columnsConfig, onChange, onImagePopup }: TableCustomProps<any>) => {
  const windowSize = useWindowSize();
  const [Columns, setColumns] = useState<ColumnType<any>[]>([]);
  const tTable = useTranslation(['table']).t;
  const dispatch = useDispatch(); // Access the dispatch function from Redux
  // [09/11/2024][#20629][phuong_td] thêm state giữ tên của table key
  const [rowKey, setRowKey] = useState<string>('');

  // [09/11/2024][#20629][phuong_td] Không được gọi api trực tiếp ở component này
  // const handleDelete = (id: number) => {
  //   // Dispatch the DeleteAdditionalCost action with the selected record ID
  //   dispatch(accountingInvoiceActions.DeleteAdditionalCost({ id }));
  // };
  const renderAction = (record: any) => {
    const action: ReactElement[] = [];
    const edit = columnsConfig['action']?.actions?.find(a => a.name === 'edit');
    if (edit) {
      action.push(
        <Tooltip title={'Edit'} key={'edit'}>
          <Button
            icon={<EditOutlined style={{ color: colors.primary }} />}
            type="text"
            size="small"
            onClick={() => edit.action(record)}
          />
        </Tooltip>,
      );
    }

    const remove = columnsConfig['action']?.actions?.find(a => a.name === 'remove');
    if (remove) {
      // [09/11/2024][#20629][phuong_td] điều chỉnh câu thông báo cho hành động
      action.push(
        <Popconfirm // popconfirm xóa ảnh
          key="remove"
          title={remove.Notification}
          onConfirm={() => remove.action(record)}
          cancelText={tTable('Hủy')}
        >
          <Tooltip title={tTable('Xóa')}>
            <Button icon={<DeleteOutlined />} danger size="small" type="text" />
          </Tooltip>
        </Popconfirm>,
      );
    }

    return <Space>{action.map(e => e)}</Space>;
  };

  useEffect(() => {
    // [#20692][phuong_td][31/10/2024] lấy danh sách tên thuộc tính của một đối tượng
    const personnelKeys: string[] = Utils.getKeys<{ [key: string]: iColumnsConfig }>(columnsConfig);
    const columns: ColumnType<any>[] = [];
    // [#20692][phuong_td][31/10/2024] Tạo columns động theo danh sách thuộc tính và dữ liệu cấu hình của bảng
    personnelKeys.forEach(m => {
      // [#20692][phuong_td][31/10/2024] chỉ tạo các cột mà cấu hình hidden bằng false
      if (columnsConfig[m] && !columnsConfig[m].hidden) {
        const { type, onClick, width, fixed, title, align, previewImage, sorter, isKey, formaterNumber } =
          columnsConfig[m];
        // [09/11/2024][#20629][phuong_td] tên của thuộc tính là key
        if (isKey) {
          setRowKey(m);
        }
        const column: any = {
          fixed: fixed,
          title: title ?? <></>,
          dataIndex: m,
          key: m,
          // width: width,
          // className: _columnsHidden.includes(m) ? 'HiddenColumn' : '',
          align: align ?? 'center',
          render: (text: any, record: any) => {
            // [#20692][phuong_td][31/10/2024] Render Input cho cấu hình type = input
            switch (type) {
              case eColumnsTpye.textStart:
                return (
                  <div key={`${m}-${record.Key}`} style={{ textAlign: 'start' }}>
                    {text}
                  </div>
                );
              case eColumnsTpye.date:
                return <span key={`${m}-${record.Key}`}>{dayjs(text).format(formatDateDisplay)}</span>;
              case eColumnsTpye.number:
                return <span key={`${m}-${record.Key}`}>{Utils.formatNumber(text)}</span>;
              case eColumnsTpye.image:
                return (
                  <Image
                    onClick={() => {
                      if (onImagePopup) onImagePopup(record);
                    }}
                    width={width}
                    height={width}
                    src={text}
                    // [09/11/2024][#20629][phuong_td] đưa dữ liệu ảnh ra chổ khác
                    fallback={fallback}
                    preview={previewImage}
                  />
                );
              case eColumnsTpye.input:
                return (
                  <Input
                    key={Utils.generateRandomString(3)}
                    className={''}
                    readOnly={false}
                    defaultValue={text}
                    // value={text}
                    onChange={v => {
                      if (onChange) {
                        const data = { ...record };
                        data[m] = v.target.value;
                        onChange(data, m);
                      }
                    }}
                  />
                );
              case eColumnsTpye.inputNumber:
                return (
                  <InputNumber
                    key={Utils.generateRandomString(3)}
                    className={''}
                    style={{ width: '100%' }}
                    readOnly={false}
                    defaultValue={text}
                    formatter={value =>
                      formaterNumber ? value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value
                    }
                    parser={value => (formaterNumber ? value?.replace(/,/g, '') : value)}
                    // value={text}
                    onChange={v => {
                      if (onChange) {
                        const data = { ...record };
                        data[m] = v || 0;
                        onChange(data, m);
                      }
                    }}
                  />
                );
              case eColumnsTpye.checkbox:
                // [10/11/2024][#20691][phuong_td] Gắn value cho checkbox
                return (
                  <Input
                    type="checkbox"
                    defaultChecked={text}
                    onChange={e => {
                      if (onChange) {
                        const data = { ...record };
                        data[m] = e.target.checked;
                        onChange(data, m);
                      }
                    }}
                  ></Input>
                );
              case eColumnsTpye.action:
                return renderAction(record);
            }
            // [#20692][phuong_td][31/10/2024] Render Input cho cấu hình type = text
            return <span key={`${m}-${record.Key}`}>{text}</span>;
          },
        };
        // [09/11/2024][#20629][phuong_td] thêm xử lý nếu trường width không được truyền vào
        if (width) {
          column.width = width;
        }
        // [09/11/2024][#20629][phuong_td] thêm chức năng sort
        if (sorter) {
          column.sorter = (a: any, b: any) => {
            const data1 = a[m];
            const data2 = b[m];
            switch (type) {
              case eColumnsTpye.textStart:
              case eColumnsTpye.number:
              case eColumnsTpye.text:
                return data1.localeCompare(data2);
              case eColumnsTpye.date:
                return dayjs(data1).diff(dayjs(data2));
              default:
            }
          };
        }
        columns.push(column);
      }
    });
    setColumns(columns);
  }, [columnsConfig]);
  // [09/11/2024][#20629][phuong_td] thêm thông tin phân trang
  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    tTable('pagingTotal', { range1: range[0], range2: range[1], total });
  return (
    <Table
      rowKey={record => {
        // [09/11/2024][#20629][phuong_td] gắn key cho các dòng theo biến key
        const key = record[rowKey] ?? Utils.generateRandomString(4);
        return key;
      }}
      className="antd-table-custom"
      dataSource={dataSource}
      columns={Columns}
      rowHoverable={false}
      bordered={true}
      tableLayout={'fixed'}
      // pagination={false}
      // [09/11/2024][#20629][phuong_td] điều chỉnh chức năng phân trang
      pagination={{
        pageSizeOptions: [20, 50, 100],
        defaultPageSize: 20,
        total: dataSource.length || 0,
        responsive: true,
        showTotal,
        showSizeChanger: true,
      }}
      scroll={{
        // x: 'max-content',
        x: 1000,
        y: windowSize[1] - 250,
      }}
      // summary={() => (
      //   <Table.Summary.Row>
      //     <Table.Summary.Cell index={0} colSpan={5}>
      //       Tổng
      //     </Table.Summary.Cell>
      //     <Table.Summary.Cell index={5}>125</Table.Summary.Cell>
      //     <Table.Summary.Cell index={6}></Table.Summary.Cell>
      //     <Table.Summary.Cell index={7}>125</Table.Summary.Cell>
      //   </Table.Summary.Row>
      // )}
    />
  );
};

export default TableCustom;
