/* eslint-disable import/order */
import { catchError, concat, filter, map, mergeMap, Observable, switchMap, withLatestFrom } from 'rxjs';

import { accountingInvoice, defaultPagingParams, eStatusRequest, FormatDateAPI } from '@/common/define';
import { AccountingInvoiceService, IAttachmentLinks } from '@/services/AccountingInvoiceService';
import Utils from '@/utils';
import dayjs from 'dayjs';
import { accountingInvoiceActions, getDateRange } from '../accountingInvoice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { cxmService } from '@/services/CxmService';
import { DataType } from '@/services/AccountingInvoiceService';

const GetDieuChuyenVatTu$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDieuChuyenVatTu.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { madvcs, tu_ngay, den_ngay, ma_kho } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoiceActions.GetDieuChuyenVatTu.type })],
        AccountingInvoiceService.Post.GetDanhSachDieuChuyenHangHoaVatTu(madvcs, tu_ngay, den_ngay, ma_kho).pipe(
          switchMap(results => {
            return [accountingInvoiceActions.setDieuchuyenvattu(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoiceActions.GetDieuChuyenVatTu.type })],
      );
    }),
  );
};
const CreatePhieuDieuChuyen$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuDieuChuyen.match),
    switchMap(action => {
      const { data }: any = action.payload;
      const tu_ngay = dayjs().startOf('month').format(FormatDateAPI);
      const den_ngay = dayjs().endOf('month').format(FormatDateAPI);
      const ma_kho = data.chiTietHangHoa[0]?.ma_kho || '';
      return concat(
        [startLoading({ key: 'CreatePhieuDieuChuyen' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(repon => {
            Utils.successNotification('Tạo phiếu thành công');
            return [
              accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(repon),
              accountingInvoiceActions.GetDieuChuyenVatTu({ madvcs: 'THUCHIEN', tu_ngay, den_ngay, ma_kho }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuDieuChuyen' })],
      );
    }),
  );
};
// [#20627][nam_do][30/10/2024] Cập nhập tồn kho khi nhập kho xong
const CreatePhieuNhapKho$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapKho.match),
    switchMap(action => {
      const { data }: any = action.payload;
      const tu_ngay = dayjs().startOf('month').format(FormatDateAPI);
      const den_ngay = dayjs().endOf('month').format(FormatDateAPI);
      const ma_kho = data.chiTietHangHoa[0]?.ma_kho || '';
      return concat(
        [startLoading({ key: 'CreatePhieuDieuChuyen' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu xuất kho thành công');
            return [
              accountingInvoiceActions.GetDieuChuyenVatTu({ madvcs: 'THUCHIEN', tu_ngay, den_ngay, ma_kho }),
              accountingInvoiceActions.GetTonKho({
                data: {
                  madvcs: 'THUCHIEN',
                  danhSachMaHang: [],
                  ngay_kiem_tra: dayjs().format(FormatDateAPI),
                  danhSachMakho: [ma_kho],
                },
                params: {},
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuDieuChuyen' })],
      );
    }),
  );
};

const CreatePhieuXuatKho$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuXuatKho.match),
    switchMap(action => {
      const { data }: any = action.payload;
      return concat(
        [startLoading({ key: 'CreatePhieuXuatKho' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu xuất kho thành công');
            return [accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(response)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuXuatKho' })],
      );
    }),
  );
};
const CreatePhieuNhapKhodc$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapKhodc.match),
    switchMap(action => {
      const { data }: any = action.payload;
      return concat(
        [startLoading({ key: 'CreatePhieuNhapKho' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu nhập kho thành công');
            return [accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(response)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuNhapKho' })],
      );
    }),
  );
};
const getProductRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getProducts })],
        AccountingInvoiceService.Get.GetProduct({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetProducts(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetProducts(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getProducts })],
      );
    }),
  );
};

const getWareHouse$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetWareHouse.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getWareHouse })],
        AccountingInvoiceService.Get.GetWareHouse({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetWareHouse(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetWareHouse(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getWareHouse })],
      );
    }),
  );
};

const GetProductUnit$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProductUnit.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProductUnit })],
        AccountingInvoiceService.Get.GetProductUnit({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProductUnits(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProductUnits(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProductUnit })],
      );
    }),
  );
};

const GetDanhSachThietBi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachThietBi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachThietBi })],
        AccountingInvoiceService.Get.GetDanhSachThietBi({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMachineries(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMachineries(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachThietBi })],
      );
    }),
  );
};

const GetMoneyTypeList$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetMoneyTypeList.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetMoneyTypeList })],
        AccountingInvoiceService.Get.GetMoneyTypeList({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMoneyTypes(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMoneyTypes(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetMoneyTypeList })],
      );
    }),
  );
};

//#region PhieuNhapXuatKho
const GetDanhSachDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
        AccountingInvoiceService.Get.GetDanhSachDuyetChi({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(results),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({ params }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({ params: undefined }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
      );
    }),
  );
};

const GetDanhSachDuyetMuaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetMuaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
        AccountingInvoiceService.Get.GetDanhSachDuyetMuaHang({ search: { ...params } }).pipe(
          mergeMap((results: any) => {
            //         var data = results
            // .replaceAll('\\"', '"')   // Replace escaped double quotes
            // .replaceAll(/"\[|\]"/g, (match: string) => match.replace(/"/g, '')) // Remove quotes before/after brackets
            // .replaceAll(/\n/g, '') // Remove both newline and carriage return characters
            // .replaceAll(/\r/g, ''); // Remove both newline and carriage return characters
            // console.log(jsonParse, 'jsonParse');
            // console.log('here', results);
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(results),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({ params }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({ params }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
      );
    }),
  );
};

const DuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.DuyetChi })],
        AccountingInvoiceService.Post.DuyetChi(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DuyetChi })],
      );
    }),
  );
};

const HuyDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.HuyDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.HuyDuyetChi })],
        AccountingInvoiceService.Post.HuyDuyetChi(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.HuyDuyetChi })],
      );
    }),
  );
};

const CreatePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.CreatePhieuNhapXuatKho(data).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
      );
    }),
  );
};
const DeletePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeletePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.DeletePhieuNhapXuatKho(ids).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
      );
    }),
  );
};
//#region Tồn kho
const GetTonKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetTonKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params, TonKhoTheoNgay } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetTonKho })],
        AccountingInvoiceService.Post.GetTonKho(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(results)];
            } else {
              return [accountingInvoiceActions.setTonkho(results)];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(undefined)];
            } else {
              return [accountingInvoiceActions.setTonkho(undefined)];
            }
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetTonKho })],
      );
    }),
  );
};
//[20563] [nam_do] lấy KL theo định mức
const GetKLdinhmuc$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getKldinhmuc.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetTonKho })],
        AccountingInvoiceService.Post.GetTonKho(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setKLdinhmuc(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetTonKho })],
      );
    }),
  );
};
// [15/10/2024][#20413][ngoc_td] add epic lấy giá & ncc
const GetGiaVaNhaCungCap$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetGiaVaNhaCungCap.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetGiaVaNhaCungCap })],
        AccountingInvoiceService.Post.GetGiaVaNhaCungCap(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            var data = results
              .replaceAll('\\"', '"') // Replace escaped double quotes
              .replaceAll(/"\[|\]"/g, (match: string) => match.replace(/"/g, '')) // Remove quotes before/after brackets
              .replaceAll(/\n/g, '') // Remove both newline and carriage return characters
              .replaceAll(/\r/g, ''); // Remove both newline and carriage return characters
            const jsonParse = JSON.parse(data);
            return [accountingInvoiceActions.setPriceAndNcc(jsonParse)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setPriceAndNcc(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetGiaVaNhaCungCap })],
      );
    }),
  );
};
//[20433] [ngoc_td] add getCustomers, newCustomers api to redux
const GetNcc$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getCustomers.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        [startLoading({ key: accountingInvoice.getCustomers })],
        AccountingInvoiceService.Get.getCustomer().pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setCustomers(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setCustomers(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getCustomers })],
      );
    }),
  );
};
// [22/10] [ngoc_td] add notification when success
const NewNcc$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.newCustomers.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetGiaVaNhaCungCap })],
        AccountingInvoiceService.Post.newCustomer(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            if (results.isSuccess) {
              Utils.successNotification();
              return [accountingInvoiceActions.getCustomers()];
            } else {
              Utils.errorHandling(results.errorMessage);
              return [accountingInvoiceActions.getCustomers()];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.getCustomers()];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetGiaVaNhaCungCap })],
      );
    }),
  );
};
// [#20755] [hao_lt] call api báo cáo thu doanh thu chi phí
const BaoCaoDanhThu$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getBaoCaoDanhThuChiPhi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { tu_ngay, den_ngay } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.getBaoCaoDoanhThuChiPhi })],
        AccountingInvoiceService.Post.BaoCaoDanhThu(tu_ngay, den_ngay).pipe(
          mergeMap((results: any) => {
            if (results) {
              return [accountingInvoiceActions.setBaoCaoDanhThuChiPhi(results)];
            }
            return [];
          }),
          catchError((error: any) => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setBaoCaoDanhThuChiPhi(null)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getBaoCaoDoanhThuChiPhi })],
      );
    }),
  );
};

//#region ProposalForm
const GetProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProposalForm })],
        AccountingInvoiceService.Get.GetProposalForm({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProposalForms(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProposalForms(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProposalForm })],
      );
    }),
  );
};

const GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa })],
        AccountingInvoiceService.Get.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa({ search: { ...params } }).pipe(
          mergeMap(results => {
            var data = results
              .replaceAll('\\"', '"') // Replace escaped double quotes
              .replaceAll(/"\[|\]"/g, (match: string) => match.replace(/"/g, '')) // Remove quotes before/after brackets
              .replaceAll(/\n/g, '') // Remove both newline and carriage return characters
              .replaceAll(/\r/g, ''); // Remove both newline and carriage return characters
            const jsonParse = JSON.parse(data);
            return [accountingInvoiceActions.setChiTietHangHoa(jsonParse)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setChiTietHangHoa(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa })],
      );
    }),
  );
};
const ConfirmProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.ConfirmProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.ConfirmProposalForm })],
        AccountingInvoiceService.Post.CreateProposalForm(data, { search: { ...params } }).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: 'KEHOACH',
              //     ngay_de_nghi_tu_ngay: '2024-09-23',
              //     ngay_de_nghi_den_ngay: '2024-09-23',
              //     ma_kho: 'TONG'
              //   },
              // }),
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({
                params: state.accountingInvoice.query_danhSachDuyetMuaHang.params,
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.ConfirmProposalForm })],
      );
    }),
  );
};
const CreateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreateProposalForm })],
        AccountingInvoiceService.Post.CreateProposalForm(data, { search: { ...params } }).pipe(
          switchMap(d => {
            Utils.successNotification();
            const p = state.accountingInvoice.query_danhSachDuyetMuaHang?.params;
            if (p) {
              return [
                accountingInvoiceActions.setStatusRequest({
                  api: accountingInvoice.CreateProposalForm,
                  status: eStatusRequest.success,
                }),
                accountingInvoiceActions.GetDanhSachDuyetMuaHang({ params: p }),
              ];
            }
            return [
              accountingInvoiceActions.setStatusRequest({
                api: accountingInvoice.CreateProposalForm,
                status: eStatusRequest.success,
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setStatusRequest({
                api: accountingInvoice.CreateProposalForm,
                status: eStatusRequest.error,
              }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreateProposalForm })],
      );
    }),
  );
};
const UpdateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.UpdateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { id, data } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.UpdateProposalForm })],
        AccountingInvoiceService.Put.UpdateProposalForm(id, data, {}).pipe(
          switchMap(() => {
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.UpdateProposalForm })],
      );
    }),
  );
};
const DeleteProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeleteProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeleteProposalForm })],
        AccountingInvoiceService.Post.DeleteProposalForm(ids, {}).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeleteProposalForm })],
      );
    }),
  );
};

const splitDeNghiMuaHangTheoNhaCungCap$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.splitDeNghiMuaHangTheoNhaCungCap.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.SplitDeNghiMuaHangTheoNhaCungCap })],
        AccountingInvoiceService.Post.SplitDeNghiMuaHangTheoNhaCungCap(params.guid).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({
                params: state.accountingInvoice.query_danhSachDuyetMuaHang.params,
              }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.SplitDeNghiMuaHangTheoNhaCungCap })],
      );
    }),
  );
};

const DeletePhieuDeNghiMuaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeletePhieuDeNghiMuaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      const dateRange = getDateRange();
      // console.log('Deleted');
      return concat(
        [startLoading({ key: accountingInvoice.DeleteDanhSachDuyetMuaHang })],
        AccountingInvoiceService.Post.DeletePhieuDeNghiMuaHang(ids, {}).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({
                params: state.accountingInvoice.query_danhSachDuyetMuaHang.params,
              }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeleteDanhSachDuyetMuaHang })],
      );
    }),
  );
};
const getDanhSachBoPhanRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachBoPhan.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachBoPhan })],
        AccountingInvoiceService.Get.GetDanhSachBoPhan({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetDanhSachBoPhan(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetDanhSachBoPhan(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachBoPhan })],
      );
    }),
  );
};

const getBaoCaoXuatNhapTon$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getBaoCaoXuatNhapTon.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.getBaoCaoXuatNhapTon })],
        AccountingInvoiceService.Post.getBaoCaoXuatNhapTon(params.data).pipe(
          switchMap(results => {
            try {
              const parsedData = JSON.parse(results);
              return [accountingInvoiceActions.setBaoCaoXuatNhapTon(parsedData)];
            } catch (error) {
              return [accountingInvoiceActions.setBaoCaoXuatNhapTon(undefined)];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getBaoCaoXuatNhapTon })],
      );
    }),
  );
};

const getAdditionalCosts$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getAdditionalCosts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, companyId } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.getAdditionalCosts })],
        cxmService.Get.getAdditionalCosts(projectId).pipe(
          mergeMap(results => {
            try {
              let listEmployeeEvaluation: DataType[] = [];
              const getImageActions: any[] = [];
              if (results && results.length > 0) {
                results.forEach((x: any) => {
                  const item: DataType = {
                    checkbox: false,
                    id: x.id, // id
                    key: x.id,
                    costCode: x.costCode, // costCode
                    costName: x.costName, // costName
                    unit: x.unit, // unit
                    createDate: x.createDate, // createDate
                    amount: x.amount, // amount
                    quantity: x.quantity, // quantity
                    totalAmount: x.totalAmount, // totalAmount
                    hinhanh: '', // urlImage
                    payer: x.payer, // payer
                    notes: x.notes, // notes
                    attachmentLinks: x.attachmentLinks, // list urlImage
                  };
                  listEmployeeEvaluation.push(item);
                  // console.log('attachmentLinks: ', x.attachmentLinks);
                  if (x.attachmentLinks && x.attachmentLinks.length > 0) {
                    getImageActions.push(
                      accountingInvoiceActions.getImageAdditionalCosts({
                        drawingId: x.attachmentLinks[0].drawingId,
                        companyId,
                        id: x.id,
                      }),
                    );
                  }
                });
              }

              return [accountingInvoiceActions.setAdditionalCosts(listEmployeeEvaluation), ...getImageActions];
            } catch (error) {
              return [accountingInvoiceActions.setAdditionalCosts([])];
            }
          }),
          catchError(error => {
            // console.log('doing');
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getAdditionalCosts })],
      );
    }),
  );
};

const CreateAdditionalCost$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreateAdditionalCost.match),
    switchMap(action => {
      const { dataCreate, files } = action.payload; //action payload truyền vào để tạo chi phí phát sinh mới bao gồm dữ liệu tạo datacreate và files ảnh

      const data = Array.isArray(dataCreate) ? dataCreate[0] : dataCreate;

      return concat(
        [startLoading({ key: 'CreateAdditionalCost' })],
        AccountingInvoiceService.Post.CreateAdditionalCost(data).pipe(
          switchMap(response => {
            // console.log('response', response);

            Utils.successNotification('Thêm phát sinh chi phí thành công');

            if (files && files instanceof FormData) {
              return [
                accountingInvoiceActions.createAdditionalCostSuccess(response), //lưu lại response
                accountingInvoiceActions.getAdditionalCosts({ id: response.projectId, data: [] }),
                accountingInvoiceActions.createFileCPPS({
                  //tạo file ảnh bao gồm itemid và id mà api tự sinh ra khi tạo mới dữ liệu và ảnh
                  itemId: response.id,
                  dataImage: files,
                }),
              ];
            } else {
              return [
                accountingInvoiceActions.CreateAdditionalCost(response),
                accountingInvoiceActions.getAdditionalCosts({ id: response.projectId, data: [] }),
              ];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreateAdditionalCost' })],
      );
    }),
  );
};

const createFileCPPS$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.createFileCPPS.match),
    switchMap(action => {
      const { itemId, dataImage } = action.payload; //actionpayload truyền vào bao gồm itemid và dữ liệu ảnh

      return concat(
        [startLoading({ key: 'createFileCPPS' })],
        AccountingInvoiceService.Post.createFileCPPS(itemId, dataImage).pipe(
          switchMap(response => {
            // console.log('Tải file thành công', response);
            return [accountingInvoiceActions.createFileCPPSSuccess(response)];
          }),
          catchError(error => {
            console.error('Lỗi tải file', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createFileCPPS' })],
      );
    }),
  );
};

const DeleteImage$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.deleteFileCPPSRequest.match),
    switchMap(action => {
      const { itemId, drawingIds } = action.payload; //dữ liệu truyeennm vào để xóa ảnh bằng itemid và drawingid của ảnh
      return concat(
        [startLoading({ key: 'deleteFileCPPS' })],
        AccountingInvoiceService.Delete.DeleteImage(itemId, drawingIds).pipe(
          switchMap(response => {
            Utils.successNotification('Xóa ảnh thành công');

            return [accountingInvoiceActions.deleteFileCPPSSuccess(response)];
          }),
          catchError(error => {
            console.error('Lỗi xóa file', error);
            return [];
          }),
        ),
        [stopLoading({ key: 'deleteFileCPPS' })],
      );
    }),
  );
};

const UpdateAdditionalCost$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.UpdateAdditionalCost.match),
    switchMap(action => {
      const { id, dataCreate } = action.payload;
      return concat(
        [startLoading({ key: 'UpdateAdditionalCost' })],
        AccountingInvoiceService.Put.UpdateAdditionalCost(id, dataCreate).pipe(
          switchMap(response => {
            Utils.successNotification('Cập nhật phát sinh chi phí thành công');
            return [
              accountingInvoiceActions.UpdateAdditionalCost(response),
              accountingInvoiceActions.getAdditionalCosts({ id: response.id, data: [] }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'UpdateAdditionalCost' })],
      );
    }),
  );
};

const DeleteAdditionalCost$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeleteAdditionalCostRequest.match),
    switchMap(action => {
      const { id } = action.payload; //truyền id để xóa chi phí phát sinh
      return concat(
        [startLoading({ key: 'DeleteAdditionalCost' })],
        AccountingInvoiceService.Delete.DeleteAdditionalCost(id).pipe(
          switchMap(response => {
            // console.log('Xóa phát sinh chi phí thành công', response);
            Utils.successNotification('Xóa phát sinh chi phí thành công');
            return [accountingInvoiceActions.DeleteAdditionalCost({ id })];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'DeleteAdditionalCost' })],
      );
    }),
  );
};

const getImageAdditionalCosts$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getImageAdditionalCosts.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { drawingId, companyId, id } = action.payload;
      const dataAttachmentLinks = [...state.accountingInvoice.dataAttachmentLinks];
      const listData = dataAttachmentLinks.filter(x => x.drawingId === drawingId && x.imageUrl !== '');
      if (listData && listData.length > 0) {
        // image đã download roi ==> bo qua
        return [];
      }
      return concat(
        cxmService.Get.downloadFile(drawingId, companyId).pipe(
          mergeMap(imageData => {
            const url = window.URL.createObjectURL(imageData);
            // console.log(`ImageAdditionalCosts: drawingId: ${drawingId}, companyId: ${companyId}, id: ${id}`, url);
            return [
              accountingInvoiceActions.updateImageAdditionalCosts({
                imageUrl: url,
                drawingId,
                id,
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return []; // Trả về EMPTY nếu có lỗi
          }),
        ),
      );
    }),
  );
};

const getAttachmentLinks$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getImageUrlAttachmentLinks.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { drawingId, companyId, itemId, fileName } = action.payload;
      return concat(
        cxmService.Get.downloadFile(drawingId, companyId).pipe(
          mergeMap((imageData: any) => {
            return Utils.convertBlobToBase64(imageData).pipe(
              map(base64Image => {
                // IAttachmentLinks
                return accountingInvoiceActions.updateImageUrlAttachmentLinks({
                  drawingId,
                  fileName,
                  itemId,
                  selected: false,
                  imageUrl: base64Image,
                });
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return []; // Trả về EMPTY nếu có lỗi
          }),
        ),
      );
    }),
  );
};

const deleteAttachmentLinks$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.deleteAttachmentLinks.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { drawingIds, itemId, id } = action.payload;
      // console.log(`deleteAttachmentLinks: itemId: ${itemId}, drawingIds: ${drawingIds}`);
      return concat(
        [startLoading({ key: accountingInvoice.deleteAttachmentLinks })],
        cxmService.delete.deleteAttachmentFiles(itemId, drawingIds).pipe(
          switchMap(() => {
            // console.log(`deleteAttachmentLinks: itemId: ${itemId}, drawingId: ${drawingIds}`);
            Utils.successNotification();
            return [accountingInvoiceActions.updateAttachementImageUrl({ drawingIds, id, isdelete: true })];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.deleteAttachmentLinks })],
      );
    }),
  );
};

const uploadAttachmentLinks$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.uploadAttachmentLinks.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { dataImage, itemId, id, companyId } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.uploadAttachmentLinks })],
        cxmService.Post.uploadAttachmentFiles(itemId, dataImage).pipe(
          switchMap(results => {
            let attachmentLinks: IAttachmentLinks[] = [];
            let imageAttachmentLinks: any = [];
            if (results && results.length > 0) {
              results.forEach((x: any) => {
                const item: IAttachmentLinks = {
                  id: x.id,
                  drawingId: x.drawingId,
                  itemId: x.itemId,
                  fileName: x.fileName,
                  selected: false,
                  imageUrl: '',
                };
                attachmentLinks.push(item);
                imageAttachmentLinks.push(
                  accountingInvoiceActions.getImageUrlAttachmentLinks({
                    drawingId: x.drawingId,
                    fileName: x.fileName,
                    companyId,
                    itemId: x.itemId,
                  }),
                );
              });
            }
            Utils.successNotification();
            return [
              accountingInvoiceActions.updateAttachementImageUrl({ attachmentLinks, id, isdelete: false }),
              ...imageAttachmentLinks,
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.uploadAttachmentLinks })],
      );
    }),
  );
};
export const accountingInvoiceEpics = [
  createFileCPPS$,
  DeleteImage$,
  DeleteAdditionalCost$,
  UpdateAdditionalCost$,
  CreateAdditionalCost$,
  getProductRequest$,
  getWareHouse$,
  GetMoneyTypeList$,
  GetDanhSachThietBi$,
  GetProductUnit$,
  GetProposalForm$,
  CreateProposalForm$,
  DeleteProposalForm$,
  UpdateProposalForm$,
  CreatePhieuNhapXuatKho$,
  DeletePhieuNhapXuatKho$,
  GetDanhSachDuyetChi$,
  GetDanhSachDuyetMuaHang$,
  GetTonKho$,
  DuyetChi$,
  DeletePhieuDeNghiMuaHang$,
  HuyDuyetChi$,
  GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa$,
  ConfirmProposalForm$,
  GetDieuChuyenVatTu$,
  CreatePhieuDieuChuyen$,
  CreatePhieuNhapKho$,
  getDanhSachBoPhanRequest$,
  CreatePhieuXuatKho$,
  CreatePhieuNhapKhodc$,
  GetGiaVaNhaCungCap$,
  splitDeNghiMuaHangTheoNhaCungCap$,
  getBaoCaoXuatNhapTon$,
  getAdditionalCosts$,
  getImageAdditionalCosts$,
  NewNcc$,
  GetNcc$,
  GetKLdinhmuc$,
  getAttachmentLinks$,
  deleteAttachmentLinks$,
  uploadAttachmentLinks$,
  BaoCaoDanhThu$,
];
