/* eslint-disable import/order */
/* eslint-disable import/order */
import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Button, DatePicker, Space, Tabs, Input, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import Style from './TabHeader.module.less';
import { getLoading } from '@/store/loading';
import { useAppSelector } from '@/store/hooks';
import { accountingInvoice } from '@/common/define';
import ExpenseForm from '@/pages/MachineryMaterials/components/AddAriseNew';

const { RangePicker } = DatePicker;
const { Search } = Input;

interface TabHeaderProps {
  tabs: TabsProps['items'];
  onAddProposal?: () => void;
  onAddMorearise?: () => void;
  onDownload?: () => void;
  onSelectDate?: (dates: [Dayjs | null, Dayjs | null] | null) => void;
  onSearch?: (searchText: string) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({
  tabs,
  onAddProposal,
  onDownload,
  onSelectDate,
  onAddMorearise,
  onSearch,
}) => {
  const { t } = useTranslation('tabheader');
  const [activeTab, setActiveTab] = useState<string>('1');
  const today = dayjs();
  //[22/10/2024] [nam_do] chỉnh fillter lay 30 ngay tu ngay hom nay do ve truoc
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([today.subtract(30, 'day'), today]); // Cập nhật ngày bắt đầu là 30 ngày trước
  const isLoading = useAppSelector(getLoading(accountingInvoice.GetTonKho));
  const [isExpenseFormVisible, setIsExpenseFormVisible] = useState(false); // State để điều khiển hiển thị của ExpenseForm

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setDateRange(dates as [Dayjs, Dayjs]);
    if (onSelectDate) {
      onSelectDate(dates);
    }
  };

  const handleAddMoreAriseClick = () => {
    setIsExpenseFormVisible(true);
    if (onAddMorearise) {
      onAddMorearise();
    }
  };

  const handleExpenseFormClose = () => {
    setIsExpenseFormVisible(false);
  };

  return (
    <div>
      <div className={Style.tabContainer}>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          tabBarExtraContent={
            <div className={Style.tabActions}>
              {activeTab === '1' && (
                <Search
                  placeholder="Tìm kiếm..."
                  onChange={e => onSearch?.(e.target.value)}
                  onSearch={value => onSearch?.(value)}
                  style={{ width: 200, marginRight: 8 }}
                />
              )}

              {(activeTab === '2' || activeTab === '3') && (
                <Space direction="vertical" size={12} className={Style.datePicker}>
                  {/* Use RangePicker for selecting a date range */}
                  <RangePicker onChange={handleDateChange} value={dateRange} />
                </Space>
              )}
              {activeTab === '4' && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  style={{ marginRight: 8, borderRadius: '20px' }}
                  onClick={handleAddMoreAriseClick}
                >
                  {t('More arise')}
                </Button>
              )}
              {activeTab !== '4' && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  style={{ marginRight: 8, borderRadius: '20px' }}
                  onClick={onAddProposal}
                  disabled={isLoading}
                >
                  {t('Add Proposal')}
                </Button>
              )}
              {/* <Button
                icon={<DownloadOutlined />}
                style={{ marginRight: 8 }}
                onClick={onDownload}
              />
              <Button icon={<EllipsisOutlined />} /> */}
            </div>
          }
          items={tabs}
        />
      </div>
      <Modal visible={isExpenseFormVisible} onCancel={handleExpenseFormClose} footer={null}>
        <ExpenseForm />
      </Modal>
    </div>
  );
};
export default TabHeader;
